#view-doc-list-page #submenu-mobile {
  margin: 10px 0; }

#view-doc-list-page #sub-header-v2 #submenu-mobile h3 {
  display: inline; }

#view-doc-list-page #view-plan1-container,
#view-doc-list-page .view-monitoring-container,
#view-doc-list-page .view-keika-kiroku-container {
  margin: 0px auto;
  max-width: 1080px;
  padding: 0 10px; }
  #view-doc-list-page #view-plan1-container .pljp-table-tbody > tr > td,
  #view-doc-list-page .view-monitoring-container .pljp-table-tbody > tr > td,
  #view-doc-list-page .view-keika-kiroku-container .pljp-table-tbody > tr > td {
    padding: 10px 10px; }
  #view-doc-list-page #view-plan1-container .view-plan-table1 .pljp-table-tbody > tr > td:last-child,
  #view-doc-list-page .view-monitoring-container .view-plan-table1 .pljp-table-tbody > tr > td:last-child,
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table1 .pljp-table-tbody > tr > td:last-child {
    border-bottom: 1px solid; }
  #view-doc-list-page #view-plan1-container .view-plan-table1 .pljp-table table,
  #view-doc-list-page .view-monitoring-container .view-plan-table1 .pljp-table table,
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table1 .pljp-table table {
    width: 100%;
    text-align: left;
    border-radius: 4px 4px 0 0;
    border-collapse: collapse;
    border-spacing: 0; }
  #view-doc-list-page #view-plan1-container .view-plan-table1 .pljp-table-tbody > tr:last-child > td:nth-child(3),
  #view-doc-list-page .view-monitoring-container .view-plan-table1 .pljp-table-tbody > tr:last-child > td:nth-child(3),
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table1 .pljp-table-tbody > tr:last-child > td:nth-child(3) {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid #00838F;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page #view-plan1-container .view-plan-table1 .pljp-table-tbody > tr:last-child > td:first-child,
  #view-doc-list-page .view-monitoring-container .view-plan-table1 .pljp-table-tbody > tr:last-child > td:first-child,
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table1 .pljp-table-tbody > tr:last-child > td:first-child {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid white;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page #view-plan1-container .view-plan-table1 .pljp-table-tbody > tr:last-child > td:nth-child(3),
  #view-doc-list-page .view-monitoring-container .view-plan-table1 .pljp-table-tbody > tr:last-child > td:nth-child(3),
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table1 .pljp-table-tbody > tr:last-child > td:nth-child(3) {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid #00838F;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page #view-plan1-container .view-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(2), #view-doc-list-page #view-plan1-container .view-plan-table1 #view-doc-list-page #view-plan1-container .view-plan-table1 .pljp-table-tbody > tr:nth-child(2) > td:nth-child(2),
  #view-doc-list-page .view-monitoring-container .view-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(2),
  #view-doc-list-page .view-monitoring-container .view-plan-table1 #view-doc-list-page #view-plan1-container .view-plan-table1 .pljp-table-tbody > tr:nth-child(2) > td:nth-child(2),
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(2),
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table1 #view-doc-list-page #view-plan1-container .view-plan-table1 .pljp-table-tbody > tr:nth-child(2) > td:nth-child(2) {
    border-bottom: 1px solid; }
  #view-doc-list-page #view-plan1-container .view-plan-table2 .pljp-table-tbody > tr > td,
  #view-doc-list-page .view-monitoring-container .view-plan-table2 .pljp-table-tbody > tr > td,
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table2 .pljp-table-tbody > tr > td {
    border-top: 0px;
    border-bottom: 0px; }
  #view-doc-list-page #view-plan1-container .view-plan-table2 .pljp-table-tbody > tr:first-child > td:first-child,
  #view-doc-list-page .view-monitoring-container .view-plan-table2 .pljp-table-tbody > tr:first-child > td:first-child,
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table2 .pljp-table-tbody > tr:first-child > td:first-child {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid white;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page #view-plan1-container .view-plan-table2 .pljp-table-tbody > tr:first-child > td:nth-child(3),
  #view-doc-list-page .view-monitoring-container .view-plan-table2 .pljp-table-tbody > tr:first-child > td:nth-child(3),
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table2 .pljp-table-tbody > tr:first-child > td:nth-child(3) {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid white;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page #view-plan1-container .view-plan-table2 .pljp-table-tbody > tr:first-child > td:nth-child(2),
  #view-doc-list-page .view-monitoring-container .view-plan-table2 .pljp-table-tbody > tr:first-child > td:nth-child(2),
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table2 .pljp-table-tbody > tr:first-child > td:nth-child(2) {
    border-right: 0px; }
  #view-doc-list-page #view-plan1-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:first-child,
  #view-doc-list-page .view-monitoring-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:first-child,
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:first-child {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid #00838F;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page #view-plan1-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(3),
  #view-doc-list-page .view-monitoring-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(3),
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(3) {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid #00838F;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page #view-plan1-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(5),
  #view-doc-list-page .view-monitoring-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(5),
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(5) {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid #00838F;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page #view-plan1-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(4),
  #view-doc-list-page .view-monitoring-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(4),
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(4) {
    border-right: 0px; }
  #view-doc-list-page #view-plan1-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(2),
  #view-doc-list-page .view-monitoring-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(2),
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(2) {
    border-right: 0px; }
  #view-doc-list-page #view-plan1-container .view-plan-table3 .pljp-table-tbody > tr > td,
  #view-doc-list-page .view-monitoring-container .view-plan-table3 .pljp-table-tbody > tr > td,
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table3 .pljp-table-tbody > tr > td {
    border-bottom: 1px solid; }
  #view-doc-list-page #view-plan1-container .view-plan-table4 .pljp-table-tbody > tr:last-child > td:first-child,
  #view-doc-list-page .view-monitoring-container .view-plan-table4 .pljp-table-tbody > tr:last-child > td:first-child,
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table4 .pljp-table-tbody > tr:last-child > td:first-child {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid #00838F;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page #view-plan1-container .view-plan-table4 .pljp-table-tbody > tr:last-child > td:last-child,
  #view-doc-list-page .view-monitoring-container .view-plan-table4 .pljp-table-tbody > tr:last-child > td:last-child,
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table4 .pljp-table-tbody > tr:last-child > td:last-child {
    border-bottom: 1px solid; }
  #view-doc-list-page #view-plan1-container .view-plan-table,
  #view-doc-list-page .view-monitoring-container .view-plan-table,
  #view-doc-list-page .view-keika-kiroku-container .view-plan-table {
    border-bottom: 1px solid; }
  #view-doc-list-page #view-plan1-container .content-tb1,
  #view-doc-list-page .view-monitoring-container .content-tb1,
  #view-doc-list-page .view-keika-kiroku-container .content-tb1 {
    border-bottom: none; }
  #view-doc-list-page #view-plan1-container .title-view1,
  #view-doc-list-page .view-monitoring-container .title-view1,
  #view-doc-list-page .view-keika-kiroku-container .title-view1 {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid white;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }

#view-doc-list-page .menu-m-view {
  float: right; }

#view-doc-list-page .view-plan1-container-m {
  margin: 30px auto;
  max-width: 90%; }

#view-doc-list-page .title-view {
  background-color: #00bcd4;
  margin: 0 10px;
  font-weight: bold; }

#view-doc-list-page .row-content {
  margin: 5px 0px; }

#view-doc-list-page .input-value {
  margin: 0px 10px 10px 10px;
  border-bottom: 1px solid;
  word-break: break-all; }

#view-doc-list-page .title-view-center {
  text-align: center; }

#view-doc-list-page .plan-date-top {
  display: flex;
  flex-wrap: wrap; }
  #view-doc-list-page .plan-date-top .label {
    background-color: #b7dee8;
    display: inline-block;
    font-weight: 600; }
    @media screen and (max-width: 767px) {
      #view-doc-list-page .plan-date-top .label {
        min-width: 110px;
        background-color: transparent; } }
  #view-doc-list-page .plan-date-top .label-date {
    margin-left: 20px;
    border-bottom: 2px solid gray;
    max-width: 200px;
    width: 100%;
    display: inline-block;
    height: 20px;
    vertical-align: bottom; }
  @media screen and (max-width: 767px) {
    #view-doc-list-page .plan-date-top .label-date {
      margin-left: 0; } }
  #view-doc-list-page .plan-date-top .pljp-col {
    margin-bottom: 10px;
    display: flex; }
  #view-doc-list-page .plan-date-top .title-table {
    justify-content: flex-end;
    padding-right: 20px;
    margin-bottom: 0; }
    @media screen and (max-width: 767px) {
      #view-doc-list-page .plan-date-top .title-table {
        order: 1;
        padding-right: 0;
        justify-content: center; }
        #view-doc-list-page .plan-date-top .title-table span {
          max-width: 55px;
          width: 100%;
          border-bottom: 2px solid gray; } }
  @media screen and (max-width: 767px) {
    #view-doc-list-page .plan-date-top .column-2 {
      order: 2; }
    #view-doc-list-page .plan-date-top .pljp-col {
      margin-bottom: 0; } }

#view-doc-list-page #plan-table {
  padding: 0px; }
  #view-doc-list-page #plan-table .kikan {
    display: block; }
  #view-doc-list-page #plan-table td {
    vertical-align: top; }
    #view-doc-list-page #plan-table td ul {
      margin: -5px; }
      #view-doc-list-page #plan-table td ul > .item {
        padding: 5px;
        overflow-y: auto;
        display: block; }
        #view-doc-list-page #plan-table td ul > .item:not(:last-child) {
          border-bottom: 2px solid gray; }
    #view-doc-list-page #plan-table td > p {
      overflow-y: auto;
      margin: -5px;
      padding: 5px; }
      #view-doc-list-page #plan-table td > p::-webkit-scrollbar {
        width: 2px;
        background-color: #F5F5F5;
        border-radius: 100px; }
      #view-doc-list-page #plan-table td > p::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 100px; }

#view-doc-list-page .plan-date-bottom {
  padding-top: 10px; }
  #view-doc-list-page .plan-date-bottom .label {
    background-color: #b7dee8;
    font-weight: 600; }
    @media screen and (max-width: 767px) {
      #view-doc-list-page .plan-date-bottom .label {
        background-color: transparent; } }
  #view-doc-list-page .plan-date-bottom .label-date {
    margin-left: 20px;
    border-bottom: 2px solid gray;
    max-width: 200px;
    width: 100%;
    display: inline-block;
    height: unset;
    vertical-align: bottom; }
  #view-doc-list-page .plan-date-bottom .pljp-col {
    display: flex;
    margin-bottom: 10px; }
    #view-doc-list-page .plan-date-bottom .pljp-col:last-child .label-date {
      width: 100%;
      max-width: none;
      word-break: break-all; }
    @media screen and (max-width: 767px) {
      #view-doc-list-page .plan-date-bottom .pljp-col:last-child {
        flex-direction: column; }
        #view-doc-list-page .plan-date-bottom .pljp-col:last-child .label-date {
          margin-left: 0;
          margin-top: 20px;
          word-break: break-all; } }
    #view-doc-list-page .plan-date-bottom .pljp-col span:first-child {
      min-width: 80px; }

#view-doc-list-page .plan-mobile {
  padding: 10px 0px; }
  #view-doc-list-page .plan-mobile .item-normal {
    white-space: pre-line;
    font-weight: 100; }
  #view-doc-list-page .plan-mobile .item-expand {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 100; }
  #view-doc-list-page .plan-mobile .pljp-collapse,
  #view-doc-list-page .plan-mobile .pljp-collapse-item,
  #view-doc-list-page .plan-mobile .pljp-collapse-content {
    border: none; }
  #view-doc-list-page .plan-mobile .tb-dis-type-wrap .pljp-typography {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  #view-doc-list-page .plan-mobile .tb-dis-type-normal .pljp-typography {
    white-space: normal; }
  #view-doc-list-page .plan-mobile .pljp-collapse .pljp-collapse-item {
    margin-bottom: 20px; }
  #view-doc-list-page .plan-mobile .pljp-collapse .pljp-collapse-header {
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #d9d9d9;
    border-radius: 5px; }
    #view-doc-list-page .plan-mobile .pljp-collapse .pljp-collapse-header span {
      transform: translateY(-13px);
      display: inline-block; }
  #view-doc-list-page .plan-mobile .pljp-collapse .pljp-collapse-content-box {
    padding: 0; }
  #view-doc-list-page .plan-mobile .pljp-collapse .pljp-collapse-item {
    margin-bottom: 20px; }
  #view-doc-list-page .plan-mobile .pljp-typography {
    font-weight: 600; }
    #view-doc-list-page .plan-mobile .pljp-typography span {
      font-weight: 400; }
  #view-doc-list-page .plan-mobile .mobile-table {
    border: 1px solid grey; }
    #view-doc-list-page .plan-mobile .mobile-table .pljp-col {
      padding: 5px; }
      #view-doc-list-page .plan-mobile .mobile-table .pljp-col.pljp-col-sm-2 {
        text-align: right; }
    #view-doc-list-page .plan-mobile .mobile-table .item-detail {
      display: flex;
      clear: both; }
      #view-doc-list-page .plan-mobile .mobile-table .item-detail .pljp-col {
        border-top: 1px solid grey;
        border-right: 1px solid grey; }

#view-doc-list-page .pljp-layout-content {
  -ms-flex: auto;
  display: flex;
  flex-flow: column;
  height: 100vh; }

#view-doc-list-page .pljp-table-thead > tr > th {
  text-align: center;
  background-color: #00838F;
  font-weight: bold; }

#view-doc-list-page .pljp-table-tbody > tr > td {
  border: 1px solid gray;
  border-bottom: unset; }

#view-doc-list-page .view-dailyplan .table-record .pljp-table {
  border-right: 0; }

#view-doc-list-page .view-dailyplan .table-record .pljp-table-thead > tr > th {
  text-align: center;
  background-color: #00838F;
  font-weight: bold;
  color: white;
  border-right: 1px solid white; }

#view-doc-list-page .view-dailyplan .table-record .pljp-table-tbody > tr:last-child > td {
  border-bottom: 1px solid; }

#view-doc-list-page .view-dailyplan .table-record .pljp-table-tbody > tr > td {
  border: 1px solid gray;
  border-bottom: unset;
  border-left: 0px; }

#view-doc-list-page .view-dailyplan .table-record .pljp-table-tbody > tr > td:first-child {
  border-left: 1px solid; }

#view-doc-list-page .view-dailyplan .timeColumn {
  border-right: 0px; }

#view-doc-list-page .view-dailyplan .expand-dailyplan1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media screen and (max-width: 3000px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan1 {
      width: 280px; } }
  @media screen and (max-width: 1600px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan1 {
      width: 215px; } }
  @media screen and (max-width: 1200px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan1 {
      width: 170px; } }
  @media screen and (max-width: 992px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan1 {
      width: 124px; } }

#view-doc-list-page .view-dailyplan .expand-dailyplan2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media screen and (max-width: 3000px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan2 {
      width: 350px; } }
  @media screen and (max-width: 1600px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan2 {
      width: 265px; } }
  @media screen and (max-width: 1200px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan2 {
      width: 207px; } }
  @media screen and (max-width: 992px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan2 {
      width: 153px; } }

#view-doc-list-page .view-dailyplan .expand-dailyplan3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media screen and (max-width: 3000px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan3 {
      width: 200px; } }
  @media screen and (max-width: 1600px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan3 {
      width: 170px; } }
  @media screen and (max-width: 1200px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan3 {
      width: 133px; } }
  @media screen and (max-width: 992px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan3 {
      width: 94px; } }

#view-doc-list-page .view-dailyplan .expand-dailyplan4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media screen and (max-width: 3000px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan4 {
      width: 200px; } }
  @media screen and (max-width: 1600px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan4 {
      width: 170px; } }
  @media screen and (max-width: 1200px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan4 {
      width: 134px; } }
  @media screen and (max-width: 992px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan4 {
      width: 98px; } }

#view-doc-list-page .view-dailyplan .expand-dailyplan-m {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media screen and (max-width: 767px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan-m {
      width: 435px; } }
  @media screen and (max-width: 575px) {
    #view-doc-list-page .view-dailyplan .expand-dailyplan-m {
      width: 255px; } }

#view-doc-list-page .view-dailyplan .view-daily {
  margin: 30px auto;
  max-width: 1080px;
  padding: 0 10px; }

#view-doc-list-page .view-dailyplan .view-daily-m {
  margin: 30px auto;
  max-width: 90%; }

#view-doc-list-page .view-dailyplan .view-plan-table1 {
  border-bottom: 1px solid;
  margin-top: 10px; }
  #view-doc-list-page .view-dailyplan .view-plan-table1 .pljp-table-tbody > tr:first-child > td:first-child {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid white;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page .view-dailyplan .view-plan-table1 .pljp-table-tbody > tr:last-child > td:first-child {
    border-top: 1px solid #00838F;
    border-bottom: none;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page .view-dailyplan .view-plan-table1 .pljp-table-thead > tr > th, #view-doc-list-page .view-dailyplan .view-plan-table1 .pljp-table-tbody > tr > td {
    padding: 10px; }

#view-doc-list-page .view-dailyplan .table-record-m .pljp-table-tbody > tr:last-child > td {
  border-bottom: 1px solid; }

#view-doc-list-page .title-view-tab3 {
  background-color: #00bcd4;
  margin-top: 20px;
  font-weight: bold; }

#view-doc-list-page .input-value-tab3 {
  border-bottom: 2px solid;
  margin-top: 20px;
  float: right;
  word-wrap: break-word; }

#view-doc-list-page .btn-change {
  padding: 0 5px; }

#view-doc-list-page .pljp-radio-button-wrapper {
  background: darkgray; }

#view-doc-list-page .pljp-radio-button-wrapper-checked {
  color: gray;
  border-color: lightgray;
  box-shadow: none;
  background-color: white; }

#view-doc-list-page .data-plan2 {
  margin: 0 auto;
  max-width: 90%;
  flex: 1;
  min-height: 600px; }
  #view-doc-list-page .data-plan2 .view-plan-table3 .pljp-table table {
    width: 100%;
    text-align: left;
    border-radius: 4px 4px 0 0;
    border-collapse: collapse;
    border-spacing: 0; }
  #view-doc-list-page .data-plan2 .view-plan-table3 .pljp-table-tbody > tr:first-child > td:first-child {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid #00838F;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page .data-plan2 .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(3) {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid #00838F;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page .data-plan2 .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(5) {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid #00838F;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page .data-plan2 .view-plan-table3 .pljp-table-tbody > tr:first-child > td:nth-child(2) {
    border-right: 0px; }
  #view-doc-list-page .data-plan2 .view-plan-table3 .pljp-table-tbody > tr > td {
    border-bottom: 1px solid; }
  #view-doc-list-page .data-plan2 .pljp-table-thead > tr > th {
    text-align: center;
    background-color: #00838F;
    text-align: center;
    font-weight: bold;
    color: white;
    border-left: 1px solid white; }
  #view-doc-list-page .data-plan2 .pljp-table-thead > tr > th:first-child {
    border-left: 1px; }
  @media only screen and (min-width: 768px) {
    #view-doc-list-page .data-plan2 {
      margin: 0px auto;
      max-width: 1080px;
      padding: 0 10px; } }
  #view-doc-list-page .data-plan2 .view-plan-table {
    border-bottom: 1px solid; }
  #view-doc-list-page .data-plan2 .content-tb1 {
    border-bottom: none; }
  #view-doc-list-page .data-plan2 .pljp-table-tbody > tr > td {
    padding: 10px;
    border-right: 0px; }
  #view-doc-list-page .data-plan2 .pljp-table-tbody > tr > td:last-child {
    border-right: 1px solid darkgray; }
  #view-doc-list-page .data-plan2 .pljp-table-tbody:last-child > tr > td {
    border-bottom: 1px solid; }
  #view-doc-list-page .data-plan2 .plan2-tb-bottom {
    margin-top: 20px;
    margin-bottom: 30px; }
    #view-doc-list-page .data-plan2 .plan2-tb-bottom .pljp-table-tbody > tr > td:first-child {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center; }

#view-doc-list-page .pljp-layout-content {
  display: flex;
  flex-flow: column;
  height: 100vh; }

#view-doc-list-page .view-monitoring-container {
  padding-top: 20px; }
  #view-doc-list-page .view-monitoring-container .view-plan-table2 td:last-child,
  #view-doc-list-page .view-monitoring-container .view-plan-table3 td:last-child {
    border-right: 1px solid gray !important; }
  #view-doc-list-page .view-monitoring-container .view-monitoring-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; }
    #view-doc-list-page .view-monitoring-container .view-monitoring-header__right {
      text-align: right; }
  #view-doc-list-page .view-monitoring-container .view-monitoring-table {
    margin-top: 20px; }
    #view-doc-list-page .view-monitoring-container .view-monitoring-table .pljp-table {
      width: 100%;
      border-radius: 0;
      border-right-color: gray;
      border-bottom-color: gray; }
    #view-doc-list-page .view-monitoring-container .view-monitoring-table tbody td {
      border-right: 0; }
    #view-doc-list-page .view-monitoring-container .view-monitoring-table th {
      border-radius: 0;
      color: #fff;
      text-align: center;
      font-weight: bold;
      border-left: 1px solid #fff; }
      #view-doc-list-page .view-monitoring-container .view-monitoring-table th:first-child {
        border-left: 0; }
    #view-doc-list-page .view-monitoring-container .view-monitoring-table tr:hover td {
      background-color: unset !important; }
  #view-doc-list-page .view-monitoring-container .view-plan-table3 tr:hover td:nth-child(even),
  #view-doc-list-page .view-monitoring-container .view-plan-table4 tr:hover td:nth-child(even) {
    background-color: unset !important; }

#view-doc-list-page .view-monitoring-container-m,
#view-doc-list-page .view-keika-kiroku-container-m {
  margin: 20px auto;
  max-width: 90%;
  min-height: 600px; }

#view-doc-list-page .view-monitoring-table-m {
  margin-top: 30px; }

#view-doc-list-page .view-monitoring-switch {
  margin-bottom: 10px; }

#view-doc-list-page .view-monitoring-header-m .pljp-row {
  margin-bottom: 10px; }

#view-doc-list-page .view-monitoring-header-m span {
  display: inline-block;
  min-width: 100px;
  border-bottom: 1px solid gray; }

#view-doc-list-page .view-monitoring-mokuhyou {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 5px 5px 0 5px;
  margin-bottom: 5px; }
  #view-doc-list-page .view-monitoring-mokuhyou--show .view-monitoring-mokuhyou__content {
    display: block; }
  #view-doc-list-page .view-monitoring-mokuhyou__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -5px -5px 0 -5px;
    padding: 5px 0 5px 5px;
    background-color: #d9d9d9;
    font-weight: bold; }
    #view-doc-list-page .view-monitoring-mokuhyou__header button {
      color: gray; }
  #view-doc-list-page .view-monitoring-mokuhyou__title {
    font-weight: bold;
    margin-right: 7px; }
  #view-doc-list-page .view-monitoring-mokuhyou__content {
    margin-top: 5px;
    display: none; }
    #view-doc-list-page .view-monitoring-mokuhyou__content .pljp-row {
      margin-bottom: 5px; }

#view-doc-list-page .view-monitoring-result {
  margin-left: -5px;
  margin-right: -5px;
  border-top: 1px solid #d9d9d9; }
  #view-doc-list-page .view-monitoring-result-item {
    display: flex;
    border-bottom: 1px solid #d9d9d9; }
    #view-doc-list-page .view-monitoring-result-item-left {
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #d9d9d9;
      padding: 0 5px;
      min-width: 30px; }
    #view-doc-list-page .view-monitoring-result-item-right {
      padding: 5px; }

#view-doc-list-page .view-monitoring-hyoka {
  border: 1px solid #d9d9d9;
  padding: 5px;
  margin-bottom: 5px;
  border-radius: 4px; }
  #view-doc-list-page .view-monitoring-hyoka__header {
    margin: -5px -5px 5px -5px;
    padding: 5px;
    background-color: #d9d9d9;
    font-weight: bold; }

#view-doc-list-page .view-keika-kiroku-container {
  padding-top: 30px;
  padding-bottom: 30px; }
  #view-doc-list-page .view-keika-kiroku-container td:nth-child(odd) {
    text-align: center; }
  #view-doc-list-page .view-keika-kiroku-container .view-table1 td,
  #view-doc-list-page .view-keika-kiroku-container .view-table11 td,
  #view-doc-list-page .view-keika-kiroku-container .view-table12 td {
    border-right: 0; }
    #view-doc-list-page .view-keika-kiroku-container .view-table1 td:last-child,
    #view-doc-list-page .view-keika-kiroku-container .view-table11 td:last-child,
    #view-doc-list-page .view-keika-kiroku-container .view-table12 td:last-child {
      border-right: 1px solid gray; }
    #view-doc-list-page .view-keika-kiroku-container .view-table1 td:nth-child(odd),
    #view-doc-list-page .view-keika-kiroku-container .view-table11 td:nth-child(odd),
    #view-doc-list-page .view-keika-kiroku-container .view-table12 td:nth-child(odd) {
      color: #fff;
      background-color: #00838F;
      font-weight: bold; }
  #view-doc-list-page .view-keika-kiroku-container .view-table12 td,
  #view-doc-list-page .view-keika-kiroku-container .view-table2 tr:last-child td,
  #view-doc-list-page .view-keika-kiroku-container .view-table3 td {
    border-bottom: 1px solid gray; }
  #view-doc-list-page .view-keika-kiroku-container .view-table1 td:first-child {
    border-bottom: 1px solid #fff; }
  #view-doc-list-page .view-keika-kiroku-container .view-table11 td:nth-child(odd) {
    border-bottom: 1px solid #fff; }
  #view-doc-list-page .view-keika-kiroku-container .view-table5 td {
    border-bottom: 1px solid gray; }

#view-doc-list-page .view-keika-kiroku-container-m .text-bold {
  font-weight: bold;
  min-width: 80px;
  display: inline-block; }

#view-doc-list-page .view-keika-kiroku-container-m .view-keika-kiroku-text {
  margin-bottom: 10px; }
  #view-doc-list-page .view-keika-kiroku-container-m .view-keika-kiroku-text .text-bold {
    display: block; }

#view-doc-list-page .view-keika-kiroku-container-m .pljp-col:not(.view-keika-kiroku-text) {
  margin-bottom: 5px; }
  #view-doc-list-page .view-keika-kiroku-container-m .pljp-col:not(.view-keika-kiroku-text) span:not(.text-bold) {
    border-bottom: 1px solid gray;
    min-width: 40px;
    display: inline-block; }

#view-doc-list-page .view-monitoring-comment {
  margin-top: 20px; }
  #view-doc-list-page .view-monitoring-comment .text-bold {
    margin-bottom: 0;
    font-weight: bold; }

#view-doc-list-page .view-tanto-kaigi-container {
  margin: 0px auto;
  max-width: 1080px;
  padding: 30px 10px; }
  #view-doc-list-page .view-tanto-kaigi-container .text-bold {
    display: inline-block;
    font-weight: bold;
    margin-right: 8px; }
  #view-doc-list-page .view-tanto-kaigi-container .view-table td {
    border-right-width: 0; }
    #view-doc-list-page .view-tanto-kaigi-container .view-table td:last-child {
      border-right-width: 1px; }
    #view-doc-list-page .view-tanto-kaigi-container .view-table td:nth-child(odd) {
      text-align: center;
      color: #fff;
      background-color: #00838F;
      font-weight: bold; }
  #view-doc-list-page .view-tanto-kaigi-container .view-table--2, #view-doc-list-page .view-tanto-kaigi-container .view-table--7, #view-doc-list-page .view-tanto-kaigi-container .view-table--9 {
    margin-bottom: 20px; }
  #view-doc-list-page .view-tanto-kaigi-container .view-table--2 td, #view-doc-list-page .view-tanto-kaigi-container .view-table--7 td, #view-doc-list-page .view-tanto-kaigi-container .view-table--8 td, #view-doc-list-page .view-tanto-kaigi-container .view-table--9 td {
    border-bottom: 1px solid gray; }
  #view-doc-list-page .view-tanto-kaigi-container .view-table--2 td:nth-child(odd), #view-doc-list-page .view-tanto-kaigi-container .view-table--4 td:nth-child(odd), #view-doc-list-page .view-tanto-kaigi-container .view-table--5 td:nth-child(odd), #view-doc-list-page .view-tanto-kaigi-container .view-table--6 td:nth-child(odd), #view-doc-list-page .view-tanto-kaigi-container .view-table--7 td:nth-child(odd) {
    border-top-color: #fff; }
  #view-doc-list-page .view-tanto-kaigi-container .view-table--9 td:last-child {
    padding: 0; }
  #view-doc-list-page .view-tanto-kaigi-container .view-table--9 tr:hover td:last-child {
    background-color: unset; }
  #view-doc-list-page .view-tanto-kaigi-container .view-table--10 .pljp-table-placeholder {
    display: none; }
  #view-doc-list-page .view-tanto-kaigi-container .view-table--10 td {
    background-color: unset !important;
    color: unset !important;
    font-weight: normal !important;
    border-width: 0 0 1px 0 !important;
    text-align: left !important; }
  #view-doc-list-page .view-tanto-kaigi-container .view-table--10 tr:last-child td {
    border-bottom: 0 !important; }
  #view-doc-list-page .view-tanto-kaigi-container .view-table--10 tr:hover td {
    background-color: #bacfcd !important; }
  #view-doc-list-page .view-tanto-kaigi-container .pljp-table-tbody > tr > td {
    padding: 10px 10px; }

#view-doc-list-page .view-tanto-kaigi-container-m {
  margin: 20px auto;
  max-width: 90%;
  min-height: 600px; }
  #view-doc-list-page .view-tanto-kaigi-container-m .text-bold {
    display: inline-block;
    font-weight: bold;
    margin-right: 8px; }
  #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-buttons .text-bold {
    width: 60px; }
  #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-buttons .pljp-col {
    margin-bottom: 5px; }
  #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-buttons span:not(.text-bold) {
    border-bottom: 1px solid gray; }
  #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendee {
    margin-top: 20px;
    margin-bottom: 20px; }
    #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendee__notfound {
      border-bottom: 1px solid gray; }
  #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-textareas .pljp-col {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px; }
  #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-hiyou .pljp-col {
    margin-bottom: 10px; }
  #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-textareas span:not(.text-bold), #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-hiyou span:not(.text-bold) {
    display: inline-block;
    border-bottom: 1px solid gray; }
  #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-hiyou .pljp-col {
    display: flex; }
    #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-hiyou .pljp-col:first-child {
      padding-right: 10px; }
  #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-hiyou span:not(.text-bold) {
    flex: 1; }
  #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance {
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 8px 8px 0 8px;
    border: 1px solid #d9d9d9; }
    #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance__no {
      margin: -8px -8px 0 -8px;
      background-color: #d9d9d9;
      padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance__no button {
        color: #777; }
    #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance__affiliation {
      padding-top: 8px; }
    #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance__name {
      padding-bottom: 8px; }
    #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance__affiliation, #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance__type, #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance__name {
      display: none; }
      #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance__affiliation .text-bold, #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance__type .text-bold, #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance__name .text-bold {
        width: 30px; }
  #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance--show .view-tanto-kaigi-attendance__affiliation, #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance--show .view-tanto-kaigi-attendance__type, #view-doc-list-page .view-tanto-kaigi-container-m .view-tanto-kaigi-attendance--show .view-tanto-kaigi-attendance__name {
    display: block; }

#view-doc-list-page .view-weekly-plan-container {
  padding-top: 10px;
  margin: 0px 5px 0px 60px; }
  #view-doc-list-page .view-weekly-plan-container .pljp-table-tbody > tr > td {
    padding: 10px 10px; }
  #view-doc-list-page .view-weekly-plan-container .view-weekly-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(1), #view-doc-list-page .view-weekly-plan-container .view-weekly-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(4) {
    border-top: 1px solid #00838F;
    border-bottom: 1px solid #00838F;
    background-color: #00838F;
    border-right: 1px solid #00838F;
    color: white;
    font-weight: bold;
    text-align: center; }
  #view-doc-list-page .view-weekly-plan-container .view-weekly-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(2), #view-doc-list-page .view-weekly-plan-container .view-weekly-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(3), #view-doc-list-page .view-weekly-plan-container .view-weekly-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(5), #view-doc-list-page .view-weekly-plan-container .view-weekly-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(6) {
    background-color: white; }
  #view-doc-list-page .view-weekly-plan-container .view-weekly-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(2), #view-doc-list-page .view-weekly-plan-container .view-weekly-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(5) {
    border-bottom: 1px solid; }
  #view-doc-list-page .view-weekly-plan-container .view-weekly-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(6), #view-doc-list-page .view-weekly-plan-container .view-weekly-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(3) {
    border-right: none;
    border-top: none; }
  #view-doc-list-page .view-weekly-plan-container .view-weekly-plan-table1 .pljp-table-tbody > tr:first-child > td:nth-child(6) {
    text-align: right; }

#view-doc-list-page .view-weekly-plan-table2 {
  padding: 0px 10px 0px 10px;
  margin-top: 10px; }
  #view-doc-list-page .view-weekly-plan-table2 .pljp-table {
    width: 100%;
    border-radius: 0;
    border-right-color: gray;
    border-bottom-color: gray; }
  #view-doc-list-page .view-weekly-plan-table2 tbody td {
    border-right: 0;
    padding: 2px;
    font-size: 13px; }
  #view-doc-list-page .view-weekly-plan-table2 tbody tr {
    height: 18px; }
  #view-doc-list-page .view-weekly-plan-table2 th {
    padding: 5px 5px;
    border-radius: 0;
    color: #fff;
    text-align: center;
    font-weight: bold;
    border-left: 1px solid #fff; }
    #view-doc-list-page .view-weekly-plan-table2 th:first-child {
      border-left: 0; }
  #view-doc-list-page .view-weekly-plan-table2 tr:hover td {
    background-color: unset; }
  #view-doc-list-page .view-weekly-plan-table2 .pljp-table-tbody > tr > td:last-child {
    border-right: 1px solid; }
  #view-doc-list-page .view-weekly-plan-table2 .pljp-table-tbody > tr > td:first-child {
    text-align: center; }

#view-doc-list-page .view-weekly-plan-table3 {
  padding: 0px 10px 20px 10px; }
  #view-doc-list-page .view-weekly-plan-table3 .pljp-table {
    width: 100%;
    border-radius: 0;
    border-right-color: gray;
    border-bottom-color: gray; }
  #view-doc-list-page .view-weekly-plan-table3 td {
    border-bottom: 1px solid !important;
    padding: 5px 5px; }
  #view-doc-list-page .view-weekly-plan-table3 tr:hover td {
    background-color: unset !important; }
