#sub-header-v2 .sub-header-v2-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; }

#sub-header-v2 .sm-c-left h3 {
  color: #fff;
  margin-top: 10px; }

#sub-header-v2 .sm-c-middle {
  padding: 16px auto; }

#sub-header-v2 .sm-footer-m-right {
  float: right; }
  #sub-header-v2 .sm-footer-m-right ul li {
    float: right;
    margin-left: 16px;
    padding: 0px 8px; }
    #sub-header-v2 .sm-footer-m-right ul li span {
      display: block;
      text-align: center; }
    #sub-header-v2 .sm-footer-m-right ul li a {
      color: #fff; }
  #sub-header-v2 .sm-footer-m-right .active {
    border: 1px solid #fff;
    border-radius: 3px;
    background-color: #aaa; }
    #sub-header-v2 .sm-footer-m-right .active i {
      color: yellow; }
    #sub-header-v2 .sm-footer-m-right .active svg {
      color: yellow; }
  #sub-header-v2 .sm-footer-m-right :hover {
    border-radius: 3px;
    background-color: #aaa; }

#sub-header-v2 .sm-c-right {
  float: right; }
  #sub-header-v2 .sm-c-right ul li {
    float: right;
    margin-left: 16px;
    padding: 6px 10px;
    line-height: 24px; }
    #sub-header-v2 .sm-c-right ul li span {
      display: block;
      text-align: center; }
    #sub-header-v2 .sm-c-right ul li a {
      color: #fff; }
  #sub-header-v2 .sm-c-right .active {
    border: 1px solid #fff;
    border-radius: 3px;
    background-color: #aaa; }
    #sub-header-v2 .sm-c-right .active i {
      color: yellow; }
    #sub-header-v2 .sm-c-right .active svg {
      color: yellow; }
  #sub-header-v2 .sm-c-right :hover {
    border-radius: 3px;
    background-color: #aaa; }
    @media screen and (max-device-width: 850px) {
      #sub-header-v2 .sm-c-right :hover {
        background-color: unset; } }

@media (max-width: 1024px) {
  #sub-header-v2 .sm-c-right :hover {
    background-color: unset; } }

@media (max-width: 575px) {
  #sub-header-v2 .sm-footer-m-right :hover {
    background-color: unset; } }
