#view-doc-list-page {
    #submenu-mobile {
      margin: 10px 0;
    }

    #sub-header-v2 #submenu-mobile h3 {
      display: inline;
    }
    #view-plan1-container,
    .view-monitoring-container,
    .view-keika-kiroku-container {
      margin: 0px auto;
      max-width: 1080px;
      padding: 0 10px;
      .pljp-table-tbody > tr > td {
        padding: 10px 10px;
    }
      .view-plan-table1 {
        .pljp-table-tbody > tr > td:last-child {
          border-bottom: 1px solid;
        }
        .pljp-table table {
          width: 100%;
          text-align: left;
          border-radius: 4px 4px 0 0;
          border-collapse: collapse;
          border-spacing: 0;
        }
        .pljp-table-tbody > tr:last-child > td:nth-child(3) {
          border-top: 1px solid #00838F;
          border-bottom: 1px solid #00838F;
          background-color: #00838F;
          border-right: 1px solid #00838F;
          color: white;
          font-weight: bold;
          text-align: center;
        }
        .pljp-table-tbody > tr:last-child > td:first-child {
          border-top: 1px solid #00838F;
          border-bottom: 1px solid white;
          background-color: #00838F;
          border-right: 1px solid #00838F;
          color: white;
          font-weight: bold;
          text-align: center;
        }
        .pljp-table-tbody > tr:last-child > td:nth-child(3) {
          border-top: 1px solid #00838F;
          border-bottom: 1px solid #00838F;
          background-color: #00838F;
          border-right: 1px solid #00838F;
          color: white;
          font-weight: bold;
          text-align: center;
        }
        .pljp-table-tbody > tr:first-child > td:nth-child(2), #view-doc-list-page #view-plan1-container .view-plan-table1 .pljp-table-tbody > tr:nth-child(2) > td:nth-child(2) {
          border-bottom: 1px solid;
        }

      }
      .view-plan-table2 {
        .pljp-table-tbody > tr > td {
          border-top: 0px;
          border-bottom: 0px;
        }
        .pljp-table-tbody > tr:first-child > td:first-child {
          border-top: 1px solid #00838F;
          border-bottom: 1px solid white;
          background-color: #00838F;
          border-right: 1px solid #00838F;
          color: white;
          font-weight: bold;
          text-align: center;
        }
        .pljp-table-tbody > tr:first-child > td:nth-child(3) {
          border-top: 1px solid #00838F;
          border-bottom: 1px solid white;
          background-color: #00838F;
          border-right: 1px solid #00838F;
          color: white;
          font-weight: bold;
          text-align: center;
        }
        .pljp-table-tbody > tr:first-child > td:nth-child(2) {
          border-right: 0px;
        }
      }
      .view-plan-table3{
        .pljp-table-tbody > tr:first-child > td:first-child {
          border-top: 1px solid #00838F;
          border-bottom: 1px solid #00838F;
          background-color: #00838F;
          border-right: 1px solid #00838F;
          color: white;
          font-weight: bold;
          text-align: center;
        }
        .pljp-table-tbody > tr:first-child > td:nth-child(3) {
          border-top: 1px solid #00838F;
          border-bottom: 1px solid #00838F;
          background-color: #00838F;
          border-right: 1px solid #00838F;
          color: white;
          font-weight: bold;
          text-align: center;
        }
        .pljp-table-tbody > tr:first-child > td:nth-child(5) {
          border-top: 1px solid #00838F;
          border-bottom: 1px solid #00838F;
          background-color: #00838F;
          border-right: 1px solid #00838F;
          color: white;
          font-weight: bold;
          text-align: center;
        }
        .pljp-table-tbody > tr:first-child > td:nth-child(4) {
          border-right: 0px;
        }
        .pljp-table-tbody > tr:first-child > td:nth-child(2){
          border-right: 0px;
        }
        .pljp-table-tbody > tr > td {
          border-bottom: 1px solid;
        }
      }
      .view-plan-table4 {
        .pljp-table-tbody > tr:last-child > td:first-child {
          border-top: 1px solid #00838F;
          border-bottom: 1px solid #00838F;
          background-color: #00838F;
          border-right: 1px solid #00838F;
          color: white;
          font-weight: bold;
          text-align: center;
        }
        .pljp-table-tbody > tr:last-child > td:last-child {
          border-bottom: 1px solid;
        }
      }

      .view-plan-table {
        border-bottom: 1px solid ;
      }
      .content-tb1 {
      border-bottom: none;
      }
      .title-view1 {
        border-top: 1px solid #00838F;
        border-bottom: 1px solid white;
        background-color: #00838F;
        border-right: 1px solid #00838F;
        color: white;
        font-weight: bold;
        text-align: center;
      }


    }
    .menu-m-view {
      float: right;
    }

    .view-plan1-container-m {
      margin: 30px auto;
      max-width: 90%;
    }

    .title-view {
      background-color: #00bcd4;
      margin: 0 10px;
      font-weight: bold;
    }

    .row-content {
      margin: 5px 0px;
    }

    .input-value {
      margin: 0px 10px 10px 10px;
      border-bottom: 1px solid;
      word-break: break-all;
    }

    .title-view-center {
      text-align: center;
    }

    .plan-date-top {
      display: flex;
      flex-wrap: wrap;

      .label {
        background-color: #b7dee8;
        display: inline-block;
        font-weight: 600;

        @media screen and (max-width:767px) {
          min-width: 110px;
          background-color: transparent;
        }
      }

      .label-date {
        margin-left: 20px;
        border-bottom: 2px solid gray;
        max-width: 200px;
        width: 100%;
        display: inline-block;
        height: 20px;
        vertical-align: bottom;
      }
      @media screen and (max-width:767px) {
        .label-date {
          margin-left: 0;
        }
      }

      .pljp-col {
        margin-bottom: 10px;
        display: flex;
      }

      .title-table{
        justify-content: flex-end;
        padding-right: 20px;
        margin-bottom: 0;

        @media screen and (max-width:767px) {
          order: 1;
          padding-right: 0;
          justify-content: center;
          span{
            max-width:55px;
            width:100%;
            border-bottom: 2px solid gray;
          }
        }
      }
      @media screen and (max-width:767px) {
        .column-2{
          order: 2;
        }

        .pljp-col {
          margin-bottom: 0;
        }
      }
    }

    #plan-table {
      padding: 0px;
      .kikan {
        display: block;
      }

      td {
        vertical-align: top;

        ul {
          margin: -5px;

          &>.item {
            padding: 5px;
            //height: 75px;
            overflow-y: auto;
            display: block;

            //&::-webkit-scrollbar {
            //  width: 2px;
            //  background-color: #F5F5F5;
            //  border-radius: 100px;
            //}
            //
            //&::-webkit-scrollbar-thumb {
            //  background-color: rgba(0,0,0,0.5);
            //  border-radius: 100px;
            //}

            &:not(:last-child) {
              border-bottom: 2px solid gray;
            }
          }
        }

        &>p {
          overflow-y: auto;
          margin: -5px;
          padding: 5px;
          &::-webkit-scrollbar {
            width: 2px;
            background-color: #F5F5F5;
            border-radius: 100px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(0,0,0,0.5);
            border-radius: 100px;
          }

        }
      }






      .row-height-0 p{
        //height: 75px;
        //max-height: 75px;
      }
    }

    .plan-date-bottom {
      padding-top: 10px;
      .label {
        background-color: #b7dee8;
        font-weight: 600;

        @media screen and (max-width:767px) {
          background-color: transparent;
        }
      }

      .label-date {
        margin-left: 20px;
        border-bottom: 2px solid gray;
        max-width: 200px;
        width: 100%;
        display: inline-block;
        height: unset;
        vertical-align: bottom;
      }

      .pljp-col {
        display: flex;
        margin-bottom: 10px;

        &:last-child {
          .label-date {
            width: 100%;
            max-width: none;
            word-break: break-all;
          }

          @media screen and (max-width:767px) {
            flex-direction: column;

            .label-date {
              margin-left: 0;
              margin-top: 20px;
              word-break: break-all;
            }
          }
        }

        span:first-child {
          min-width: 80px;
        }
      }
    }

    .plan-mobile {
      .item-normal {
        white-space: pre-line;
        font-weight: 100;
      }
      .item-expand {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 100;
      }
      padding: 10px 0px;

      .pljp-collapse,
      .pljp-collapse-item,
      .pljp-collapse-content {
        border: none;
      }

      .tb-dis-type-wrap {
        .pljp-typography {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .tb-dis-type-normal {
        .pljp-typography {
          white-space: normal;
        }
      }

      .pljp-collapse {

        .pljp-collapse-item {
          margin-bottom: 20px;
        }
        .pljp-collapse-header{
          padding-top: 4px;
          padding-bottom:4px;
          background-color: #d9d9d9;
          border-radius: 5px;
          span{
            transform: translateY(-13px);
            display: inline-block;
          }
        }
        .pljp-collapse-content-box{
          padding:0;
        }
        .pljp-collapse-item{
          margin-bottom: 20px;
        }
      }

      .pljp-typography {
        font-weight: 600;

        span {
          font-weight: 400;
        }
      }

      .mobile-table {

        border: 1px solid grey;

        .pljp-col {
          padding: 5px;
          &.pljp-col-sm-2{
            text-align: right;
          }
        }

        .item-detail {
          display: flex;
          clear: both;

          .pljp-col {
            border-top: 1px solid grey;
            border-right: 1px solid grey;
          }
        }
      }
    }
      .pljp-layout-content {
        flex: auto;
        display: flex;
        flex-flow: column;
        height: 100vh;
      }

      .pljp-table-thead > tr > th {
        text-align: center;
        background-color: #00838F;
        font-weight: bold;
      }
      .pljp-table-tbody > tr > td {
        border: 1px solid gray;
        border-bottom: unset;
      }
      .view-dailyplan{

        .table-record {
          .pljp-table {
            border-right: 0;
          }
          .pljp-table-thead > tr > th {
            text-align: center;
            background-color: #00838F;
            font-weight: bold;
            color: white;
            border-right: 1px solid white;
        }
        .pljp-table-tbody > tr:last-child > td {
          border-bottom: 1px solid;
        }
        .pljp-table-tbody > tr > td {
          border: 1px solid gray;
          border-bottom: unset;
          border-left: 0px;
        }
        .pljp-table-tbody > tr > td:first-child {
          border-left: 1px solid;
        }
        }

        .timeColumn {
          border-right: 0px;
        }
        .expand-dailyplan1 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width:3000px) {
            width: 280px;
          }
          @media screen and (max-width:1600px) {
            width: 215px;
          }
          @media screen and (max-width:1200px) {
            width: 170px;
          }
          @media screen and (max-width:992px) {
            width: 124px;
          }

        }
        .expand-dailyplan2 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width:3000px) {
            width: 350px;
          }
          @media screen and (max-width:1600px) {
            width: 265px;
          }
          @media screen and (max-width:1200px) {
            width: 207px;
          }
          @media screen and (max-width:992px) {
            width: 153px;
          }
        }
        .expand-dailyplan3 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width:3000px) {
            width: 200px;
          }
          @media screen and (max-width:1600px) {
            width: 170px;
          }
          @media screen and (max-width:1200px) {
            width: 133px;
          }
          @media screen and (max-width:992px) {
            width: 94px;
          }
        }
        .expand-dailyplan4 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width:3000px) {
            width: 200px;
          }
          @media screen and (max-width:1600px) {
            width: 170px;
          }
          @media screen and (max-width:1200px) {
            width: 134px;
          }
          @media screen and (max-width:992px) {
            width: 98px;
          }
        }
        .expand-dailyplan-m {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width:767px) {
            width: 435px;
          }
          @media screen and (max-width:575px) {
            width: 255px;
          }
        }
        .view-daily {
          margin: 30px auto;
          max-width: 1080px;
          padding: 0 10px;
        }
        .view-daily-m {
          margin: 30px auto;
          max-width: 90%;
        }

        .view-plan-table1 {
          border-bottom: 1px solid ;
          margin-top: 10px;
          .pljp-table-tbody > tr:first-child > td:first-child {
            border-top: 1px solid #00838F;
            border-bottom:1px solid white;
            background-color: #00838F;
            border-right: 1px solid #00838F;
            color: white;
            font-weight: bold;
            text-align: center;
          }
          .pljp-table-tbody > tr:last-child > td:first-child {
            border-top: 1px solid #00838F;
            border-bottom: none;
            background-color: #00838F;
            border-right: 1px solid #00838F;
            color: white;
            font-weight: bold;
            text-align: center;
          }
          .pljp-table-thead > tr > th, .pljp-table-tbody > tr > td {
            padding: 10px;
          }
        }

        .table-record-m {
          .pljp-table-tbody > tr:last-child > td {
            border-bottom: 1px solid;
          }
        }
      }
      .title-view-tab3{
        background-color: #00bcd4;
        margin-top: 20px;
        font-weight: bold;
      }
      .input-value-tab3{
        border-bottom: 2px solid;
        margin-top: 20px;
        float: right;
        word-wrap: break-word;
      }
      .btn-change{
        padding: 0 5px;
      }
      .pljp-radio-button-wrapper {
        background: darkgray;

      }
      .pljp-radio-button-wrapper-checked {
        color: gray;
        border-color: lightgray;
        box-shadow: none;
        background-color: white;
      }

      .data-plan2{
        .pljp-table table {
          border-collapse: collapse;
        }
        .view-plan-table3{
          .pljp-table table {
            width: 100%;
            text-align: left;
            border-radius: 4px 4px 0 0;
            border-collapse: collapse;
            border-spacing: 0;
          }
          .pljp-table-tbody > tr:first-child > td:first-child {
            border-top: 1px solid #00838F;
            border-bottom: 1px solid #00838F;
            background-color: #00838F;
            border-right: 1px solid #00838F;
            color: white;
            font-weight: bold;
            text-align: center;

          }
          .pljp-table-tbody > tr:first-child > td:nth-child(3) {
            border-top: 1px solid #00838F;
            border-bottom: 1px solid #00838F;
            background-color: #00838F;
            border-right: 1px solid #00838F;
            color: white;
            font-weight: bold;
            text-align: center;
          }
          .pljp-table-tbody > tr:first-child > td:nth-child(5) {
            border-top: 1px solid #00838F;
            border-bottom: 1px solid #00838F;
            background-color: #00838F;
            border-right: 1px solid #00838F;
            color: white;
            font-weight: bold;
            text-align: center;
          }
          .pljp-table-tbody > tr:first-child > td:nth-child(4) {
            // border-right: 0px;
          }
          .pljp-table-tbody > tr:first-child > td:nth-child(2){
            border-right: 0px;
          }
          .pljp-table-tbody > tr > td {
            border-bottom: 1px solid;
          }
        }
      .pljp-table-thead>tr>th {
          text-align: center;
          background-color: #00838F;
          text-align: center;
          font-weight: bold;
          color: white;
          border-left: 1px solid white;
      }
      .pljp-table-thead>tr>th:first-child {
        border-left: 1px;
    }
      @media only screen and (min-width: 768px) {
        margin: 0px auto;
        max-width: 1080px;
        padding: 0 10px;
      }
        margin: 0 auto;
        max-width: 90%;
        flex: 1;
        min-height: 600px;

        .view-plan-table {
          border-bottom: 1px solid ;
        }
        .content-tb1 {
        border-bottom: none;
        }
        .pljp-table-tbody > tr > td {
          padding: 10px;
          border-right: 0px;
        }
        .pljp-table-tbody > tr > td:last-child {
          border-right: 1px solid darkgray;
        }
        .pljp-table-tbody:last-child > tr > td {
          border-bottom: 1px solid;
        }
        .plan2-tb-bottom {
          margin-top: 20px;
          margin-bottom: 30px;
          .pljp-table-tbody > tr > td:first-child {
            border-top: 1px solid #00838F;
            border-bottom: 1px solid #00838F;
            background-color: #00838F;
            border-right: 1px solid #00838F;
            color: white;
            font-weight: bold;
            text-align: center;
          }
        }
      }
      .pljp-layout-content {
        display: flex;
        flex-flow: column;
        height: 100vh;
      }

    // monitoring view
    .view-monitoring-container {
      padding-top: 20px;

      .view-plan-table2,
      .view-plan-table3 {
        td:last-child {
          border-right: 1px solid gray !important;
        }
      }
      .view-monitoring-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        &__right {
          text-align: right;
        }
      }
      .view-monitoring-table {
        margin-top: 20px;
        .pljp-table {
          width: 100%;
          border-radius: 0;
          border-right-color: gray;
          border-bottom-color: gray;
        }
        tbody {
          td {
            border-right: 0;
          }
        }
        th {
          border-radius: 0;
          color: #fff;
          text-align: center;
          font-weight: bold;
          border-left: 1px solid #fff;
          &:first-child {
            border-left: 0;
          }
        }
        tr:hover td {
          background-color: unset !important;
        }
      }
      .view-plan-table3,
      .view-plan-table4 {
        tr:hover td:nth-child(even) {
          background-color: unset !important;
        }
      }
    }

    .view-monitoring-container-m,
    .view-keika-kiroku-container-m {
      margin: 20px auto;
      max-width: 90%;
      min-height: 600px;
    }
    .view-monitoring-table-m {
      margin-top: 30px;
    }
    .view-monitoring-switch {
      margin-bottom: 10px;
    }
    .view-monitoring-header-m {
      .pljp-row {
        margin-bottom: 10px;
      }
      span {
        display: inline-block;
        min-width: 100px;
        border-bottom: 1px solid gray;
      }
    }
    .view-monitoring-mokuhyou {
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      padding: 5px 5px 0 5px;
      margin-bottom: 5px;

      &--show {
        .view-monitoring-mokuhyou__content {
          display: block;
        }
      }
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: -5px -5px 0 -5px;
        padding: 5px 0 5px 5px;
        background-color: #d9d9d9;
        font-weight: bold;
        button {
          color: gray;
        }
      }
      &__title {
        font-weight: bold;
        margin-right: 7px;
      }
      &__content {
        margin-top: 5px;
        display: none;

        .pljp-row {
          margin-bottom: 5px;
        }
      }
    }
    .view-monitoring-result {
      margin-left: -5px;
      margin-right: -5px;
      border-top: 1px solid #d9d9d9;

      &-item {
        display: flex;
        border-bottom: 1px solid #d9d9d9;

        &-left {
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #d9d9d9;
          padding: 0 5px;
          min-width: 30px;
        }
        &-right {
          padding: 5px;
        }
      }
    }
    .view-monitoring-hyoka {
      border: 1px solid #d9d9d9;
      padding: 5px;
      margin-bottom: 5px;
      border-radius: 4px;

      &__header {
        margin: -5px -5px 5px -5px;
        padding: 5px;
        background-color: #d9d9d9;
        font-weight: bold;
      }
    }
    .view-keika-kiroku-container {
      padding-top: 30px;
      padding-bottom: 30px;
      td {
        &:nth-child(odd) {
          text-align: center;
        }
      }
      .view-table1,
      .view-table11,
      .view-table12 {
        td {
          border-right: 0;
          &:last-child {
            border-right: 1px solid gray;
          }
          &:nth-child(odd) {
            color: #fff;
            background-color: #00838F;
            font-weight: bold;
          }
        }
      }
      .view-table12 td,
      .view-table2 tr:last-child td,
      .view-table3 td {
        border-bottom: 1px solid gray;
      }
      .view-table1 {
        td {
          &:first-child {
            border-bottom: 1px solid #fff;
          }
        }
      }
      .view-table11 {
        td {
          &:nth-child(odd) {
            border-bottom: 1px solid #fff;
          }
        }
      }
      .view-table5 {
        td {
          border-bottom: 1px solid gray;
        }
      }
    }
    .view-keika-kiroku-container-m {
      .text-bold {
        font-weight: bold;
        min-width: 80px;
        display: inline-block;
      }
      .view-keika-kiroku-text {
        margin-bottom: 10px;
        .text-bold {
          display: block;
        }
      }
      .pljp-col:not(.view-keika-kiroku-text) {
        margin-bottom: 5px;
        span:not(.text-bold) {
          border-bottom: 1px solid gray;
          min-width: 40px;
          display: inline-block;
        }
      }
    }
    .view-monitoring-comment {
      margin-top: 20px;
      .text-bold {
        margin-bottom: 0;
        font-weight: bold;
      }
    }

    .view-tanto-kaigi-container {
      margin: 0px auto;
      max-width: 1080px;
      padding: 30px 10px;
      .text-bold {
        display: inline-block;
        font-weight: bold;
        margin-right: 8px;
      }
      .view-table {
        td {
          border-right-width: 0;
          &:last-child {
            border-right-width: 1px;
          }
          &:nth-child(odd) {
            text-align: center;
            color: #fff;
            background-color: #00838F;
            font-weight: bold;
          }
        }

        &--2,
        &--7,
        &--9 {
          margin-bottom: 20px;
        }

        &--2,
        &--7,
        &--8,
        &--9 {
          td {
            border-bottom: 1px solid gray;
          }
        }

        &--2,
        &--4,
        &--5,
        &--6,
        &--7 {
          td:nth-child(odd) {
            border-top-color: #fff;
          }
        }

        &--9 {
          td:last-child {
            padding: 0;
          }
          tr:hover {
            td:last-child {
              background-color: unset;
            }
          }
        }

        &--10 {
          .pljp-table-placeholder {
            display: none;
          }
          td {
            background-color: unset !important;
            color: unset !important;
            font-weight: normal !important;
            border-width: 0 0 1px 0 !important;
            text-align: left !important;
          }
          tr {
            &:last-child {
              td {
                border-bottom: 0 !important;
              }
            }
            &:hover {
              td {
                background-color: #bacfcd !important;
              }
            }
          }
        }
      }
    }
    .view-tanto-kaigi-container-m {
      margin: 20px auto;
      max-width: 90%;
      min-height: 600px;
      .text-bold {
        display: inline-block;
        font-weight: bold;
        margin-right: 8px;
      }
      .view-tanto-kaigi {
        &-buttons {
          .text-bold {
            width: 60px;
          }
          .pljp-col {
            margin-bottom: 5px;
          }
          span:not(.text-bold) {
            border-bottom: 1px solid gray;
          }
        }
        &-attendee {
          margin-top: 20px;
          margin-bottom: 20px;

          &__notfound {
            border-bottom: 1px solid gray;
          }
        }
        &-textareas {
          .pljp-col {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
          }
        }
        &-hiyou {
          .pljp-col {
            margin-bottom: 10px;
          }
        }
        &-textareas,
        &-hiyou {
          span:not(.text-bold) {
            display: inline-block;
            border-bottom: 1px solid gray;
          }
        }
        &-hiyou {
          .pljp-col {
            display: flex;
            &:first-child {
              padding-right: 10px;
            }
          }
          span:not(.text-bold) {
            flex: 1
          }
        }
        &-attendance {
          border-radius: 4px;
          margin-bottom: 4px;
          padding: 8px 8px 0 8px;
          border: 1px solid #d9d9d9;

          &__no {
            margin: -8px -8px 0 -8px;
            background-color: #d9d9d9;
            padding: 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            button {
              color: #777;
            }
          }
          &__affiliation {
            padding-top: 8px;
          }
          &__name {
            padding-bottom: 8px;
          }
          &__affiliation,
          &__type,
          &__name {
            display: none;
            .text-bold {
              width: 30px;
            }
          }
        }
        &-attendance--show {
          .view-tanto-kaigi {
            &-attendance {
              &__affiliation,
              &__type,
              &__name {
                display: block;
              }
            }
          }
        }
      }
    }
  }
