.view-Inquiry-request {
  margin: 0px auto;
  .view-table1 {
    margin-top: 30px;
    .pljp-table-tbody > tr > td:nth-child(2n) {
      padding: 10px 10px;
      border-bottom: 1px solid #474747;
      border-top: 1px solid #474747;
    }
    .pljp-table-tbody > tr:last-child > td:nth-child(1) {
      border-bottom: 1px solid #ffffff !important;
    }
    .pljp-table-tbody > tr:last-child > td:nth-child(1),
    .pljp-table-tbody > tr:last-child > td:nth-child(3),
    .pljp-table-tbody > tr:last-child > td:nth-child(5) {
      border-bottom: 1px solid #474747;
      border-top: 1px solid #474747;
      background-color: #00838f;
      border-right: 1px solid #00838f;
      color: white;
      font-weight: bold;
      text-align: center;
    }
    .pljp-table-tbody > tr:last-child > td:last-child {
      border-right: 1px solid #474747;
    }
  }
  .view-table2 {
    .pljp-table-tbody > tr:last-child > td:nth-child(1) {
      border-top: 1px solid #00838f;
      border-bottom: 1px solid #474747;
      background-color: #00838f;
      border-right: 1px solid #00838f;
      color: white;
      font-weight: bold;
      text-align: center;
    }
    .pljp-table-tbody > tr:last-child > td:nth-child(2) {
      border-bottom: 1px solid #474747;
      border-right: 1px solid #474747;
    }
  }
  &__table {
    margin-top: 10px;
    .pljp-table-thead {
      background-color: #00838f;
    }
    .pljp-table-thead > tr > th {
      color: white;
    }
  }
  .view-Inquiry-request-mobile {
    &__infos {
      padding: 8px 16px;
      margin-top: 10px;
      width: 80%;
    }
    &__info {
      display: flex;
      margin-bottom: 8px;
      .title {
        font-weight: 600;
        max-width: 30%;
        flex-grow: 1;
      }
      .data {
        border-bottom: 1px solid #8c8c8c;
      }
    }
    &__info-last {
      margin-bottom: 10px;
      .title {
        font-weight: 600;
      }
    }
    &__table__info {
      display: flex;
      margin-bottom: 5px;
      .title {
        font-weight: 600;
        max-width: 30%;
        display: flex;
        flex-grow: 1;
        white-space: nowrap;
      }
      span {
        display: flex;
        flex: 1;
      }
    }
  }
  .menu-header {
    display: flex;
    justify-content: space-between;
    color: @white;
    background-color: #212121d9;
    padding: 8px 16px;
    .sm-c-left {
      align-items: center;
      p {
        margin-right: 5px;
      }
      p:nth-child(2),
      p:nth-child(3) {
        font-size: 25px;
      }
    }
    .sm-c-right {
      display: flex;
      .item-link {
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: @white;
        transition: 0.3s all;
        padding: 2px 8px;
        &:hover {
          background-color: #e0e0e0;
          border-radius: 4px;
        }
      }
    }
  }
  .menu-header-mobile {
    background-color: #212121d9;
    color: #ffffff;
    padding: 8px 16px;
    .sm-c-left {
      &__name {
        display: flex;
        p {
          margin-right: 5px;
        }
      }
    }
  }
  .menu-footer-mobile {
    position: fixed;
    background-color: #212121d9;
    color: @white;
    height: 45px;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    width: 100%;
    .sm-c-right {
      display: flex;
      .item-link {
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: @white;
        transition: 0.3s all;
        padding: 2px 8px;
        margin-top: 5px;
      }
    }
  }
  .button-callback-view {
    background-color: transparent;
    border: none;
    display: flex;
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  @media (max-width: 1024px) {
    .menu-header {
      .sm-c-right {
        .item-link {
          &:hover {
            background-color: unset;
          }
        }
      }
    }
  }
}
