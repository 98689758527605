.main-header {
  padding: 12px;
  padding-right: 16px;
  color: #fff; }
  .main-header .h-left ul li {
    display: block;
    float: left; }
  .main-header .h-left img {
    height: 40px; }
  .main-header .h-left h2 {
    color: #fff;
    padding-left: 8px;
    margin-top: 30px; }
  .main-header .h-left .h-l-timeout span {
    display: block;
    line-height: 20px;
    text-align: center;
    padding-left: 8px;
    margin-top: 2px; }
  .main-header .h-right .h-r-item {
    display: block;
    float: right;
    margin-left: 8px;
    height: 40px; }
    .main-header .h-right .h-r-item h2 {
      color: #fff;
      margin-top: 28px; }
    .main-header .h-right .h-r-item .pljp-dropdown-menu-item-disabled {
      background: #bacfcd;
      color: #00838F; }
  .main-header .h-right .h-r-item:first-of-type {
    border: 1px solid #fff;
    border-radius: 3px; }
    .main-header .h-right .h-r-item:first-of-type i {
      font-size: 40px; }

.h-r-submenu ul li a {
  color: #333333; }

.submenu {
  background-color: rgba(33, 33, 33, 0.85);
  padding: 8px 16px; }
  .submenu .sm-content {
    height: 40px;
    margin: 0 auto; }

.sm-content {
  color: #fff; }
  .sm-content .sm-c-left h3 {
    color: #fff;
    margin-top: 10px; }
  .sm-content .sm-c-right ul li {
    float: right;
    margin-left: 16px;
    padding: 0px 8px; }
    .sm-content .sm-c-right ul li span {
      display: block;
      text-align: center; }
    .sm-content .sm-c-right ul li a {
      color: #fff; }
  .sm-content .sm-c-right .active {
    border: 1px solid #fff;
    border-radius: 3px;
    background-color: #aaa; }
    .sm-content .sm-c-right .active i {
      color: yellow; }
  .sm-content .sm-c-right :hover {
    border-radius: 3px;
    background-color: #aaa; }

.logout-modal .pljp-btn-primary {
  background-color: #00838F; }

.logout-modal .pljp-btn-primary:hover {
  background-color: #02acbb; }

.pljp-menu-vertical .pljp-menu-item {
  margin-top: 0px;
  margin-bottom: 0px !important; }
