#riyousya-shiryou-page .riyousya-shiryou-content {
  max-width: 960px;
  margin: 0 auto; }
  #riyousya-shiryou-page .riyousya-shiryou-content .rsp-c-tab {
    position: relative;
    display: inline-block;
    margin-top: 16px;
    width: 100%;
    padding: 8px 8px;
    border: 1px solid #ebedf0;
    border-radius: 2px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
  #riyousya-shiryou-page .riyousya-shiryou-content .rscp-content-list .rscp-content-list-title span {
    display: block;
    color: #333;
    line-height: 24px; }
    #riyousya-shiryou-page .riyousya-shiryou-content .rscp-content-list .rscp-content-list-title span span {
      display: inline; }
  #riyousya-shiryou-page .riyousya-shiryou-content .rscp-content-list .pljp-avatar {
    width: 80px;
    height: 80px;
    border-radius: 0; }
  #riyousya-shiryou-page .riyousya-shiryou-content .pljp-list-item {
    padding: 4px 0; }
  #riyousya-shiryou-page .riyousya-shiryou-content .rscp-content-list-action-up .rscp-content-list-action-up-l {
    background: #00838F;
    color: #ffffff;
    padding: 2px 4px;
    font-size: 14px;
    margin-bottom: 4px;
    float: left;
    line-height: 16px;
    border-radius: 3px; }
  #riyousya-shiryou-page .riyousya-shiryou-content .rscp-content-list-action-up .rscp-content-list-action-up-r {
    float: right; }
  #riyousya-shiryou-page .riyousya-shiryou-content .rscp-content-list-action-down {
    padding: 8px 18px 4px 18px;
    background: #00838F;
    color: #ffffff;
    border-radius: 4px; }
    #riyousya-shiryou-page .riyousya-shiryou-content .rscp-content-list-action-down a {
      color: #ffffff; }
    #riyousya-shiryou-page .riyousya-shiryou-content .rscp-content-list-action-down span {
      display: block; }
  #riyousya-shiryou-page .riyousya-shiryou-content .rscp-content-list-action-down:hover {
    background: #02acbb; }
  #riyousya-shiryou-page .riyousya-shiryou-content .pljp-list-item-action {
    width: 80px;
    margin-left: 0px;
    padding-left: 8px; }

#riyousya-shiryou-page .pljp-tabs-bar {
  margin: 0 0 8px 0; }

#riyousya-shiryou-page .pljp-tabs-nav .pljp-tabs-tab {
  padding: 6px 8px; }

#riyousya-shiryou-page .pljp-tabs.pljp-tabs-card .pljp-tabs-card-bar .pljp-tabs-tab {
  line-height: 30px; }

#riyousya-shiryou-addedif .riyousya-shiryou-a-c {
  max-width: 960px;
  margin: 24px auto; }
