.tabinput-content {
  margin: 20px auto 0;
  display: flex;
  align-content: flex-end;
  flex-wrap: wrap;

  .pljp-select {
    width: 200px;
  }

  .pljp-tag{
    height: auto;
  }

  .pljp-tag-checkable {
    border-radius: 3px;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 0.25em 0.5em;
    display: inline-block;
    color: #000;
    background: #fff;

    font-size: 1em;

    :hover {
      color: #fff !important;
      background: #c7c7c7;
    }
  }

  .pljp-tag-checkable:hover {
    background: #6e6e6e;
    color: #fff !important;
  }

  .pljp-tag-checkable-checked {
    border-radius: 3px;
    margin-right: 5px;
    padding: 0.25em 0.5em;
    display: inline-block;
    background: #7e7e7e;
    color: #fff;
  }
}
