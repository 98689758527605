.tanto-master {
  &__select {
    .pljp-select-arrow {
      display: flex;
      justify-content: flex-end;
      width: 10px;
    }
  }
}

.form-controller{
  .pljp-legacy-form-item-control-wrapper{
    width: 95%  !important;
  }
}