.weekly-action-row {
  padding: 0px !important;
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd !important; }
  .weekly-action-row .w-a-btn {
    border-top: 1px solid #ddd;
    padding: 8px;
    margin-top: 8px;
    background-color: #bacfcd;
    display: flex; }
    .weekly-action-row .w-a-btn .w-a-btn-del {
      background-color: #aaaaaa;
      border: 1px solid #fff; }
    .weekly-action-row .w-a-btn .w-a-btn-edit {
      border: 1px solid #fff;
      margin-left: auto;
      order: 2; }
    .weekly-action-row .w-a-btn .w-a-btn-add {
      background-color: #d32f2f;
      margin-right: 4px;
      border: 1px solid #fff;
      margin-left: auto;
      order: 2; }
    .weekly-action-row .w-a-btn .w-a-btn-del-all {
      background-color: #aaaaaa;
      border: 1px solid #fff;
      margin-right: 4px; }

#rwa-content {
  max-width: 960px;
  margin: 0 auto; }
  #rwa-content .margin24 {
    margin-top: 24px; }
  #rwa-content .pljp-collapse-header {
    background-color: #4db6ac;
    color: #fff; }
  #rwa-content .pljp-collapse-content {
    background-color: #fff;
    padding-top: 16px;
    padding-bottom: 16px; }
    #rwa-content .pljp-collapse-content .pljp-collapse-content-box {
      padding: 0px; }
  #rwa-content .rwa-c-comment-all {
    margin-bottom: 16px; }
    #rwa-content .rwa-c-comment-all button {
      float: right;
      margin-left: 16px; }
  #rwa-content .pljp-form-item {
    margin-bottom: 0px; }
  #rwa-content .rwf-title {
    font-weight: 600;
    color: #333333;
    font-size: 16px; }
  #rwa-content .kwf-vital-input {
    width: 190px;
    float: left; }
    #rwa-content .kwf-vital-input .pljp-form-item-control-wrapper {
      width: 190px; }
      #rwa-content .kwf-vital-input .pljp-form-item-control-wrapper .pljp-radio-group {
        padding-top: 4px; }
  #rwa-content .pljp-input-number {
    margin-right: 8px; }
  #rwa-content .rwf-fix-small-title {
    position: absolute;
    left: -24px; }
  #rwa-content .pljp-card-body {
    background-color: #cccccc; }
  #rwa-content .pljp-legacy-form-item {
    margin-bottom: 8px; }
  #rwa-content .pljp-upload-wrapper .pljp-upload-drag {
    background-color: white; }

#Sub-m-weekly-add .pljp-calendar-picker-input {
  border-radius: 4px;
  color: #333333; }

#Sub-m-weekly-add .kmu-menu-detail {
  color: #333333; }

#Sub-m-weekly-add .pljp-calendar-picker-icon {
  width: 60px; }

#Sub-m-weekly-add .kmu-bu-save {
  color: #fff;
  background: #d32f2f;
  padding: 4px 16px;
  font-size: 14px;
  margin-left: 16px; }

.weekly-model-dae .pljp-modal-body {
  padding: 8px; }

.weekly-model-dae .pljp-list-pagination {
  margin: 8px; }

.weekly-model-dae .active-row {
  background: #4db6ac; }

#report-keekly-add-page .zenkai-value, #report-keekly-add-page .normal-value {
  opacity: 0.7;
  padding-top: 4px; }

#report-keekly-add-page .normal-value2 {
  opacity: 0.7; }

.fix-menu-save-return-1 {
  position: absolute;
  top: 0;
  right: 0; }
  .fix-menu-save-return-1 i {
    display: block;
    margin-bottom: 1px; }

.fix-menu-save-return-2 {
  position: absolute;
  top: 0;
  right: 50px; }
  .fix-menu-save-return-2 i {
    display: block;
    color: red;
    margin-bottom: 1px; }
