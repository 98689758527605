.weekly-action-row {
  padding: 0px !important;
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd !important; }
  .weekly-action-row .w-a-btn {
    border-top: 1px solid #ddd;
    padding: 8px;
    margin-top: 8px;
    background-color: #BACFCD;
    display: flex; }
    .weekly-action-row .w-a-btn .w-a-btn-del {
      background-color: #aaaaaa;
      border: 1px solid #fff; }
    .weekly-action-row .w-a-btn .w-a-btn-edit {
      border: 1px solid #fff; }
    .weekly-action-row .w-a-btn .w-a-btn-add {
      background-color: #d32f2f;
      margin-right: 4px;
      border: 1px solid #fff; }
    .weekly-action-row .w-a-btn .w-a-btn-del-all {
      background-color: #aaaaaa;
      border: 1px solid #fff;
      margin-right: 4px; }

.weekly-model-delall-mode .pljp-modal-header, .weekly-model-delall-mode .pljp-modal-footer {
  border-bottom: none;
  border-top: none; }

.weekly-model-delall-mode .pljp-modal-body {
  display: none; }

#tb-weekly .ant-table-thead > tr.ant-table-row-hover > td,
#tb-weekly .ant-table-tbody > tr.ant-table-row-hover > td,
#tb-weekly .ant-table-thead > tr:hover > td,
#tb-weekly .ant-table-tbody > tr:hover > td,
#tb-weekly tr:hover > td {
  background: none; }

#tb-weekly .pljp-table-thead > tr > th {
  background-color: #4DB6AC;
  color: white;
  text-align: center;
  font-weight: 600; }

#tb-weekly .weekly-col-time {
  background-color: #fafafa;
  text-align: center;
  font-weight: 600; }

#tb-weekly .tb-dis-type-normal {
  white-space: normal;
  padding: 0px; }
  #tb-weekly .tb-dis-type-normal p {
    margin-bottom: 0px; }

#tb-weekly .tb-dis-type-nowrap {
  white-space: nowrap;
  padding: 8px 0px;
  overflow: auto; }
  #tb-weekly .tb-dis-type-nowrap p {
    margin-bottom: 0px; }

#tb-weekly .padding-0 {
  padding: 0px; }

#tb-weekly .rwp-row11 td, #tb-weekly .rwp-row15 td, #tb-weekly .rwp-row35 td, #tb-weekly .rwp-row43 td {
  border-bottom: 1px solid blue; }

#tb-weekly .rwp-row23 td {
  border-bottom: 1px solid red; }

#tb-weekly .pljp-table-scroll .pljp-table-body,
#tb-weekly .pljp-table-fixed-left .pljp-table-body-inner,
#tb-weekly .pljp-table-fixed-right .pljp-table-body-inner {
  overflow: scroll !important;
  max-height: calc(100vh - 250px) !important; }
