.mobile-signin-page {
  .code-form-item-login {
    max-width: 320px;
  }
  .code-form-item-login .pljp-checkbox-wrapper {
    line-height: 1.8em;
  }
  @media (max-width: 575px) {
    .signin-page__main-top {
      // padding-top: 40px;
    }
  }
  &__main-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__top {
    display: inherit; // 親の継承
    flex-direction: inherit;
    align-items: inherit;
  }

  &__logo {
    margin-top: 40px;
    // TODO set width + height here

    img {
      width: 100%;
      height: 100%;
      display: flex;
      object-fit: cover;
    }
  }

  &__description {
    padding-top: 16px;
  }

  &__form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pljp-input-affix-wrapper {
      width: 323px;
      height: 40px;
    }
    button {
      width: 323px;
      height: 33px;
    }
  }

  &__maintainance-mode {
  }

  .signin-page__logo {
    margin-top: 20px;
  }
  .sigin-page__maintainance-mode {
    text-align: center;
    color: red;
  }
}
@media only screen and (max-device-width: 991.99px) {
  .sigin-page__maintainance-mode {
    color: red;
    margin-left: 40px;
    margin-right: 40px;
    text-align: center;
  }
}
