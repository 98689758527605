#duty-report-page .tableRoom .pljp-table-thead {
  display: none; }

#duty-report-page .toolBar {
  margin: 10px auto;
  max-width: 1200px;
  padding: 0 10px; }

#duty-report-page .btnSave {
  display: flex;
  justify-content: flex-end; }

#duty-report-page .inputContent {
  margin: 10px auto;
  max-width: 1155px;
  padding: 0 10px; }

#duty-report-page .btnCopy {
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-end; }

#duty-report-page .btnShowListStaff {
  width: 95%;
  min-width: 50px; }

#duty-report-page .staffContainer {
  width: 90%;
  min-height: 120px;
  padding-top: 20px; }
  @media screen and (max-width: 992px) {
    #duty-report-page .staffContainer {
      width: 100%; } }

#duty-report-page .tableItem3032 .pljp-table-tbody > tr > td {
  text-align: right;
  word-break: break-word; }

#duty-report-page .tableItem3032 .pljp-table-thead .pljp-table-row-cell-break-word {
  text-align: left; }

@media (max-width: 575px) {
  #duty-report-page .pljp-form-item-label {
    line-height: 2.9em; }
  #duty-report-page .pljp-form-item-label, #duty-report-page .pljp-form-item-control-wrapper {
    width: initial; } }

.pointer-row {
  cursor: pointer; }

.pljp-table-row-hover {
  cursor: pointer; }
