.tabrecords-content {
  margin: 0 auto;
  margin-bottom: 3px; }
  .tabrecords-content .pljp-tag {
    height: auto; }
  .tabrecords-content .pljp-tag-checkable {
    border-radius: 3px;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 0.2em 0.5em;
    display: inline-block;
    color: #fff;
    background: #aaa;
    font-size: 1.1em; }
    .tabrecords-content .pljp-tag-checkable :hover {
      color: #fff !important;
      background: #c7c7c7; }
  .tabrecords-content .pljp-tag-checkable:hover {
    background: #6e6e6e;
    color: #fff !important; }
  .tabrecords-content .pljp-tag-checkable-checked {
    border-radius: 3px;
    margin-right: 5px;
    padding: 0.2em 0.5em;
    display: inline-block;
    background: #aaa;
    color: #333333; }
