#plan-page .pljp-picker-calendar-header {
  display: none; }

#plan-page .pljp-picker-calendar-header {
  display: none; }

#plan-page table.pljp-picker-content tr th {
  font-weight: bold;
  text-align: center !important; }

#plan-page table.pljp-picker-content tr td .pljp-picker-calendar-date-value {
  font-weight: bold;
  text-align: left !important; }

#plan-page table.pljp-picker-content tr th:nth-child(1) {
  color: red; }

#plan-page table.pljp-picker-content tr td:nth-child(1):not(.pljp-picker-cell-disabled):not(.pljp-picker-calendar-next-month-btn-day) .pljp-picker-calendar-date-value {
  color: red; }

#plan-page table.pljp-picker-content tr th:nth-child(7) {
  color: blue; }

#plan-page table.pljp-picker-content tr td:nth-child(7):not(.pljp-picker-cell-disabled):not(.pljp-picker-calendar-next-month-btn-day) .pljp-picker-calendar-date-value {
  color: blue; }

#plan-page .event-plan-table .pljp-table-body table tr td:nth-child(1) {
  display: none; }

#plan-page .event-plan-table .pljp-table-body table colgroup col:nth-child(1) {
  display: none; }

#plan-page .pljp-picker-calendar-date-value {
  border-bottom: 1px solid; }

#plan-page .yotei-input .pljp-col-21 {
  width: calc(100% - 73px); }

#plan-page #sub-header-v2 #submenu-mobile h3 {
  display: inline; }

#plan-page #submenu-mobile {
  margin: 10px 0; }

#plan-page .pljp-btn-primary:disabled, #plan-page .pljp-btn-primary.pljp-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background: white;
  box-shadow: none; }
