@color0: #ffffff; // Appears 5 times
@color1: #00838f; // Appears 3 times


.view-keika-kiroku-container {
	margin: 0px auto;
	max-width: 1080px;
	padding: 0 10px;
	.pljp-table-tbody>tr>td {
		padding: 10px 10px;
  }
  padding-bottom: 30px !important ;
  tr, td {
    border: 1px solid gray;
    border-bottom: unset;
  }
  padding-top: 30px !important;
  td {
    &:nth-child(odd) {
      text-align: center;
    }
  }
}
	.view-keika-kiroku-container-m {
		margin: 20px auto;
		max-width: 90%;
		min-height: 600px;
		.text-bold {
			display: inline-block;
			font-weight: bold;
			min-width: 80px;
		}
		.view-keika-kiroku-text {
			margin-bottom: 10px;
			.text-bold {
				display: block;
			}
		}
		.pljp-col {
			&:not(.view-keika-kiroku-text) {
				margin-bottom: 5px;
				span {
					&:not(.text-bold) {
						border-bottom: 1px solid gray;
						display: inline-block;
						min-width: 40px;
					}
				}
			}
		}
	}
	.view-keika-kiroku-container {
	
		.view-table1 {
			td {
				border-right: 0;
				&:last-child {
					border-right: 1px solid gray;
				}
				&:nth-child(odd) {
					background-color: @color1 !important;;
					color: @color0;
					font-weight: bold;
				}
				&:first-child {
					border-bottom: 1px solid @color0;
				}
			}
		}
		.view-table11 {
			td {
				border-right: 0;
				&:last-child {
					border-right: 1px solid gray;
				}
				&:nth-child(odd) {
					background-color: @color1 !important;;
					border-bottom: 1px solid @color0;
					color: @color0;
					font-weight: bold;
				}
			}
		}
		.view-table12 {
			td {
				border-bottom: 1px solid gray;
				border-right: 0;
				&:last-child {
					border-right: 1px solid gray;
				}
				&:nth-child(odd) {
					background-color: @color1 !important;;
					color: @color0;
					font-weight: bold;
				}
			}
		}
		.view-table2 {
			tr {
				&:last-child {
					td {
						border-bottom: 1px solid gray;
					}
				}
			}
		}
		.view-table3 {
			td {
				border-bottom: 1px solid gray;
			}
		}
		.view-table5 {
			td {
				border-bottom: 1px solid gray;
			}
    }
    .title-view1 {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid white;
      background-color: #00838F !important;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }
	}

  