#report-page-user .pljp-table-expanded-row-fixed {
  display: flex;
  justify-content: center; }

#report-page-user .tabrecords {
  background-color: rgba(33, 33, 33, 0.85);
  padding: 4px 16px; }

#report-page-user .dr-datapicker {
  margin: 0 auto;
  padding: 16px 0; }

#report-page-user #dailyrecords {
  margin: 0 auto;
  padding: 16px 0; }

#report-page-user .dr-datapicker .pljp-btn-group > div {
  display: inline; }
  #report-page-user .dr-datapicker .pljp-btn-group > div input {
    border: 1px solid #d9d9d9;
    padding: 5px;
    text-align: center; }

#report-page-user .s-filter-form .sa-f-item {
  margin-top: 8px; }

#report-page-user .a-filter-form .sa-f-item {
  margin-top: 8px; }

#report-page-user .a-filter-form .f-by-keyboard .pljp-form-item {
  margin-right: 4px; }

#report-page-user .table-row-bt button {
  margin-right: 8px;
  padding: 0 8px;
  height: 28px; }

#report-page-user .table-row-bt :last-child {
  margin-right: 0px; }

#report-page-user .table-row-bt:parrent {
  text-align: center; }

#report-page-user .kn-search-con .pljp-form-item {
  padding-right: 16px;
  margin-bottom: 16px; }

#report-page-user .rl-mobile .pljp-list-item-content {
  display: unset; }

#report-page-user .rl-mobile .rl-m-item {
  margin-top: 8px;
  padding-right: 16px; }
  #report-page-user .rl-mobile .rl-m-item .table-row-bt button {
    margin-top: 8px; }

#report-page-user .nts-gender-red {
  color: red; }

#report-page-user .nts-gender-blue {
  color: blue; }

#report-page-user .ntk-riyousya-colum .ntl-riyousya-gender {
  float: right; }

#report-page-user .pljp-table-small > .pljp-table-content > .pljp-table-body {
  margin: 0px; }

#report-page-user .table-kiroku-ri .pljp-table-tbody > tr > td:first-of-type {
  padding: 0px !important;
  overflow: hidden;
  position: relative; }
  #report-page-user .table-kiroku-ri .pljp-table-tbody > tr > td:first-of-type .nuytaisho-yellow {
    display: inline-block;
    width: 100%;
    padding: 8px;
    text-align: center;
    background: yellow;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%; }
  #report-page-user .table-kiroku-ri .pljp-table-tbody > tr > td:first-of-type .nuytaisho-blue {
    display: inline-block;
    width: 100%;
    padding: 8px;
    text-align: center;
    background: #89e1fd;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%; }

#report-page-user .table-kiroku-ri .pljp-layout-content .pljp-table-fixed-left .pljp-table-tbody .joutaiCol {
  padding: 0px !important;
  overflow: hidden;
  position: relative; }
  #report-page-user .table-kiroku-ri .pljp-layout-content .pljp-table-fixed-left .pljp-table-tbody .joutaiCol .nuytaisho-yellow {
    display: inline-block;
    width: 100%;
    padding: 8px;
    text-align: center;
    background: yellow;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%; }
  #report-page-user .table-kiroku-ri .pljp-layout-content .pljp-table-fixed-left .pljp-table-tbody .joutaiCol .nuytaisho-blue {
    display: inline-block;
    width: 100%;
    padding: 8px;
    text-align: center;
    background: #89e1fd;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%; }

#report-page-user .pljp-table-expanded-row > td > p {
  padding: 4px 0px;
  text-align: center; }

#report-page-user .mff-row-2 .pljp-form-item {
  margin-right: 8px; }
  #report-page-user .mff-row-2 .pljp-form-item input {
    max-width: 140px; }

#report-page-user .mff-row-2 .pljp-form-item:last-child {
  margin-right: 0; }

#report-page-user .pljp-table-body-inner .pljp-table-fixed .pljp-table-expanded-row td {
  height: 37px; }
  #report-page-user .pljp-table-body-inner .pljp-table-fixed .pljp-table-expanded-row td > p {
    display: none; }

#report-page-user .icon-table {
  text-align: center;
  width: 100%; }

#report-page-user .add-edit {
  width: 100%;
  height: 30xp !important;
  background: red; }

#report-page-user .pljp-tooltip-inner {
  background: #00838f; }

#report-page-user .pljp-tooltip-placement-bottomRight .pljp-tooltip-arrow {
  border-bottom-color: #00838f; }

#report-page-user .pljp-table-fixed > .pljp-table-tbody .pljp-table-row .timeCol {
  padding: 0px !important;
  overflow: hidden;
  position: relative; }
  #report-page-user .pljp-table-fixed > .pljp-table-tbody .pljp-table-row .timeCol .bg_green {
    display: inline-block;
    width: 100%;
    padding: 8px;
    text-align: center;
    background: #80c380;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }
  #report-page-user .pljp-table-fixed > .pljp-table-tbody .pljp-table-row .timeCol .bg_pink {
    display: inline-block;
    width: 100%;
    padding: 8px;
    text-align: center;
    background: pink;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }

#report-page-user .pljp-table-fixed > .pljp-table-tbody .pljp-table-row .haisetsuCol {
  padding: 0px !important;
  overflow: hidden;
  position: relative; }
  #report-page-user .pljp-table-fixed > .pljp-table-tbody .pljp-table-row .haisetsuCol .bg_haiben {
    display: inline-block;
    width: 100%;
    padding: 8px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }

#report-page-user .anticon-close-circle,
#report-page-user .pljp-calendar-picker-clear,
#report-page-user .pljp-calendar-input-wrap {
  display: none; }

#report-page-user .pljp-input:focus {
  box-shadow: none; }

#report-page-user .pljp-input {
  border-radius: 0px; }

#report-page-user .pljp-table-small
> .pljp-table-content
> .pljp-table-scroll
> .pljp-table-body
> table
> .pljp-table-tbody
> tr
> td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px; }

#report-page-user .pljp-tooltip-placement-bottomLeft .pljp-tooltip-arrow {
  border-bottom-color: #00838f; }

#report-page-user .pljp-tooltip-placement-bottomRight .pljp-tooltip-arrow {
  border-bottom-color: #00838f; }

#report-page-user .pljp-tooltip-placement-topLeft .pljp-tooltip-arrow {
  border-top-color: #00838f; }

#report-page-user .pljp-tooltip-placement-topRight .pljp-tooltip-arrow {
  border-top-color: #00838f; }

#report-page-user #gazouDrawerImage img {
  max-width: 100%; }

#report-page-user .drawerGazou .pljp-drawer-body {
  padding: 0px;
  height: 85%; }

#report-page-user .action-kiroku button {
  height: 21px;
  padding: 1px 2px;
  font-size: 12px; }

#report-page-user .inputMode {
  padding: 0px;
  height: 20px;
  min-width: 35px;
  font-size: 13px; }

#report-page-user .inputModeSpo2 {
  position: relative; }
  #report-page-user .inputModeSpo2 .anticon {
    width: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    color: blue; }

#report-page-user .konkaistyle {
  color: green; }

#report-page-user .title-common-value {
  float: right;
  padding-right: 50px; }

#report-page-user .model-top-row {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85); }
  #report-page-user .model-top-row .mtr-col1 {
    text-align: right;
    font-size: 14px; }

#report-page-user .pljp-modal-body {
  padding: 16px 24px 24px 24px; }

#report-page-user .number-pro button {
  width: 50px;
  height: 50px; }

#report-page-user .action-col .pljp-btn-background-ghost .pljp-btn-primary {
  color: red; }

#report-page-user .input-select .pljp-select-selection {
  background-color: #00838f;
  color: white;
  border: none; }

#report-page-user .input-select-disabled .pljp-select-selection {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

#report-page-user .text-base-color {
  color: rgba(0, 0, 0, 0.65); }

#report-page-user .inputNumber-pre,
#report-page-user .inputNumber-aft {
  border-radius: 4px;
  text-align: right;
  font-size: 20px;
  height: 40px;
  background: #fff !important;
  max-width: 196px; }

#report-page-user .text-area-select {
  max-width: 450px; }

#report-page-user .pad-bt-8 {
  padding-bottom: 8px; }

#report-page-user .ru-datepicker {
  max-width: 280px; }
  #report-page-user .ru-datepicker .pljp-calendar-picker-input {
    border-radius: 4px; }

#report-page-user .ru-filter .pljp-form-item {
  margin-bottom: 0px; }

#report-page-user .rpu-submenuinc {
  margin-bottom: 16px; }

.kiroku-input-modal .pljp-modal-footer {
  text-align: center; }

.kiroku-right-drawer .pljp-drawer-content-wrapper,
.kiroku-drawer .pljp-drawer-content-wrapper {
  width: calc(100% - 60px) !important;
  max-width: 720px !important; }

.kiroku-right-drawer .pljp-drawer-body,
.kiroku-drawer .pljp-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0; }

.kiroku-right-drawer__title,
.kiroku-drawer__title {
  padding: 15px 20px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85); }

.kiroku-right-drawer__content,
.kiroku-drawer__content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow-y: auto;
  transition: all 0.35s; }
  .kiroku-right-drawer__content .pg-viewer-wrapper,
  .kiroku-drawer__content .pg-viewer-wrapper {
    overflow-y: unset;
    width: 100% !important; }
  .kiroku-right-drawer__content .unsupported-message,
  .kiroku-drawer__content .unsupported-message {
    height: 100%;
    display: flex;
    align-items: center; }
  .kiroku-right-drawer__content .photo-viewer-container,
  .kiroku-drawer__content .photo-viewer-container {
    width: 100% !important;
    height: 100% !important; }
    .kiroku-right-drawer__content .photo-viewer-container img,
    .kiroku-drawer__content .photo-viewer-container img {
      max-width: 100% !important;
      max-height: 100% !important;
      object-fit: contain;
      cursor: zoom-in; }
  .kiroku-right-drawer__content .pdf-canvas,
  .kiroku-drawer__content .pdf-canvas {
    display: flex; }
    .kiroku-right-drawer__content .pdf-canvas canvas,
    .kiroku-drawer__content .pdf-canvas canvas {
      margin: 0 auto; }
  .kiroku-right-drawer__content .loading-container,
  .kiroku-right-drawer__content .pdf-loading,
  .kiroku-drawer__content .loading-container,
  .kiroku-drawer__content .pdf-loading {
    display: none !important; }
  .kiroku-right-drawer__content--zoom-image,
  .kiroku-drawer__content--zoom-image {
    padding-top: 0;
    padding-bottom: 0; }
    .kiroku-right-drawer__content--zoom-image .photo-viewer-container,
    .kiroku-drawer__content--zoom-image .photo-viewer-container {
      height: auto !important; }
      .kiroku-right-drawer__content--zoom-image .photo-viewer-container img,
      .kiroku-drawer__content--zoom-image .photo-viewer-container img {
        object-fit: cover;
        cursor: zoom-out; }

.kiroku-right-drawer__thumb-icon,
.kiroku-drawer__thumb-icon {
  width: 80px !important;
  height: 80px !important; }

.kiroku-right-drawer__footer,
.kiroku-drawer__footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  border-top: 1px solid #e8e8e8; }
  .kiroku-right-drawer__footer button:first-child,
  .kiroku-drawer__footer button:first-child {
    margin-right: 10px; }
    .kiroku-right-drawer__footer button:first-child a,
    .kiroku-drawer__footer button:first-child a {
      margin-left: 5px; }
  .kiroku-right-drawer__footer button:disabled,
  .kiroku-drawer__footer button:disabled {
    color: #bbb; }
    .kiroku-right-drawer__footer button:disabled a,
    .kiroku-drawer__footer button:disabled a {
      color: inherit !important; }

.kiroku-drawer-form-file .pljp-drawer-body {
  overflow: auto;
  height: calc(100% - 90px); }
  .kiroku-drawer-form-file .pljp-drawer-body .pljp-spin-nested-loading,
  .kiroku-drawer-form-file .pljp-drawer-body .pljp-spin-container {
    height: 100%; }
  .kiroku-drawer-form-file .pljp-drawer-body .pljp-spin-container {
    display: flex;
    flex-direction: column; }
  .kiroku-drawer-form-file .pljp-drawer-body .unsupported-message {
    background-color: unset; }

#reportUser-page .table-kiroku-ri .pljp-table-bordered,
#reportUser-page .table-kiroku-ri .pljp-table-bordered > .pljp-table-content,
#reportUser-page .table-kiroku-ri .pljp-table-bordered .pljp-table-tbody > tr > td,
#reportUser-page .table-kiroku-ri .pljp-table-bordered .pljp-table-thead > tr > th,
#reportUser-page .table-kiroku-ri .pljp-table-small.pljp-table-bordered .pljp-table-fixed-left .pljp-table-thead > tr > th:last-child,
#reportUser-page .table-kiroku-ri .pljp-table-small.pljp-table-bordered .pljp-table-fixed-left .pljp-table-tbody > tr > td:last-child {
  border-color: #ccc; }

#reportUser-page .pljp-table-scroll .pljp-table-body,
#reportUser-page .pljp-table-fixed-left .pljp-table-body-inner,
#reportUser-page .pljp-table-fixed-right .pljp-table-body-inner {
  overflow: scroll !important;
  max-height: calc(100vh - 370px) !important; }

#reportUser-page .pljp-table-empty.pljp-table-fixed-header #reportUser-page .pljp-table-scroll .pljp-table-body .pljp-table-fixed {
  display: block;
  height: 1px; }
