@color-gray: #afafaf;
@color-select-row: #bacfcd;
@color-button: #77933c;

.container-subsidy {
  .sub-menu-top-subsidy {
    background-color: rgba(33, 33, 33, 0.85);
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;

    .m-right {
      display: flex;
      justify-content: space-between;

      a {
        padding: 4px;
      }

      .link-item {
        color: #fff;
        display: flex;
        flex-direction: column;
        transition: 0.3s all;
        margin-right: 10px;
        padding: 2px 5px;
        align-items: center;
        border-radius: 3px;
        text-align: center;
        p {
          padding-top: 4px;
          white-space: nowrap;
        }
      }

      .link-item:hover {
        background-color: #c9bfbf;

        @media screen and (max-device-width: 850px) {
          background-color: unset;
        }
      }

      .active {
        border: 1px solid #fff;
        border-radius: 3px;
        background-color: #aaa;

        i {
          color: yellow;
        }
      }
    }
  }

  .body {
    padding: 16px;

    .toolbar-subsidy-1 {
      display: flex;
      align-items: center;
      gap: 20px;

      .hidden-item-small {
        display: flex;

        label {
          width: 35px;
          padding-top: 8px;
        }
      }
    }

    .pljp-col-24 {
      padding-top: 16px;
    }

    .item-insured-person-number {
      margin-left: 20px;

      button {
        width: 100px;
        height: 40px;
      }
    }

    .item-check-for-input-errors {
      button {
        width: 88px;
        height: 40px;
        white-space: normal;

        span {
          height: 40px;
        }
      }
    }

    .text-bottom {
      margin-top: 8px;
    }

    .billing-next-month,
    .insurance-renewal,
    .ticket-quote {
      background-color: #548235 !important;
      color: white;
      transition: box-shadow 0.3s ease;

      &:hover {
        background-color: @color-button;
        color: white;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
      }
    }

    .pljp-btn .pljp-btn-icon {
      line-height: 0;
    }

    .lable-date-picker {
      display: flex;
      align-items: center;
    }

    .group-tanto {
      display: flex;
      align-items: center;
      gap: 20px;

      .tanto-select {
        width: 150px;
      }
    }
  }

  .riyousyaName {
    display: flex;
    justify-content: space-between;
  }

  .date-picker-subsidy::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 23%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #00838f;
  }

  .date-picker-subsidy {
    .pljp-picker-input>input {
      right: -23px;
    }

    .pljp-picker-suffix {
      order: -1;
      color: white;
      z-index: 1;
      position: absolute;
      left: -8px !important;
    }

    .pljp-picker-footer-extra {
      width: 100% !important;
    }

    .pljp-picker-suffix:hover {
      color: white !important;
    }
  }

  .list-button {
    display: flex;
    padding: 16px 0;
    align-items: center;

    button {
      height: 40px;
    }

    .item-operation-mode {
      padding-left: 24px;
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  }

  #custom-table-plan-subsidy,
  #custom-table-final,
  #custom-table-addition {
    border: 1px solid;
  }

  .attached-table .pljp-table-thead>tr>th:nth-child(n) {
    text-align: center !important;
  }

  #custom-table-plan-subsidy {
    .row-expanded {
      background-color: @color-select-row;
      height: 50px;
      vertical-align: text-top;
    }

    .pljp-table-thead {
      tr {
        th {
          border-right: 1px solid black;
          border-bottom: 1px solid black;
          padding: 8px 4px;
          height: 38px;
          font-weight: bold !important;
        }
      }
    }

    .pljp-table-tbody {
      .row-expanded>.pljp-table-cell {
        border-bottom: 1px solid black;
        border-top: 1px solid black;
      }

      .row-expanded>td:first-of-type {
        border-right: 1px solid black;
      }
    }

    .pljp-table-expanded-row:hover {
      background-color: @color-select-row;
    }

    .pljp-table-expanded-row {
      background-color: @color-select-row;
      left: 100px;
      margin-top: -50px;
    }

    .pljp-table-tbody {
      tr {
        td:not(:nth-of-type(12)) {
          text-overflow: initial;
        }
      }
    }

    .row-selected {

      tr,
      td {
        border: none !important;
      }

      .item-detail-office {
        border: none;
      }
    }

    .pljp-table-thead {
      tr {
        th {
          height: unset;
          padding: 8px 4px !important;
        }
      }
    }

    .pljp-table-tbody {
      tr {
        td:not(:nth-of-type(14)) {
          text-overflow: initial;
        }

        td.text-serviceSyuruiCd {
          text-overflow: ellipsis;
        }
      }
    }

    @media (max-width: 1050px) {
      .row-render-plan {
        width: 90%;
      }

      .row-render-actual {
        width: 84%;
      }
    }
  }

  .pljp-table-row {
    cursor: pointer;
  }

  .pljp-table-expanded-row td {
    border-bottom: none !important;
  }

  .d-flex-col {
    display: flex;
  }

  .custom-table {
    tr {
      th {
        padding: 5px 7px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        text-wrap: nowrap;
      }

      td {
        padding: 5px 10px;
        text-wrap: nowrap;

        &:last-of-type {
          text-wrap: initial;
        }
      }
    }

    .pljp-table-thead {
      tr th:last-of-type {
        border-top-right-radius: initial;
      }
    }

    .pljp-table {
      border-color: black !important;
    }

    .pljp-table-small.pljp-table-bordered .pljp-table-content {
      border-right: 1px solid black;
    }

    .pljp-table-thead,
    .pljp-table-tbody {
      tr {

        th,
        td {
          border-color: black !important;
        }

        td:last-child {
          border-right: 0.5px solid black !important;
        }

        th:last-child {
          border-right: 0.5px solid black !important;
        }
      }

      tr:last-child {
        td {
          border-bottom: none !important;
        }
      }
    }

    .pljp-table-placeholder {
      border-color: black !important;
      // border-right: 0.5px solid black !important;
    }
  }

  .maxw-960 {
    max-width: 768px;
    margin: 0 auto;

    @media screen and (max-width: 784px) {
      padding: 0 16px;
    }
  }

  @media (max-width: 1180px) {
    .toolbar-subsidy-1 {
      align-items: flex-start;
      gap: 4px !important;
    }

    .date-picker-subsidy {
      width: 120px;
    }

    .pljp-radio-wrapper {
      margin-inline-end: 0px !important;
    }

    .item-insured-person-number {
      margin-left: 0px !important;
    }

    .search-riyousya input {
      width: 114px !important;
    }

    .search-riyousya .pljp-btn-default {
      width: 35px;
    }

    .search-riyousya .pljp-btn-primary {
      width: 60px;
    }

    .sort-handle button {
      width: 60px;
    }

    .label-dot {
      width: 118px;
    }

    .group-tanto {
      gap: 4px !important;
    }

    .tanto-select {
      width: 112px !important;
    }

    .text-bottom button {
      width: 35px;
    }
  }
}

.anticon-close-circle {
  display: flex !important;
}

.text-dots-comment {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 600px;
}

.model-show-notify .pljp-modal-content .pljp-modal-body {
  display: flex;

  svg {
    width: 30px !important;
    height: 30px !important;
  }
}

.modal-choose-date {
  .datepicker-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pljp-modal-body {
    height: 0 !important;
    padding: 0 !important;
  }
}

.modal-list-name-error .pljp-modal-body {
  padding-top: 0px;
  padding-bottom: 0px;

  .list-name {
    padding-left: 20px;
  }
}