#riyousya-page .riyousya-list-content {
  max-width: 960px;
  margin: 0 auto; }
  #riyousya-page .riyousya-list-content .pljp-list-item-meta-content span {
    display: block;
    color: #555;
    line-height: 20px; }
    #riyousya-page .riyousya-list-content .pljp-list-item-meta-content span span {
      display: inline; }
    #riyousya-page .riyousya-list-content .pljp-list-item-meta-content span span:first-of-type {
      font-size: 14px; }
  #riyousya-page .riyousya-list-content .pljp-list-item-meta-content span:first-of-type {
    font-size: 10px; }
  #riyousya-page .riyousya-list-content .pljp-list-item-meta-content .user_kanji {
    font-size: 16px;
    font-weight: 600; }
  #riyousya-page .riyousya-list-content .ripa-item-content span {
    display: block;
    color: #555; }
  #riyousya-page .riyousya-list-content .pljp-list-item {
    padding-top: 2px;
    padding-bottom: 2px; }
  #riyousya-page .riyousya-list-content .pljp-avatar {
    min-width: 50px;
    min-height: 50px;
    margin-top: 8px; }
  #riyousya-page .riyousya-list-content .ripa-list-actions ul a li {
    float: left;
    background: #00838F;
    padding: 6px 0px;
    color: white;
    border-radius: 4px;
    min-width: 55px; }
    #riyousya-page .riyousya-list-content .ripa-list-actions ul a li span {
      display: block;
      text-align: center; }
  #riyousya-page .riyousya-list-content .ripa-list-actions ul a li {
    margin-left: 4px; }
  #riyousya-page .riyousya-list-content .ripa-list-actions ul a:first-of-type li {
    margin-left: 0px; }
  #riyousya-page .riyousya-list-content .ripa-list-actions ul li:hover {
    background: #02acbb; }
  #riyousya-page .riyousya-list-content .f-by-keyboard {
    float: right; }
    #riyousya-page .riyousya-list-content .f-by-keyboard input {
      max-width: 150px; }
    #riyousya-page .riyousya-list-content .f-by-keyboard span {
      margin-right: 8px; }
    #riyousya-page .riyousya-list-content .f-by-keyboard span:last-of-type {
      margin-right: 0px; }
  #riyousya-page .riyousya-list-content .ripa-search {
    margin: 4px 0px 8px 0px; }

#riyousya-page .riyousya-loadmore {
  margin-top: 16px;
  margin-bottom: 8px;
  text-align: center; }

.pljp-drawer-body iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important; }

#riyousya-update-page .riyousya-update-content {
  max-width: 960px;
  margin: 0 auto; }
  #riyousya-update-page .riyousya-update-content .rdc-avatar .pljp-avatar {
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 200px; }
  #riyousya-update-page .riyousya-update-content .rdc-avatar .rdc-avatar-img {
    padding: 16px; }
  #riyousya-update-page .riyousya-update-content .rdc-avatar .rdc-avatar-info span {
    display: block; }
  #riyousya-update-page .riyousya-update-content .rdc-avatar .rdc-avatar-info .pljp-row {
    margin-top: 16px; }
  #riyousya-update-page .riyousya-update-content .rdc-avatar .rdc-avatar-info {
    padding: 0 16px; }
    #riyousya-update-page .riyousya-update-content .rdc-avatar .rdc-avatar-info button:first-of-type {
      margin-right: 16px; }
  #riyousya-update-page .riyousya-update-content .pljp-collapse-content {
    background-color: #ffffff;
    padding: 16px 0px; }
    #riyousya-update-page .riyousya-update-content .pljp-collapse-content .pljp-divider-horizontal {
      margin: 0px; }
  #riyousya-update-page .riyousya-update-content .rdc-list .expand-btn {
    margin: 16px 0;
    text-align: right; }
  #riyousya-update-page .riyousya-update-content .rdp-tab-kankei span {
    display: block; }
  #riyousya-update-page .riyousya-update-content .rdp-tab-title,
  #riyousya-update-page .riyousya-update-content .rdp-basic-tab-title {
    font-weight: 600;
    color: #000000; }
  #riyousya-update-page .riyousya-update-content .rdp-tab-kankei-urgent {
    background-color: #00838F;
    padding: 4px 0px;
    color: #ffffff;
    text-align: center;
    max-width: 80px;
    border-radius: 4px; }
  #riyousya-update-page .riyousya-update-content .pljp-form-item {
    margin-bottom: 0px; }
  #riyousya-update-page .riyousya-update-content .pljp-collapse-header {
    font-size: 18px; }
  #riyousya-update-page .riyousya-update-content .rdu-tab-title {
    padding-right: 5px; }
    #riyousya-update-page .riyousya-update-content .rdu-tab-title span {
      color: rgba(0, 0, 0, 0.85); }
  #riyousya-update-page .riyousya-update-content .rdu-tab {
    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center; }
    #riyousya-update-page .riyousya-update-content .rdu-tab-2 {
      margin: 5px 0; }

#riyousya-shokuji-page .riyousya-shokuji-content {
  max-width: 960px;
  margin: 0 auto; }

#riyousya-shokuji-page .pljp-collapse-content {
  background-color: #ffffff; }

#riyousya-shokuji-page .riyou-meal-page .pljp-table-row {
  background-color: #d6f1ee;
  padding: 16px 16px 32px; }
  #riyousya-shokuji-page .riyou-meal-page .pljp-table-row td {
    border: 1px solid gray;
    padding: 0; }
    #riyousya-shokuji-page .riyou-meal-page .pljp-table-row td .riyou-meal-page-click-area {
      padding: 16px 16px 32px; }
      #riyousya-shokuji-page .riyou-meal-page .pljp-table-row td .riyou-meal-page-click-area .pljp-col span:last-child {
        margin-left: 16px; }

#riyousya-shokuji-page .pljp-collapse-content > .pljp-collapse-content-box {
  padding: 4px 0px; }

#riyousya-shokuji-page .pljp-collapse-header {
  border: 1px solid gray; }

.syokujisen-input-form .pljp-modal-body {
  padding: 16px 24px 0px 24px; }

.search-modal-kikan:hover {
  cursor: pointer; }

.search-modal-kikan-none:hover {
  cursor: not-allowed; }

#riyousya-hoken-page .pljp-divider-horizontal {
  margin: 2px 0 !important; }

#riyousya-hoken-page .riyousya-shokuji-content {
  max-width: 1250px;
  margin: 0 auto; }
  @media screen and (min-device-width: 768px) {
    #riyousya-hoken-page .riyousya-shokuji-content {
      max-width: 960px; } }

#riyousya-hoken-page .form-title {
  line-height: 50px !important; }

#riyousya-hoken-page .form-title2 {
  line-height: 50px !important; }

#riyousya-hoken-page .pljp-collapse-content {
  background-color: #ffffff; }

#riyousya-hoken-page .riyou-hoken-table .pljp-table-row {
  background-color: #d6f1ee;
  padding: 16px 16px 32px; }
  #riyousya-hoken-page .riyou-hoken-table .pljp-table-row td {
    border: 1px solid gray;
    padding: 0; }
    #riyousya-hoken-page .riyou-hoken-table .pljp-table-row td .riyou-hoken-table-row {
      padding: 5px; }

#riyousya-hoken-page .pljp-collapse-content > .pljp-collapse-content-box {
  padding: 4px 0px; }

#riyousya-hoken-page .pljp-collapse-header {
  border: 1px solid gray; }

.hoken1-input-form .pljp-form-item {
  display: flex; }

.hoken1-input-form .pljp-modal-body {
  background-color: #fffde7; }

.hoken1-input-form .title {
  font-weight: bold; }
  .hoken1-input-form .title-red {
    font-weight: bold;
    color: red; }

.hoken1-input-form .calender .pljp-fullcalendar-column-header .pljp-fullcalendar-column-header-inner {
  color: red; }

.calender .header {
  padding: 10px;
  border-bottom: 1px gray solid; }

.calender .pljp-fullcalendar-calendar-body {
  padding: 8px 0 8px 0 !important; }

.calender .pljp-fullcalendar-column-header .pljp-fullcalendar-column-header-inner {
  font-weight: 900;
  border-bottom: 2px gray solid;
  padding-bottom: 4px; }

.input-number .pljp-input-number-input {
  text-align: right !important; }

.input-number .pljp-input-number-handler-wrap {
  display: none; }

.hoken1-input-form .pljp-form-item-control-wrapper {
  flex: 1; }

.hoken-dropdown .rc-virtual-list-holder {
  max-height: unset !important; }

.loading-new {
  position: fixed;
  bottom: 10%;
  left: 50%; }

.loading-bar {
  display: inline-block;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  animation: loading 1s ease-in-out infinite; }

.loading-bar:nth-child(1) {
  background-color: #3498db;
  animation-delay: 0; }

.loading-bar:nth-child(2) {
  background-color: #c0392b;
  animation-delay: 0.09s; }

.loading-bar:nth-child(3) {
  background-color: #f1c40f;
  animation-delay: 0.18s; }

.loading-bar:nth-child(4) {
  background-color: #27ae60;
  animation-delay: 0.27s; }

@keyframes loading {
  0% {
    transform: scale(1); }
  20% {
    transform: scale(1, 2.2); }
  40% {
    transform: scale(1); } }
