@import "variables.less";
@import "components";
@import "pages";
* {
  margin: 0;
  padding: 0;
  p {
    margin: 0;
  }
}

@primary-color: #00838F;@font-family: KozGo !important;@ant-prefix: pljp;