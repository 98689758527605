
.Issue-Summary {
    .Issue-Summary__button {
      border-radius: 20%;
      width: 40px;
      height: 28px !important;
      background-color: @blue;
      border: none;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s all;
      margin-top: 20px !important;
      margin-left: 10px;
      margin-right: 20px;
    }
    .Issue-Summary__button:hover {
      background-color: #09a2b0;
      color: #ffffff;
    }  
    .outlook-content {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      vertical-align: top;
    }
    .pljp-table-tbody > tr:hover {
      background-color: #ffffff !important; 
    }
    .pljp-table-tbody > tr--selected {
      background-color: #ffffff; 
    }

    .pljp-table-thead > tr.pljp-table-row-hover:not(.pljp-table-expanded-row):not(.pljp-table-row-selected) > td, .pljp-table-tbody > tr.pljp-table-row-hover:not(.pljp-table-expanded-row):not(.pljp-table-row-selected) > td, .pljp-table-thead > tr:hover:not(.pljp-table-expanded-row):not(.pljp-table-row-selected) > td, .pljp-table-tbody > tr:hover:not(.pljp-table-expanded-row):not(.pljp-table-row-selected) > td {
      background-color: rgba(0, 0, 0, 0) ; 
    }
    .btn-remarks, .btn-factor, .btn-outlook {
        color: rgba(0, 0, 0, 0.65)!important; 
        background-color: #ffffff !important; 
        cursor: inherit !important; 
        opacity: 1;
    }
    .pljp-select-disabled .pljp-select-selection {
      color: rgba(0, 0, 0, 0.65)!important; 
      background-color: #ffffff !important; 
      cursor: inherit !important; 
    }
    .btn-addcolumn {
      background-color: rgb(10, 104, 180);
      border-color: rgb(10, 104, 180);
      color: white;
    }
    .btn-addcolumn:hover, .btn-addcolumn:focus {
      background-color: rgb(6, 92, 163);
      border-color: rgb(6, 92, 163);
      color: white;
    }
      .column-1-2{
        border-bottom: 1px solid #ccc
      }
      .column-5{
        border-right: 1px solid #ccc !important
      }

      .EnterAssessment{
         .pljp-radio-checked .pljp-radio-inner{
          background: none;
        }
      }
  }

  .back-docs {
    background-color: #e46c0a !important;
    color: white !important;
    border: none !important;
    transition: 0.3s all !important;
    margin-left: 10px;
  }
  .back-docs:hover {
    background-color: #ec8f43 !important;
    color: white !important;
  }
  
  // Browsing screen
  #last-quote-table td {
    text-align: center !important;
  }
  #browsing-screen-table input {
    border:none
  }
  #browsing-screen-table textarea {
    border:none;
    background-color: transparent;
    resize: none;
  }

  #browsing-screen-table textarea:focus, #browsing-screen-table input:focus{
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}
.radio-view{
    white-space: normal !important;
}
.text-fixer{
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 30px !important;
}
.hide-border-input{
    border: none !important;
}
.mitoshi-view{
  height: 70px
}
.naiyou-task{
  top: 37% !important
}
#header-info{
  display: flex;
  margin-bottom: 5px; 
}
#mitoshi-area{
  position: absolute;
  top: 0px;
  width: 18%;
}
.ikou-view{
  white-space: pre-wrap;
}
#browsing-screen-table {
  td.youin-view,
  td.radio-view,
  td.comment-view,
  td.kadai-view,
  td.sortNum-view{
    vertical-align: top;
  };
  td.sortNum-view{
    padding: 8px 0px !important;
  }
  .pljp-table-tbody > tr > td {
    border: 1px solid #dddddd
  }
  .pljp-table-thead >tr > th {
    border: 1px solid #dddddd
  }
}
.cal-input .pljp-calendar-picker{
  width: 120px;
}
.task-kadai-button{
  display: flex;
  margin-top: 20px;
}
.tanto-kadai-seiri{
  display: flex;
  margin-top: 20px;
}
@media screen and (max-width: 1800px) {
  .tanto-kadai-seiri .tanto-name {
    width: 140px !important;
  }
}