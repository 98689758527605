@btn-color: #2e75b6;
@color-white: #ffffff;
@color-black: rgba(0, 0, 0, 0.65);
@color-green: #548235;
@color-orange: #e46c0a;
@color-light-blue: #c5e0b4;
@color-red: #c00000;
@color-gray: #808080;
@color-pink: #fcd5b5;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.group-button-useslip {
  .button-useslip {
    width: 90px;
    margin: 5px;
  }
  .group-button-1,
  .group-button-2,
  .group-button-3,
  .group-button-4 {
    // border: 1px;
    // border-color: red;
    border: solid 1px red;
    margin: 3px;
    padding: 3px;
  }
  .group-button-useslip {
    display: flex;
    justify-content: center;
  }
  .group-button-1,
  .group-button-3 {
    width: 210px;
  }
  .group-button-2,
  .group-button-4 {
    width: 6%;
  }
  .item-1,
  .item-2 {
    display: inline-grid;
  }
}

#attached-table-id {
  .custom-table {
    .pljp-table {
      border: 1px solid black;
    }

    .pljp-table-header,
    .pljp-table-tbody,
    .pljp-table-thead {
      tr {
        th{
          font-weight: 500;
          border-color: black;
          &:last-child{
            border-right: none;
          }
          }
        td {
          border-color: black;
          &:last-child{
            border-right: none;
          }
        }
        &:last-child{
          td{
            border-bottom: none;
          }
        }

      }
    }

    .pljp-table-content {
      border-right: none;
    }

    .pljp-table-placeholder {
      //border-color: black;
    }
  }
}

.use-slip {
  @media screen and (max-width: 1180px) {
    .right_header{
      width: 100%;
      margin-top: 5px;
    }
  }
  
  .header_useslip{
    justify-content: space-between;
    @media screen and (max-width: 1180px) {
        justify-content: flex-start !important;
        & > *:not(:last-child) {
          margin-right: 30px;
      }
    }
  }
  .sm-c-right {
    display: flex;
    @media screen and (max-width: 1180px) {
      float: right;
    }
  }

  .submenu {
    padding: 8px 20px 8px 10px;
    .item {
      color: white;
    }
  }

  .submenu .header:after,
  .submenu .header:before {
    content: none;
  }
  .pljp-col {
    display: inline-block;
  }
}

.item-hoken {
  margin-left: 15px;
}
.date-picker-useslip {
  width: 120px;
  .pljp-input-disabled {
    color: @color-black !important;
    background-color: white !important;
  }
}
.text-align-center {
  text-align: center;
}

.naiyou-modal-useslip {
  margin-top: 110px !important;
}

.time-table-row-select {
  tr:hover,
  thead:hover {
    background: white !important;
  }
}

.pljp-picker-footer{
  display: flex !important;
  justify-content: space-between !important;
}
.table-date-weekly {
  thead[class*="pljp-table-thead"] th {
    background-color: #dce6f1 !important;
    cursor: pointer;
    border: 1.2px solid rgb(190, 190, 190);
  }
}

.table-date-useslip {
  height: 75vh;
  padding-bottom: initial !important;
  .pljp-modal-content {
    height: 100%;
    .pljp-modal-body {
      padding: 16px 24px 10px 24px;
      height: calc(100% - 53px);
      .table-date-useslip-content {
        height: 100%;
        .useslip_quote_weekly {
          .color-master-useslip {
            height: 355px;
          }
        }
      }
    }
    
    .pljp-modal-footer {
      padding: 10px 24px;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 53px;
      border-top: none;
    }
  }
}

.table-date-record-lengther {
  .useslip_quote_weekly {
    height: calc(100% - 318px) !important; 
  }
}

.text-dots {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

#useslip-table {
  th,
  td {
    border: 0.5px solid black;
  }
  .pljp-table{
    width: 100%;
  }
}

.actual-row {
  background-color: "#eeeeee";
  text-align: "center";
}

.plan-row {
  background-color: "#eeeddddddee";
  text-align: "center";
}

.header-table-pick-date {
  border: 1px solid gray;
}

.table-main-useslip {
  tr:hover {
    background-color: unset !important;
    color: unset !important;
  }
}
.add-service-code-modal {
  .pljp-table-small > .pljp-table-content > .pljp-table-scroll > .pljp-table-body > table > .pljp-table-tbody > tr > td {
    padding: 4px !important;
  }
}

.modalCheck {
  padding: 5px 5px;
}

.modalCheck .pljp-modal-content {
  width: 220px;
  padding: 0 !important;
}

.modalCheck .pljp-modal-body {
  padding: 10px 10px;
}

.modalCheck .groupBtnCheck {
  display: inline;
}

.modalCheck .closeBtn {
  display: inline;
  margin-left: 9px;
}

.modalCheck .btnCheck,
.modalCheck .btnCheck:hover {
  background-color: #a3a7a8;
  color: white;
  display: inline;
  margin-right: 3px;
}

.modalCheck .btnCheckBlank {
  background-color: #00aede;
  text-align: center;
}

.modalCheck .btnClose {
  background-color: #ffffff;
  color: #616161;
  border: none;
  box-shadow: none;
  padding: 0 2px 0 2px;
}

#useslip-table .pljp-table-small > .pljp-table-content > .pljp-table-body > table > .pljp-table-tbody > tr > td,
#useslip-table .pljp-table-small > .pljp-table-content > .pljp-table-body > table > .pljp-table-thead > tr > th {
  padding: 2px;
}

#useslip-table ::-webkit-scrollbar {
  width: 0px;
  background: white;
  color: white; /* make scrollbar transparent */
}
.use-slip {
  .header-attachedTable {
    justify-content: flex-start;
  }
}

.use-slip {
  .toolbarTable {
    transform: translateY(-20px);
    .useSlipDetail {
      zoom: 1.1;
    }
  }

  #header-useslip {
    color: rgba(0, 0, 0, 0.65);
    &:before {
      display: none;
    }
    .hidden-item-small {
      font-size: 11px;
    }
  }

  .header-attachedTable {
    .item-hoken {
      margin-left: 20px;
    }
  }

  .pljp-row {
    &:before,
    &:after {
      display: none;
    }
  }
}

#header-useslip-table {
  position: sticky;
  top: 0px;
  z-index: 1;
}

.pljp-table-body {
  overflow-x: hidden;
}
.header-table1 {
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: white !important;
  box-shadow: inset 0 1px 0 black, inset 0 -1px 0 black;
  border-top: none !important;
  border-bottom: none !important;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.65) !important;
  font-weight: 500;
  padding: 4px !important;
}
.header-table2 {
  position: sticky;
  top: 25px;
  z-index: 1;
  background-color: white !important;
  box-shadow: inset 0 -1px 0 black;
  border-top: none !important;
  border-bottom: none !important;
  font-weight: 500;
  padding: 4px !important;

}

.btnFilterGroup {
  width: 126px;
  padding: 5px 0px 5px 5px;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
  margin: 0 5px 5px 0px;
  display: inline-block;
}

.btnGroup {
  width: 205px;
  padding: 2px 0px 5px 5px;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 5px;
}

.useSlipDetail .pljp-table-tbody {
  font-size: 10px;
}

.btnFilterGroup .btnfilterOffice {
  font-size: 11px;
  height: 24px;
  padding: 0 7px;
}

.btnFilterGroup .btnfilterCancel {
  margin-left: 5px;
  font-size: 11px;
  height: 24px;
  padding: 0 7px;
}

.break-word-office {
  .pljp-table-row-cell-break-word {
    word-break: normal;
  }
}

.tenKeyBoard {
  height: 300px;
}

.groupKeyNumber .keyNumber {
  width: 50px;
  height: 40px;
}

.groupKeyNumber .inputNumber {
  min-height: 30px;
  max-height: 30px;
  // text-align: center;
}

.groupBtnAction {
  display: inline-block;
  // width: 30%;
}

.groupBtnAction .btnOrange {
  display: block;
}

.groupBtnAction .btnGreen {
  display: block;
}

.groupBtnAction .btnOrg {
  display: inline;
  margin-bottom: 3px;
  background-color: chocolate;
  border-color: chocolate;
  color: white;
  padding: 0 10px 0 10px;
  width: 80px;
}

.groupBtnAction .btnOrg:hover,
.groupBtnAction .btnOrg:focus,
.groupBtnAction .btnOrg:active,
.groupBtnAction .btnOrg.active {
  text-decoration: none;
  background-color: chocolate;
  color: white;
}

.groupBtnAction .btnOrange1 {
  margin-left: 10px;
}

.groupBtnAction .btnOrange2 {
  margin-left: 3px;
}

.groupBtnAction .btnGrn {
  display: inline;
  margin-bottom: 3px;
  background-color: #548235;
  border-color: #548235;
  color: white;
  padding: 0 10px 0 10px;
  width: 88px; //60
}

.groupBtnAction .btnGrn:hover,
.groupBtnAction .btnGrn:focus,
.groupBtnAction .btnGrn:active,
.groupBtnAction .btnGrn.active {
  text-decoration: none;
  background-color: #548235;
  color: white;
}

.groupBtnAction .btnGreen1 {
  margin-left: 10px;
}

.groupBtnAction .btnGreen2 {
  margin-left: 3px;
}

.groupBtnAction .btnCalendar {
  display: block;
  width: 163px;
  background-color: pink;
  border-color: pink;
  color: white;
  margin: 0 20px 0 10px;
}

.groupBtnAction .btnCalendar:hover {
  background-color: #ffc0cb;
}

div.attached-table .pljp-table-body {
  overflow-x: auto !important;
}

.attached-table-subtotal {
  background-color: #afe3f1;
}

.attached-table .pljp-table-thead > tr > th {
  height: 62px;
}

.attached-table .pljp-table-thead > tr > th:nth-child(n) {
  text-align: left !important;
  padding: 4px !important;
}

.attached-table-grey {
  background-color: #7e8283;
  color: #ffffff;
  opacity: 80;
}

.attached-table-grey-is30dayover {
  background-color: #a4aaaa;
  color: #ffffff;
  opacity: 80;
}

.pljp-calendar-footer-btn {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

.attached-table-subtotal-is30dayover {
  background-color: #7e8283;
  color: #ffffff;
  opacity: 80;
}

.pljp-calendar-footer-btn .pljp-calendar-footer-extra {
  cursor: pointer;
  color: #00838f;
}

.boxItem-Modal {
  flex: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boxItem {
  display: inline-block;

  .boxItem-detailUseSlip {
    display: flex;
    border: 1px solid rgb(221, 221, 221);
    padding: 5px;
    border-radius: 5px;
    width: 60%;
    // margin-right: 20px;
    // .groupBtnAction {
    //   flex-wrap: wrap;
    // }
    .btn-red {
      background-color: #c00000;
      color: @color-white;
      height: 67%;
      span {
        font-size: 20px;
      }
    }
    .btn-red:hover{
      background-color: #c00000;
      color: @color-white;
    }
  }

  .boxItem-tableSort {
    background-color: #7accff;
    margin-top: 8px;
    .pljp-table-small {
      border-radius: unset;
    }
    .pljp-table-row > td:first-of-type {
      border-bottom: unset;
    }
  }
}

.modal-dialog {
  font-size: 18px;
  color: black !important;

  .pljp-modal-close {
    right: 24px !important;
    width: auto !important;
  }

  .pljp-table-body {
    th {
      font-weight: 600;
    }
  }
  .pljp-modal-content{
    background-color: antiquewhite !important;
  }

  .pljp-modal-body  {
    background-color: antiquewhite;
  }

  .pljp-table-tbody {
    text-align: center;

    td {
      color: black;
    }
  }

  .pljp-pagination {
    display: none;
  }

  .pljp-table-wrapper {
    border: 1px solid #c5c5c7;
    background-color: white;
    margin: 10px 0;
  }

  .pljp-modal-footer {
    display: none;
  }

  .modal-dialog-close {
    cursor: pointer;
    padding: 5px 2px;
    color: black;
    border: 1px solid rgb(221, 221, 221);
    border-radius: 5px;
  }

  .modal-dialog-header {
    span {
      font-size: 17px;
    }
    input {
      padding: 4px;
      text-align: end;
      margin-left: 10px;
    }
  }

  .modal-dialog-footer {
    display: flex;
    justify-content: flex-end;

    button {
      background: #e0c490;
      padding: 2px;
      border-color: wheat;
    }

    input,
    span {
      padding: 4px;
      text-align: end;
      margin-left: 3px;
      font-size: 28px;
      font-weight: 800;
      width: 110px;
      border: 1px solid black;

      &:first-of-type {
        background: #d4d4d4;
      }

      &:last-of-type {
        background: #ffae68;
      }
    }
  }
}

.modal-batch-create {
  font-weight: 500 !important;
  .pljp-table-thead > tr > th {
    text-align: left;
  }

  .pljp-table {
    border-color: black;
  }

  .pljp-table-bordered .pljp-table-content {
    border-right: 1px solid black !important;
  }
  .pljp-table-thead,
  .pljp-table-tbody {
    tr {
      th,
      td {
        border-color: black !important;
        border-right: 1px solid black !important;
      }
      td:last-child {
        border-right: none !important;
      }
      th:last-child {
        border-right: none !important;
      }
    }
  }

  .pljp-table-tbody {
    tr:last-child {
      td {
        border-bottom: none !important;
      }
    }
  }

  .pljp-table-placeholder {
    border-color: black !important;
  }
}

.modal-dialog-table-sort {
  .pljp-modal-body {
    background-color: @color-white;
  }
}

.sonota-group {
  .sonota-groupModal {
    display: flex;
    gap: 10px;
    padding: 30px 10px 10px;
    .groupModal-btnAction {
      margin: auto 0;
      .hidden-item-small {
        color: black;
      }
      .btnAction-addEdit {
        .btn-add {
          margin-left: 10px;
          width: 88px;
        }

        .btn-edit {
          margin-left: 10px;
        }
      }

      .btnAction-duplicateRemove {
        margin-top: 5px;
        .btn-duplicate {
          margin-left: 10px;
        }
        .btn-remove {
          margin: 0 10px;
        }
      }
    }
    .groupModal-option {
      .btn-calendar {
        i {
          font-size: 21.6px;
          cursor: pointer;
        }
      }
      .title-calendar {
        font-size: 11px;
        display: block;
      }

      .btnCalendar {
        padding: 0px 30px;
        background-color: #ffa9b8;
        color: #ffffff;
      }

      .btnDisabledCalendar {
        color: rgba(0, 0, 0, 0.25);
        background-color: #f5f5f5;
        border-color: #d9d9d9;
      }
    }
  }

  .content-useslip {
    border: 3px solid rgb(39, 174, 96);
  }
}

.sonota-modal {
  .pljp-modal-content {
    .pljp-modal-header {
      padding: 10px;
      background-color: #cbcbcb;
    }

    .pljp-modal-content {
      border-top: none;
    }
  }
}

// Start HeaderUseSlip
.pointer {
  cursor: pointer;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.border-none {
  border: none !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.gap-2 {
  gap: 2px;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-6 {
  gap: 6px;
}

.gap-7 {
  gap: 7px;
}

.gap-50 {
  gap: 50px;
}

.gap-y-x {
  gap: 5px 10px;
}

.gap-11 {
  gap: 11px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.align-initial {
  align-items: initial !important;
}

.align-end {
  align-items: flex-end !important;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-start {
  justify-content: flex-start;
}

.flex-align-start {
  align-items: start;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-column {
  flex-direction: column;
}

.text-white {
  color: @color-white !important;
}

.text-black {
  color: @color-black;
}

.text-initial {
  text-align: initial !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.mt-3 {
  margin-top: 3px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mr-10 {
  margin-right: 10px !important;
}

.ml-10 {
  margin-left: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-72 {
  margin-left: 72px;
}

.pl-10 {
  padding-left: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pr-35 {
  padding-right: 35px;
}

.px-10 {
  padding: 0 10px;
}

.p-10 {
  padding: 10px;
}

.px-16 {
  padding: 0 16px;
}

.size-btn {
  width: 88px;
}

.my-4 {
  margin: 4px 0;
}

.my-10 {
  margin: 10px 0;
}

.mt-25  {
  margin-top: 20px;
}

.w-130  {
  width: 130px
}

.w-160 {
  width: 160px;
}

.d-flex-o {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.line-h-15 {
  line-height: 15px;
}

.line-h-23 {
  line-height: 23px;
}

.line-h-32 {
  line-height: 32px;
}

.border-top-half {
  border-top: 0.5px !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.border-top-1 {
  border-top: 1px solid black !important;
}

.border-bottom-1 {
  border-bottom: 1px solid black !important;
}

.border-top-none {
  border-top: none !important;
}

.border-right-none {
  border-right: none !important;
}

.border-left-none {
  border-left: none !important;
}

.d-inline-block {
  display: inline-block;
}

.text-calculator {
  font-size: 15px !important;
  padding: 2px 3px;
}

.ml-18 {
  margin-left: 18px;
}

.ml-60 {
  margin-left: 60px;
}

.useSlip-btn {
  background-color: @btn-color !important;
  color: @color-white !important;
  border-color: @btn-color !important;
}

.useSlip-btn-add {
  background-color: @color-red !important;
}

.useSlip-btn-remove {
  background-color: @color-gray !important;
}

.bg-green {
  background-color: @color-green !important;
}

.bg-orange {
  background-color: @color-orange !important;
}

.bg-light-blue {
  background-color: @color-light-blue !important;
}

.bg-pink {
  background-color: @color-pink !important;
}

.bg-color-default {
  background-color: #00838f !important;
  color: white !important;
}

.disabled-element {
  color: #ccc;
  pointer-events: none;
  opacity: 0.6;
}

.color-bold {
  font-weight: bold !important;
  color: black !important;
  font-size: 15px;
}

.fw-600 {
  font-weight: 600;
}

.border-1 {
  border: 1px solid black;
}

.border-2 {
  border: 2px solid black;
}

.w-10 {
  width: 10%;
}

.w-13 {
  width: 13%;
}

.w-17 {
  width: 17%;
}

.w-85 {
  width: 85%;
}

.w-590 {
  width: 590px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-42 {
  padding-right: 42px !important;
}

.border-not-right {
  border: 2px solid black !important;
  border-right: none !important;
}

.border-2 {
  border: 2px solid black !important;
}

.text-value-days {
  width: 0.7% !important;
}

.btn-primary {
  color: @color-white!important;
  background-color: #00838f !important;
  border-color: #00838f;
}


.group-list-office-2 .pljp-table-tbody > tr > td,
.toolbarTable .useSlipDetail .pljp-table-bordered .pljp-table-tbody > tr > td {
  font-size: 11px;
}

.toolbarTable .useSlipDetail .pljp-table-bordered .pljp-table-tbody > tr > td {
  font-size: 11px;
  padding: 5.25px 8px 5.25px 50px !important;
}

.group-list-office-2 .pljp-table-tbody > tr > td:nth-of-type(2),
.group-list-office-2 .pljp-table-tbody > tr > td:nth-of-type(4),
.toolbarTable .useSlipDetail .pljp-table-bordered .pljp-table-tbody > tr > td:nth-of-type(2),
.toolbarTable .useSlipDetail .pljp-table-bordered .pljp-table-tbody > tr > td:nth-of-type(4) {
  color: black;
  font-weight: bold;
  font-size: 15px;
  text-align: right;
}

.toolbarTable .useSlipDetail .pljp-table-bordered .pljp-table-tbody > tr > td:nth-of-type(2),
.toolbarTable .useSlipDetail .pljp-table-bordered .pljp-table-tbody > tr > td:nth-of-type(4) {
  padding-right: 5px;
  padding-left: 60px;
}

.group-list-office-2 .pljp-table-tbody > tr > td:first-child,
.group-list-office-2 .pljp-table-tbody > tr > td:nth-of-type(3),
.toolbarTable .useSlipDetail .pljp-table-bordered .pljp-table-tbody > tr > td:first-child,
.toolbarTable .useSlipDetail .pljp-table-bordered .pljp-table-tbody > tr > td:nth-of-type(3) {
  border-right: unset !important;
  font-size: 11px;
}

.toolbarTable .useSlipDetail .pljp-table-bordered .pljp-table-tbody tr:nth-of-type(3) {
  td {
    &:nth-of-type(3) {
      border: 2px solid black;
    }

    &:nth-of-type(4) {
      border: 2px solid black;
      border-left: none;
      padding-right: 4px;
    }
  }
}

button.white-button {
  span {
    color: @color-white;
  }
}

button.black-button {
  span {
    color: black !important;
    font-weight: 600 !important;
  }
}

.header-title-name {
  font-size: 1.43em;
  font-weight: bold;
}

#input-use-slip {
  .header-useSlip {
    .pljp-btn {
      &:hover,
      &:focus {
        color: @color-black;
        border: 1px solid black;
      }
    }
  }

  .list-office-useSlip {
    color: rgba(0, 0, 0, 0.65) !important;
    .groupBtnAction {
      button {
        padding: 1px 10px 0 10px;
      }
      .pljp-btn {
        &:hover,
        &:focus {
          border: none;
        }
      }
    }
  }

  .table-list#table-control {
    display: flex;
    gap: 3px;
    padding-bottom: 1px;
    .inputNameSelected {
      background-color: rgba(59, 59, 59, 0.05);
      border: 1px solid #dddd;
      color: rgba(0, 0, 0, 0.85) !important;
      width: 310px;
      padding: 2px;
      font-weight: bold;
      line-height: 23px;
    }
  }
}

.sonota-group {
  .pljp-btn.btn-remove {
    &:hover,
    &:focus {
      color: @color-black;
      border: none;
    }
  }
}

.modal-dialog-create {
  .pljp-modal-title {
    font-weight: 500 !important;
  }
  .pljp-modal-body {
    background-color: white;
    border-radius: 4px;
    button {
      font-size: 14px;
      height: fit-content;
      background-color: white;
      font-weight: 500 !important;
      span {
        color: @color-black;
        font-weight: 500 !important;
      }
    }
  }
}

.useSlip-date {
  letter-spacing: 4px;
  font-size: 16px;

  border-color: #00838f !important;
  padding: 1px 2px 0px 6px;
  border: 3px solid;
  line-height: 24px;
}

.useSlip-btn-list {
  button {
    span {
      font-weight: 700;
    }
  }
}

.nav-header-useslip {
  .btn-nav {
    padding: 2px 6px;
    border-radius: 4px;
    margin-left: 10px !important;
    &:hover {
      background-color: #e0e0e0;
    }
  }
}

.header-submenu {
  padding: 13px 20px 13px 10px;
  text-align: center;
  background-color: #212121d9;
  color: @color-white;

  .pljp-btn:hover,
  .pljp-btn:focus {
    color: @color-black !important;
  }
}

.header-useSlip {
  .useSlip-btn-header {
    button {
      span {
        color: black;
        font-weight: 800;
      }
    }
  }

  .item-header {
    p {
      transform: translateY(-1px);
    }
  }
}

// End HeaderUseSlip

// Start Toolbar
.toolbar-useSlip,
.header-attachedTable {
  margin: 10px 0;
  padding: 0 10px;
  .date-picker-useslip {
    .pljp-picker-suffix {
      order: -1;
      color:white;
      z-index: 1;
      position: absolute;
      left: -8px;
    } 
    input{
      left: 20px;
    }
  }
  .date-picker-useslip:hover{
    color:white !important;
  }
  .date-picker-useslip::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 23%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #00838f;
  }

  .item-hoken {
    div {
      &:first-of-type {
        font-size: 11px;
      }
    }
    .pljp-btn {
      padding: 0 11px;
      height: fit-content;
    }
  }
  .item-hoken > div:nth-of-type(2) {
    font-size: 14px;
  }
  .pljp-picker-input{
    color: rgba(0, 0, 0, 0.65);
  }

  .toolbar-useSlip-1 {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .tanto-useslip {
    .messageIcon {
      cursor: pointer;
      position: relative;
      display: inline-block;
      width: 37%;
      transform: translate(-10px, 0px);
      .icon-pen {
        position: absolute;
        top: 45%;
        left: 43%;
        transform: translate(-44%, -55%);
        height: 62%;
      }
    }
    .comment-icon {
      margin-top: 14px;
    }
    .group-tanto {
      transform: translateY(-5px);
      .hidden-item-small {
        font-size: 13px;
        margin-right: 8px;
        display: block;
      }
      .tanto-name {
        width: 130px;
      }
    }
    // .item-hoken {
    //   button {
    //     span {
    //       color: @color-white;
    //     }
    //   }
    // }
  }
}

// .header-attachedTable {
//   margin: 2px 0;
// }

.toolbarTable {
  .toobarTable-right {
    margin-right: 50px;
  }
}

// End Toolbar

// Start list-office-useSlip
.list-office-useSlip {
  .list-office-1 {
    .hidden-item-small {
      color: black;
    }
    margin-right: 10px;
    flex: 2;
    justify-content: initial;
    .group-btn-action {
      // flex: 1;
      .list-btn-group {
        .btn-list-office {
          width: 88px;
        }
        justify-content: space-between;
        button {
          width: 88px;
        }
      }
    }

    .btn-calendar {
      .title-calendar {
        display: block;
      }
      transform: translateY(-17%);
      span {
        font-size: 11px;
      }
      i {
        font-size: 21.6px;
        cursor: pointer;
      }
    }

    .btnOrange > button {
      padding: 2px 10px 0 10px;
    }
  }
  .list-office-2 {
    flex: 2;
    align-items: initial;
    justify-content: flex-end;
    .group-list-office-2 {
      flex: 2;
      .pljp-table {
        line-height: 1;
        .pljp-table-tbody {
          tr {
            &:last-of-type {
              td {
                &:nth-of-type(3) {
                  border: 2px solid black;
                }

                &:nth-of-type(4) {
                  border: 2px solid black;
                  border-left: none;
                  padding-right: 6px;
                }
              }
            }
          }
        }
      }
    }

    .btnOrange {
      button {
        &:first-of-type {
          padding: 6px 15px;
        }

        &:last-of-type {
          padding: 5px 15px;
        }
      }
    }
  }

  .list-office-3 {
    flex: 0.5;
  }
}

// .list-office-actual.list-office-useSlip .list-office-1 {
//   flex: 3;
// }
// End list-office-useSlip

//Start Table Useslip
.table-main {
  margin: 10px;
  #table-storge {
    display: flex;
    gap: 40px;
    .table-main-office {
      display: flex;
      align-items: flex-end;
      gap: 1px;
      padding-left: 1px;
      button {
        border-bottom: none;
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
        border-color: @color-black;
        span {
          color: black;
          font-weight: bold;
        }
      }

      .mode-active {
        height: 26px;
        // background-color: darkgray;
        border-color: @color-black;
        span {
          font-weight: unset;
        }
      }
    }
    .radio-check {
      padding: 5px;
      // border: 1px solid #dddddd;
      border-radius: 5px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 0;
      margin-bottom: 0;
    }
  }

  .table-list {
    .btnfilterOffice {
      padding: 2px;
      height: fit-content;
      background-color: #00838f;
      color: white;
    }

    i {
      font-size: 16px;
      padding: 4px;
      border: 1px solid #d9d9d9;
      line-height: 16px;
      border-radius: 3px;
      margin-left: 5px;
      cursor: pointer;
    }
    .btnfilterOffice:hover {
      background-color: #00838f;
      color: white;
    }
  }
  

  .table-main-useslip {
    .pljp-table-small {
      border: unset;
      .pljp-table-body {
        table {
          // border-top: 1px solid;
        }
      }
    }
  }

  .pljp-btn:hover,
  .pljp-btn:focus {
    color: @color-white !important;
  }
}

// .ant-table-wrapper {
//   overflow-x: auto;
//   max-width: 100%;
// }
//End Table Useslip
@media only screen and (max-device-width: 817px) {
  .use-slip {
    .header-useSlip {
      .sm-c-right {
        gap: 7px;
        a,
        div,
        button {
          margin-left: 0px !important;
        }
      }
    }
  }

  .modal-dialog-close {
    font-size: 12px;
    width: fit-content;
  }
  .modal-dialog {
    .modal-dialog-header {
      span {
        font-size: 15px;
      }
    }
  }
}

.modal-list-useSlip {
  .pljp-modal-title{
    font-weight: 500 !important;
  }
  .pljp-modal-header {
    border-bottom: none;
  }

  .pljp-modal-footer {
    border-top: none;
  }

  .table-check-mess {
    padding-bottom: 5px;
    .item-detail-office {
      padding: 5px 10px;
      border-bottom: 1px solid black;
    }

    .item-detail-office:last-child {
      border-bottom: none;
    }

    .pljp-table-thead > tr > th {
      height: initial;
    }

    .pljp-table-body > tr > td {
      padding: 8px 4px;
    }

    .pljp-table-body tr td:last-of-type {
      padding: 0 !important;
    }

    .custom-table-check-mess {
      td,
      th {
        border: 1px solid black;
      }

      tr {
        th {
          padding: 5px 7px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          text-wrap: nowrap;
        }

        td {
          padding: 5px 10px;
          text-wrap: nowrap;
          &:last-of-type {
            text-wrap: initial;
          }
        }
      }
    }

    .custom-table {
      width: 100%;
      tr {
        th {
          padding: 5px 7px;
          color: rgba(0, 0, 0, 0.85);
          font-weight: 500;
          text-wrap: nowrap;
        }

        td {
          padding: 5px 10px;
          text-wrap: nowrap;
          color: rgba(0, 0, 0, 0.85);
          &:last-of-type {
            text-wrap: initial;
          }
        }
      }

      .pljp-table-thead {
        tr th:last-of-type {
          border-top-right-radius: initial;
        }
      }
      .pljp-table {
        border-color: black !important;
      }

      .pljp-table-small.pljp-table-bordered .pljp-table-content {
        border-right: 1px solid black;
      }

      .pljp-table-thead,
      .pljp-table-tbody {
        tr {
          th,
          td {
            border-color: black !important;
          }
          td:last-child {
            border-right: 0.5px solid black !important;
          }
          th:last-child {
            border-right: 0.5px solid black !important;
          }
        }
        tr:last-child {
          td {
            border-bottom: none !important;
          }
        }
      }

      .pljp-table-placeholder {
        border-color: black !important;
        // border-right: 0.5px solid black !important;
      }

      tr:hover {
        background-color: #e0e0e0;
        cursor: pointer;
      }

      .selected {
        background-color: #d3d3d3;
      }
    }
  }
}
.modal-list-useSlip .pljp-modal-content .pljp-table .pljp-table-content {
  border: none !important;
}

.view-useSlip {
  .view-header-useSlip {
    .sm-c-right {
      display: flex;

      .item-link {
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        transition: 0.3s all;
        padding: 2px 8px;
        height: 41px;

        &:hover {
          background-color: #e0e0e0;
          border-radius: 4px;
        }
      }
    }
  }

  .view-information-useSlip {
    padding: 10px;

    .view-days {
      .view-day-created,
      .view-day-agreed {
        border: 1px solid black;
        .view-day-created-title {
          width: 104px;
          padding: 0.8px 37px 0.8px 2px;
          border-right: 1px solid black;
        }
        .title-security {
          width: 96px;
        }
      }
    }

    .view-security {
      td {
        display: inline-block;
        padding: 2px 30px;
      }
    }

    #view-table-attached {
      .useSlipDetail {
        table {
          border: 1px solid;
          th,
          td {
            font-size: 11px;
            padding: 2px 8px !important;
          }
          .pljp-table-tbody {
            tr td {
              border-color: black;
            }
            tr:nth-of-type(3) {
              td:nth-of-type(3),
              td:nth-of-type(4) {
                border: 2px solid black;
              }

              td:nth-of-type(4) {
                border-left: 1px solid black;
              }
            }

            td:first-child {
              // padding: 0 8px !important;
            }

            tr > td:nth-of-type(3),
            tr:nth-of-type(3) td:nth-of-type(3) {
              background-color: #00838f;
              color: #ffffff;
            }
          }
        }
      }

      .useSlipDetail {
        // width: 48%;
      }

      .table-information-left {
        // width: 90%;
        td {
          padding: 2.5px 5px;
          text-align: center;
          &:first-of-type,
          &:nth-of-type(3) {
            color: #ffffff;
            border-color: black;
            background-color: #00838f;
            text-align: center !important;
          }

          &:nth-of-type(2),
          &:nth-of-type(4) {
            text-align: right !important;
            font-weight: 600;
            color: black;
          }
        }
      }
    }

    .view-information-right {
      width: 25%;
      table,
      td,
      th {
        border: 1px solid;
      }

      th,
      td {
        padding: 2.5px 8px;
        width: 1%;
        font-size: 11px;
        font-weight: 400;
        text-align: center;
      }

      th {
        background-color: #00838f;
        border-color: black;
        color: #ffffff;
        font-weight: 400;
        border-bottom: none;
      }

      td:first-child {
        color: #ffffff;
        border-color: black;
        background-color: #00838f;
      }

      td {
        border-top: none;
        text-align: center;
      }

      table {
        width: 100%;
        border-collapse: collapse;
      }

      .table-information-right {
        width: 80%;
        margin-left: auto;

        tr {
          th {
            width: 50%;
          }
        }
      }

      .table-sort {
        tr {
          th,
          td {
            padding-top: 2.4px;
          }
        }
      }
    }

    .view-information-left {
      width: 75%;
      .table-information-left,
      td {
        border: 1px solid black;
      }

      #table-header-information-attached {
        width: 90%;
      }

      .table-information-left {
        border-collapse: collapse;
        width: 98%;

        th,
        td {
          padding: 1px 3px;
          width: 1%;
          font-size: 11px;
          font-weight: 400;
          text-align: center;
        }

        tr {
          th,
          td {
            &:first-of-type {
              background-color: #00838f;
              color: white;
              text-align: initial;
            }
          }
        }

        .table-comment {
          td {
            &:last-of-type {
              word-break: break-all;
            }
          }
        }
      }

      .table-information-left,
      th,
      td {
        border: 1px solid black;
      }

      .full-width {
        width: 100%;
      }
    }
  }

  .table-view-useSlip {
    padding: 0 10px;
    #useslip-table {
      .pljp-table-small {
        border: none;
      }
    }

    .pljp-table-thead > tr:first-child {
      th:first-child {
        border-top-left-radius: 1px;
      }

      th:last-child {
        border-top-right-radius: 1px;
      }
    }
  }
}

#view-attached {
  .table-main {
    margin: 12px 10px;
  }

  .view-information-left {
    .view-attached-created {
      align-items: flex-start;

      .view-day-created-title {
        padding: 1px 37px 1px 2px;
      }
    }
  }

  .custom-table {
    .pljp-table-small > .pljp-table-content > .pljp-table-header > table,
    .pljp-table-small > .pljp-table-content > .pljp-table-body > table,
    .pljp-table-small > .pljp-table-content > .pljp-table-scroll > .pljp-table-header > table,
    .pljp-table-small > .pljp-table-content > .pljp-table-scroll > .pljp-table-body > table,
    .pljp-table-small > .pljp-table-content > .pljp-table-fixed-left > .pljp-table-header > table,
    .pljp-table-small > .pljp-table-content > .pljp-table-fixed-right > .pljp-table-header > table,
    .pljp-table-small > .pljp-table-content > .pljp-table-fixed-left > .pljp-table-body-outer > .pljp-table-body-inner > table,
    .pljp-table-small > .pljp-table-content > .pljp-table-fixed-right > .pljp-table-body-outer > .pljp-table-body-inner > table {
      border: 1px solid black !important;
    }
    .pljp-table-thead {
      tr {
        th {
          text-align: center;
          padding: 8px 4px;
          background-color: #00838f;
          color: #ffffff;
          border-color: black;
        }
      }
    }

    .pljp-table-tbody {
      tr {
        td {
          border-color: black;
        }

        td:nth-child(1),
        td:nth-child(3) {
          text-align: left;
        }

        td:nth-child(2) {
          text-align: center;
        }

        td {
          text-align: right;
        }
      }
    }
  }
}

.form-provide {
  padding: 0 10px;
  .pljp-modal-body {
    padding: 16px 0px;
  }

  .form-provide-name,
  .form-provide-color,
  .form-provide-schedule-comment,
  .form-provide-schedule-content {
    padding: 0 24px;
  }

  .form-provide-setup {
    border: 1px solid #D9D9D9;
    border-left: none;
    border-right: none;
    padding: 20px 24px;

    margin-top: 16px;
  }

  .input-setting{
    width: 66px !important;
    height: 25px ;
  }

  .radio-check {
    label {
      margin-right: 25px;
    }
  }

  .group-pick-color {
    display: flex;
    align-items: center;
    padding-top: 5px;
    align-items: center;

    .color-picker-container {
      display: flex;
      align-items: center;
      border: 1px solid #D9D9D9;
      padding: 0 13px 0 0;
      .color-display {
        width: 50px;
        height: 50px;
        border: 1px solid #000;
        margin-bottom: 10px;
      }
  
      .color-input {
        width: 22px;
        height: 22px;
        margin-right: 5px;
  
        border: none;
        outline: none;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  //Schedule

  .form-provide-sort {
    padding: 0px 24px;
    margin-top: 16px;
  }

  .form-provide-schedule-comment {
    .d-flex-o {
      display: flex;
      align-items: center;
    }
  }

  .btn-schedule-excel {
    border-radius: 4px;
    border: 1px solid rgb(219, 219, 219);
    padding: 5px;
  }
}

.btn-all{
  margin-bottom: 10px;
}

#view-sonota {
  .view-information-useSlip {
    .view-information-left {
      .table-information-left {
        width: 80%;
      }
    }
  }

  .table-main-useslip {
    margin: 12px 10px;
    #useslip-table {
      margin: 0px;
    }
    .data-table-use-slip {
      .pljp-table-thead {
        tr:first-of-type {
          th:nth-of-type(1),
          th:nth-of-type(2),
          th:nth-of-type(3),
          th:last-of-type {
            text-align: center;
            padding: 8px 4px !important;
            background-color: #00838f !important;
            color: #ffffff;
            border-color: black;
          }
        }
      }
    }
  }
}

.data-table-use-slip{
  width: 100%;
  border-collapse: collapse;
}
.group-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap:5px;
}

.eraser-icon, .eraser-icon-active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 32px;
  border-radius: 5px;
  font-size: 20px;
  background: #ccc;
  -webkit-transform: none !important;
  transform: none !important;
}

.eraser-icon-active {
  cursor: pointer;
  background: #9298a1;
}

.group-icon-calendar, .calendar-active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 32px;
  background: #00838F;
  border-radius: 5px;
  font-size: 20px;
  opacity: 0.6;
}
.group-icon-calendar{
  cursor: not-allowed;
}

.calendar-active {
  opacity: 1;
  cursor: pointer;
}


.input-file {
    margin-left: 7px;
    width: 200px;
    height: 25px;
    margin-top: 4px;
    color: #00838F;
}

@media only screen and (max-device-width: 1580px) {
  .list-office-3 {
    display: none;
  }

  .table-date-useslip {
    height: 92vh;
  }

  .table-date-useslip.table-date-record-lengther {
    height: 98vh;
  }
}

@media only screen and (max-device-width: 1368px) {
  .use-slip #header-useslip {
    .item-hoken {
      margin-left: 0;
    }
  }

  .use-slip #header-useslip.header-attachedTable .item-hoken {
    margin-left: 10px;
  }

  .gap-md-4 {
    gap: 4px;
  }

  .sonota-group {
    .btnAction-addEdit,
    .btnAction-duplicateRemove {
      button {
        font-size: 12px;
        width: 65px;
      }
    }
    .sonota-groupModal {
      .groupModal-option {
        .title-calendar {
          display: none;
        }
      }
    }
  }

  .attached-table {
    /* custom-table.css */
    .pljp-table-body {
      /* Optional: If you want to hide the vertical scrollbar, set its width to 0px */
      /* overflow-y: hidden; */
    }

    .pljp-table-scroll .pljp-table-body::-webkit-scrollbar {
      // height: 8px;
      // width: 8px;
    }

    .pljp-table-scroll .pljp-table-body::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 6px;
    }

    .pljp-table-scroll .pljp-table-body::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }

  .table-main {
    #table-storge {
      gap: 8px;
    }
  }

  .toolbar-useSlip-1 {
    gap: 5px;
  }

  .list-office-useSlip {
    align-items: initial;
    gap: 7px;
    .list-office-1 {
      align-items: initial;
      padding-top: 4px;
      gap: 7px;
      flex: 1.5;
      margin-right: 0;

      .groupBtnAction,
      .list-btn-group {
        button {
          line-height: 27px;
        }
        justify-content: end;
        .d-flex:first-of-type {
          flex-direction: column;
        }
      }

      .btn-calendar {
        transform: translateY(0);
        .title-calendar {
          display: none;
        }
      }
    }

    .list-office-2 {
      flex: 1;
      justify-content: space-between;
      .boxItem-detailUseSlip {
        flex: 2;
        .group-list-office-2 {
          flex: 1;
        }
      }
      .btnOrange {
        margin: auto 0px auto 10px;
        button {
          height: 35px;
          .d-none-md {
            display: none;
          }
        }
      }
    }

    .pljp-table-tbody > tr {
      font-size: 14px;
    }

    .groupBtnAction > div {
      font-size: 18px;
    }
  }

  .table-date-useslip {
    height: 88vh;
    .pljp-modal-content {
      .pljp-modal-body {
        .table-date-useslip-content {
          .useslip_quote_weekly {
            .color-master-useslip {
              height: calc(100% - 30px);
            }
          }
        }
      }
    }
  }

  .table-date-useslip.table-date-record-lengther {
    height: 88vh;
    .color-master-useslip {
      height: calc(100% - 33px) !important; 
    }
  }
}
.list-item-check{
  color: @color-black;
  font-size: 14px;
  justify-content: initial;
  gap: 13px;
}

@media screen and (max-device-width: 1181px) {
  .gap-md-20 {
    gap: 20px;
  }

  .toolbarTable .useSlipDetail .pljp-table-bordered .pljp-table-tbody > tr > td {
    padding: 5.25px 8px 5.25px 25px !important;
  }

  .toolbarTable {
    .toobarTable-right {
      margin-right: 65px;
    }
  }

 
}

@media screen and (max-device-width: 1200px) {
  .table-main {
    #table-storge {
      gap: 4px;

      .table-main-office {
        button {
          padding: 5px;
        }
      }

      .pljp-radio-group {
        display: flex;
        align-items: flex-end;
        padding: 5px 2px;

        .pljp-radio-wrapper {
          margin-right: 3px;
        }

        .pljp-radio-checked {
          padding: 0 3px;
        }

        label {
          font-size: 13px;

          span {
            &:last-child {
              padding: 0 5px;
            }
          }
        }
      }

      .line-h-32 {
        transform: translateY(1px);
        span {
          font-size: 13px;
        }
      }
    }
  }

  .view-useSlip {
    .view-information-useSlip {
      gap: 5px;
      padding: 10px 10px 5px 10px;

      .view-information-left {
        width: 100%;
        .table-information-left {
          width: 100%;
        }
        .view-toltal-created {
          .item-hoken {
            padding: 0.5px 10px;
          }
        }
      }

      .view-information-right {
        width: 100%;
        // gap: 10px;
        .table-information-right {
          width: 100%;
          margin-left: unset;

          border: 1px solid;
          // border-right: none;

          tr {
            display: flex;
            width: 100%;

            th {
              // width: 50%;
              border: none;
              border-left: 1px solid;
              width: 47%;
              &:first-of-type {
                border-left: none;
                width: 46.5%;
              }
            }
          }
        }

        .text-right {
          text-align: center;
        }

        .view-days {
          width: 24%;
          .text-right {
            display: flex;
            justify-content: initial;
            padding: 0 8px;

            span {
              &:last-of-type {
                margin-left: 105px !important;
              }
            }
          }
        }

        .table-sort {
          width: 36%;
        }
      }
    }
  }

  #view-attached {
    .view-information-useSlip {
      gap: 5px;

      #view-table-attached {
        flex-direction: row-reverse;
      }

      #table-header-information-attached {
        width: 100% !important;
      }
    }

    .table-main {
      margin-top: 0;
    }

    #view-table-attached {
      .useSlipDetail {
        width: 48%;
      }
      .table-information-left {
        width: 50%;
      }
    }
  }

  #view-sonota {
    .view-information-useSlip {
      flex-direction: row;
    }

    .view-information-left {
      flex: 3;
      .table-information-left {
        width: 100% !important;
      }
    }

    .view-information-right {
      flex: 1;
      display: inline-block;

      .table-information-right {
        border-right: 1px solid black;

        tr {
          th {
            &:first-of-type {
              width: 47.5%;
            }
          }
        }
      }
    }

    .table-main-useslip {
      margin: 0px 10px 10px;
      #useslip-table {
        margin: 0;
      }
    }
  }

  .group-icon {
    justify-content: initial;
    margin: 0 3px;
  }

}

@media screen and (max-device-width: 1124px) {
  #header-useslip.toolbar-useSlip {
    margin-top: 6px;
    margin-bottom: 4px;
  }

  #input-use-slip {
    .list-office-useSlip {
      // flex-direction: column;
      .groupBtnAction {
        button {
          padding: 0px 10px;
        }
      }

      .list-office-1 {
        flex: 1;
        .groupBtnAction {
          flex-direction: column;
        }
      }

      .list-office-2 {
        flex: 0.8;
        .group-list-office-2 {
          .pljp-table {
            .pljp-table-tbody {
              tr {
                td {
                  // padding: 4px 8px;
                }
              }
            }
          }
        }

        .btnOrange {
          button {
            &:first-of-type {
              padding: 6px 10px;
            }

            &:last-of-type {
              padding: 5px 10px;
            }
          }
        }
      }
    }
  }

  .toolbarTable {
    .toobarTable-right {
      margin-right: 15px;
    }
  }

  .w-65 {
    width: 65px !important;
  }

  .list-office-useSlip .pljp-btn {
    font-size: 12px;
  }
}

@media screen and (max-device-width: 1440px) {
  .view-useSlip {
    .view-information-useSlip {
      flex-direction: column;
      gap: 5px;

      .view-information-left {
        .view-toltal-created {
          justify-content: space-between;
          gap: 0;
        }
      }

      .view-information-right {
        display: flex;
        justify-content: space-between;

        .view-tano {
          margin-left: 0;

          .view-day-tano-title {
            padding-right: 45px;
          }
        }

        table {
          width: auto;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .use-slip {
    .header-btn-list {
      button {
        padding: 0 9px;
      }
    }

    .toolbarTable {
      flex-wrap: wrap;
    }

    .submenu {
      padding: 8px 0px;
    }

    .header-sonota {
      padding: 8px 10px;
    }

    .pljp-radio-group {
      label {
        display: block;

        &:first-of-type {
          margin-bottom: 10px;
        }
      }
    }

    .header-submenu {
      padding: 8px 10px;
    }

    #header-useslip {
      gap: 0;
    }
  }

  .header-title-name {
    font-size: 1.43em;
  }

  .header-useSlip {
    font-size: 12px;

    .max-title.header-title {
      width: 15%;
    }

    .useSlip-date {
      font-size: 13px;
    }
  }

  .toolbar-useSlip {
    font-size: 9px;
    .toolbar-useSlip-1 {
      gap: 10px;
    }

    .tanto-useslip {
      .group-tanto {
        .hidden-item-small {
          font-size: 11px;
        }
        .tanto-name {
          width: 100px;
          font-size: 12px;
        }
      }

      .messageIcon {
        transform: translateY(0);
        margin-top: 14px;
        .icon-pen {
          left: 40%;
        }
      }
    }
  }

  .view-useSlip {
    .view-information-useSlip {
      .view-information-right {
        width: 100%;
        .table-information-right {
          width: 100%;
          margin-left: unset;

          border: 1px solid;
          // border-right: none;

          tr {
            display: flex;
            width: 100%;

            th {
              // width: 50%;
              border: none;
              width: 45.8%;
              border-left: 1px solid;
              &:first-of-type {
                border-right: none;
                width: 45.8%;
              }
            }
          }
        }

        .view-days {
          width: 24%;
          .text-right {
            display: flex;
            justify-content: initial;
            padding: 0 8px;

            span {
              &:last-of-type {
                margin-left: 86px !important;
              }
            }
          }
        }
      }
    }
  }
}

.dialog-kaigo{
  .pljp-modal-header{
    .pljp-modal-title{
      font-weight: 500 !important;
    }
  }
  .pljp-table-wrapper .pljp-table-thead >tr>th{
    font-weight: 500;
  }
}
.btn-calendar-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  color: white;
  .title-calendar {
    color: black;
  }
}
.sonota-setting{
  display: flex !important;
  width: 100% !important;
}


.modal-kyufu{
  .pljp-modal-title{
    font-weight: 500 !important;
  }
}

.custom-table{
  .pljp-table-container{
    border-inline-start: unset !important;
    border-top: unset !important;
  }
}
.white{
  color: white;
}
.row-service-modal{
  td{
    padding: 3px !important;
  }
}
.modal-list-useslip{
  table{
    border: 1px solid !important;
  }
}
.color-red{
  background: red !important;
}
.checkbox_useslip:checked {
  accent-color: #00838F !important;
}

