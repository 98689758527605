.back {
  max-width: 100%;
  max-height: 100%;
  background-color: #FFF9C4;
}
.weekly-plan-schedule {
  &__main {
    max-width: 1081px;
    margin: 0px auto;
    padding: 20px 15px 500px 15px !important;
    background-color: #FFFDE7;
  }
    .menu-header-web {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: #212121d9;
      color: @white;
      padding: 8px 16px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .sm-c {
        display: flex;
        justify-content: space-between;
        .sm-c-left {
          h3 {
            color: @white;
          }
          &__name {
            align-items: baseline;
            .title {
              margin-right: 10px;
            }
            display: flex;
            div:last-child {
              font-size: 1.2em;
              font-weight: bold;
            }
          }
        }
        .sm-c-right {
          display: flex;
          .link-item1 {
            color: @white;
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .link-item2 {
            color: @white;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .button-callback {
            background-color: transparent;
            border: none;
            display: flex;
            &:focus {
              outline: none;
            }
            &:hover {
              cursor: pointer;
            }
          }
          p {
            margin-top: 5px;
            color: @white;
          }
        }
      }
      .group-input-tab {
        display: flex;
      }
      .tabinput-content {
        margin-left: 0px;
        margin-right: 50px;
      }
      .group-button-view {
        display: flex;
  
        .item-button {
          //   margin: 20px auto 0;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-line-pack: end;
          align-content: flex-end;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  
          // .button-content {
          //   border-radius: 3px;
          //   margin-right: 5px;
          //   margin-bottom: 5px;
          //   padding: 0.25em 0.5em;
          //   display: inline-block;
          //   color: #000;
          //   background: #fff;
          //   font-size: 1em;
// }
    }
  }
}
    .menu-footer {
      position: fixed;
      bottom: 0;
      z-index: 2;
      background-color: #212121d9;
      color: @white;
      padding: 8px 16px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      .sm-c-right {
        display: flex;
        .link-item1 {
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .button-callback {
          background-color: transparent;
          border: none;
          display: flex;
          &:focus {
            outline: none;
          }
          &:hover {
            cursor: pointer;
          }
        }
        .link-item2 {
          color: @white;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        p {
          margin-top: 5px;
          color: @white;
        }
      }
    }
    &__header {
      display: flex;
      justify-content: space-between;
      color: @black;
      margin-bottom: 15px;
    }
    &__controll1,
    &__controll2,
    &__controll3 {
      display: flex;
      height: min-content;
      p {
        display: flex;
        align-items: center;
        margin-right: 5px;
        white-space: nowrap;
      }
      span {
        width: 200px;
      }
    }
    &__controll4 {
      button {
        background-color: @blue;
        color: #ffffff;
        transition: 0.3 all;
        border: none;
      }
    }
    &__controll4 {
      button:hover {
        background-color: #09a2b0;
        color: #ffffff;
      }
    }
    &__input-text {
      margin-bottom: 15px;
      &__title {
        display: flex;
        color: #000000;
        align-items: center;
        margin-bottom: 5px;
        p {
          margin-right: 5px;
        }
      }
    }
    .Inquiry-Request__button {
      border-radius: 50%;
      width: 27px;
      height: 27px;
      background-color: @blue;
      border: none;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s all;
    }
    .Inquiry-Request__button:hover {
      background-color: #09a2b0;
      color: #ffffff;
    }
    &__table-main {
      display: flex;
      align-items: center;
    }
    &__table {
      .pljp-table-thead {
        white-space: nowrap;
      }
      flex: 1;
    }
    &__th-column {
      display: flex;
      align-items: center;
    }
    &__th-column-p {
      margin-right: 10px;
    }
    &__add-reference {
      button {
        border: none;
        background-color: #ff4d4f;
        color: #ffffff;
        transition: 0.3s all;
        margin-top: 10px;
      }
    }
    &__add-reference button:hover {
      background-color: #e76d60;
      color: #ffffff;
    }
    &__button-del {
      background-color: #808080;
      border-color: #808080;
      color: #ffffff;
      margin-left: 5px;
      width: 55px;
      height: 30px;
      border-radius: 4px;
      border: none;
      padding: 0;
      transition: 0.3s all;
    }
    &__button-del:hover {
      background-color: #a0a0a0;
      border-color: #a0a0a0;
      color: #ffffff;
    }
    &__button-del:focus {
      outline: none;
    }
  
    @media (max-width: 992px) {
      &__controll1,
      &__controll2 {
        span {
          width: 120px;
        }
      }
      &__controll3 {
        .tanto-master {
          display: flex !important;
          &__select:last-child {
            width: 120px !important;
          }
        }
      }
    }
  
    @media (max-width: 575px) {
      &__header {
        flex-direction: column;
        div:nth-child(1n) {
          margin-bottom: 5px;
        }
      }
      &__controll1,
      &__controll2 {
        p {
          margin-right: 78px;
        }
        span {
          width: 220px;
        }
      }
      &__controll3 {
        p {
          margin-right: 22px;
          margin-top: 5px;
          align-items: baseline !important;
        }
        .tanto-master {
          .tanto-master__select:last-child {
            width: 146px !important;
          }
        }
      }
    }
    &__mobile {
      &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-weight: 600;
        }
      }
      &__item3,
      &__item4,
      &__item5,
      &__item6 {
        margin: 15px 0;
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 5px 0;
          p {
            margin-right: 5px;
            font-weight: 600;
          }
        }
      }
      &__item1,
      &__item2 {
        margin: 15px 0;
        display: flex;
        white-space: nowrap;
        align-items: center;
        p {
          margin-right: 5px;
          font-weight: 600;
        }
      }
    }
    

    &__toolbar {
      &__row {
        display: flex;
        justify-content: space-between;
      }
      &__row-picker {
        display: flex;
        align-items: center;
      }
      &__date-picker {
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space: nowrap;
        p {
          margin-right: 5px;
          font-weight: 600;
        }
      }
      &__total-rental p {
        border: 1px solid;
        border-radius: 10px;
        padding: 5px;
        background-color: #fff;
      }
    }

    &__button-create {
      background-color: #a30000;
      border: none;
    }

    &__button-delete {
      background-color: gray;
      border: none;
    }

    &__content-middle {
      display: flex;
      flex-wrap: wrap;
      &__flex-item-left {
        flex: 40%;
        display: flex;
        align-items: flex-end;
      }
      
      &__flex-item-right {
        flex: 60%;
      }

      @media (max-width: 800px) {
        &__flex-item-right, &__flex-item-left {
          flex: 100%;
        }
      }
    }

    &__fieldset {
      position: relative;
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 10px;
      background-color: #fff;
    }
    
    &__fieldset button {
      position: absolute;
      top: 0;
      line-height: 0;
      margin: -9px 0 0; /* half of font-size */
      padding: 10px 3px;
    }
    &__fieldset .pljp-checkbox-wrapper:nth-child(1) {
      margin-left: 8px;
    }

    &__content-table {
      margin-top: 20px;
      tr td:nth-child(1),
      tr td:nth-child(2),
      tr td:nth-child(3),
      tr td:nth-child(4),
      tr td:nth-child(5) {
        text-align: center;
      }
      .pljp-table-tbody td {
        white-space: normal !important;
        text-overflow: clip !important;
        background-color: #fff;
      }
      .pljp-table-thead tr {
        background-color: #fff;
      }
    }

    &__content-footer {
      margin-top: 20px;
      display: flex;
      align-items: center;
    }
    
    

  }
  .kim-row {
    text-align: center;
    margin-left: 20px;
  }
  .hm-item-extent {
    background: none;
    border: none;
  }

  .hm-item {
    height: 62.5px !important;
    width: 62.5px !important;
    font-size: 22px !important;
  }

  .hm-item-del {
    padding: 0px;
  }

  .weekly-plan-schedule__button-create{
    color: white;
  }