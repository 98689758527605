#kanri-page {
  height: 100vh; }
  #kanri-page #table-resizeable table {
    border-collapse: collapse;
    border-spacing: 0px; }
  #kanri-page #table-resizeable td {
    border: 2px solid black;
    padding: 0;
    margin: 0px;
    overflow: auto; }
  #kanri-page #table-resizeable div {
    resize: both;
    overflow: auto;
    width: 120px;
    height: 120px;
    margin: 0px;
    padding: 0px;
    border: 1px solid black;
    display: block; }
  #kanri-page #table-resizeable td div {
    border: 0;
    width: auto;
    height: auto;
    min-height: 20px;
    min-width: 20px; }
  #kanri-page #table-resizeable-2 .react-resizable {
    position: relative;
    background-clip: padding-box; }
  #kanri-page #table-resizeable-2 .react-resizable-handle {
    position: absolute;
    right: -5px;
    bottom: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
    cursor: col-resize; }
  #kanri-page #table-resizeable-2 .pljp-table-pagination {
    float: left; }
  #kanri-page .pljp-layout-header {
    margin: 16px 8px 0px 48px; }
  #kanri-page .kanri-header .kanri-header-content .pljp-input-search {
    margin-right: 16px; }
    #kanri-page .kanri-header .kanri-header-content .pljp-input-search input {
      border-radius: 4px;
      height: 40px; }
  #kanri-page .kanri-header .kanri-header-content .kanri-header-logout-btn {
    margin-top: 4px;
    background: none;
    border: none; }
    #kanri-page .kanri-header .kanri-header-content .kanri-header-logout-btn i {
      background-color: #00838f;
      color: #ffffff;
      padding: 6px;
      border-radius: 3px; }
  #kanri-page .pljp-menu {
    background-color: #fafafa; }
    #kanri-page .pljp-menu .pljp-menu-item {
      height: 50px;
      margin-left: 16px;
      padding-left: 10px !important; }
      #kanri-page .pljp-menu .pljp-menu-item .anticon {
        background-color: #ffffff;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0 4px 6px #cdcdcd; }
    #kanri-page .pljp-menu .pljp-menu-item::after {
      border: none; }
    #kanri-page .pljp-menu .pljp-menu-item-selected {
      background-color: #ffffff;
      border-radius: 8px;
      padding: 5px;
      box-shadow: 0 8px 14px #cdcdcd; }
      #kanri-page .pljp-menu .pljp-menu-item-selected .anticon {
        background: #00838f;
        color: #ffffff; }
  #kanri-page .pljp-menu-inline {
    border: none; }
  #kanri-page .pljp-layout-header {
    background-color: #fafafa; }
  #kanri-page .pljp-layout {
    background-color: #fafafa;
    overflow: scroll; }
  #kanri-page .logo {
    height: 54px;
    background: #fafafa; }
    #kanri-page .logo h2 {
      color: #444444;
      text-align: center;
      margin-top: 8px; }
  #kanri-page .pljp-layout-header {
    height: 54px; }
    #kanri-page .pljp-layout-header .kanri-header-logout-btn {
      margin-top: 8px;
      margin-right: 16px;
      float: right; }
  #kanri-page .table-operations {
    margin-bottom: 8px; }
    #kanri-page .table-operations button {
      margin-right: 8px; }
  #kanri-page .kanri-home-page .f-khp-dash {
    padding: 8px; }
    #kanri-page .kanri-home-page .f-khp-dash .khp-dash {
      background-color: #ffffff;
      display: flex;
      align-items: center;
      padding: 16px;
      border-radius: 12px;
      color: #444444;
      font-size: 18px;
      box-shadow: 0 16px 20px #cdcdcd; }
      #kanri-page .kanri-home-page .f-khp-dash .khp-dash .f-khp-dash-sp {
        text-align: right; }
        #kanri-page .kanri-home-page .f-khp-dash .khp-dash .f-khp-dash-sp .khp-dash-sp {
          background-color: #00838f;
          border-radius: 8px;
          padding: 16px;
          color: #ffffff; }
  #kanri-page .pljp-form-item {
    margin-bottom: 8px; }
  #kanri-page .kanri-page-send-group {
    background-color: #00838f;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    display: flex;
    color: white; }
  #kanri-page .kanri-page-send-group:hover {
    background-color: #19959c; }
  #kanri-page .krsg-select-area .pljp-checkbox-group-item {
    display: block;
    margin: 8px; }

.kanri-search-dialog .pljp-modal-body {
  padding: 8px 4px; }

.kanri-table {
  margin: 10px auto; }
  .kanri-table .table .pljp-table-body {
    overflow: scroll !important; }

.kanri-table-select-dis .pljp-checkbox-group-item {
  width: 200px; }

.m-kanri-record .pljp-btn {
  margin-right: 8px; }

.m-kanri-record .pljp-row {
  margin-bottom: 8px; }
