@media screen and (max-width: 768px) {
  #login-form {
    max-width: 300px;
    width: 300px;
    margin-left: -150px;
    position: absolute;
    left: 50%;
    top: 10%; }
    #login-form .lg-f-header {
      text-align: center; }
      #login-form .lg-f-header h1 {
        padding-top: 16px; }
    #login-form .lg-form form input {
      height: 32px; }
    #login-form .lg-form button {
      width: 100%;
      background-color: #00838F;
      height: 35px; }
    #login-form .lg-form .lg-mes {
      color: red; }
    #login-form .lg-f-error {
      color: #d50000;
      font-size: 11px; }
  #code-form {
    max-width: 300px;
    width: 300px;
    margin-left: -150px;
    position: absolute;
    left: 50%;
    top: 10%; }
    #code-form .lg-f-header {
      text-align: center; }
      #code-form .lg-f-header h1 {
        padding-top: 16px; }
    #code-form .lg-form form input {
      height: 32px; }
    #code-form .lg-form button {
      width: 100%;
      background-color: #00838F;
      height: 35px; }
    #code-form .lg-form .lg-mes {
      color: red; }
    #code-form .lg-f-error {
      color: #d50000;
      font-size: 11px; } }

@media screen and (min-width: 769px) {
  #login-form {
    max-width: 323px;
    width: 340px;
    margin-top: -200px;
    margin-left: -145px;
    position: absolute;
    left: 50%;
    top: 35%; }
    #login-form .lg-f-header {
      text-align: center; }
      #login-form .lg-f-header h1 {
        padding-top: 16px; }
    #login-form .lg-form {
      justify-content: center; }
      #login-form .lg-form form input {
        height: 32px; }
      #login-form .lg-form button {
        width: 100%;
        background-color: #00838F;
        height: 35px; }
      #login-form .lg-form .lg-mes {
        color: red;
        position: relative;
        text-align: center; }
    #login-form .lg-f-error {
      color: #d50000;
      font-size: 11px; }
    #login-form .lg-mes-n .lg-mes {
      line-height: 24px;
      width: 769px; }
  #code-form {
    max-width: 340px;
    width: 340px;
    margin-top: -200px;
    margin-left: -145px;
    position: absolute;
    left: 50%;
    top: 35%; }
    #code-form .code-form-item-login {
      display: table;
      margin: 0 auto; }
    #code-form .pljp-form-item {
      margin-bottom: 16px; }
    #code-form .lg-f-header {
      text-align: center; }
      #code-form .lg-f-header h2 {
        padding-top: 24px; }
      #code-form .lg-f-header p {
        text-align: left; }
    #code-form .lg-form form input {
      height: 32px; }
    #code-form .lg-form button {
      width: 250px;
      height: 35px; }
    #code-form .lg-form .lg-mes {
      color: red;
      position: relative;
      text-align: center; }
    #code-form .lg-form .pljp-checkbox-wrapper {
      line-height: 1.8em; }
    #code-form .lg-f-error {
      color: #d50000;
      font-size: 11px; } }

#login-form .lg-form button {
  height: 32px;
  line-height: 32px; }
