#input-kyotaku-plan1-page .back {
  max-width: 100%;
  max-height: 100%;
  background-color: #fff9c4; }

#input-kyotaku-plan1-page .main {
  background-color: #fffde7;
  padding: 20px 15px 15px 15px !important; }

#input-kyotaku-plan1-page .pljp-row:not(.submenu):not(.main-header) {
  padding: 0; }

#input-kyotaku-plan1-page .pljp-col-24 .pljp-form-item-control-wrapper {
  width: 100%; }

#input-kyotaku-plan1-page .pljp-form-inline .pljp-form-item > .pljp-form-item-control-wrapper,
#input-kyotaku-plan1-page .pljp-form-inline .pljp-form-item > .pljp-form-item-label {
  vertical-align: middle; }

#input-kyotaku-plan1-page .pljp-form-item-label > label {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65); }

#input-kyotaku-plan1-page .item-riyu-hoka .pljp-form-item-control-wrapper {
  width: 100%; }

#input-kyotaku-plan1-page .item-comment .pljp-form-item-control-wrapper {
  width: calc(100% - 66px); }

#input-kyotaku-plan1-page .fixed-header {
  position: sticky;
  top: 0;
  z-index: 2; }

#input-kyotaku-plan1-page .fixed-footer {
  position: sticky;
  bottom: 0;
  z-index: 2; }

#input-kyotaku-plan1-page .mt-1 {
  margin-top: 0.5rem; }

#input-kyotaku-plan1-page .text-bold {
  font-weight: bold; }

#input-kyotaku-plan1-page .pr-1 {
  padding-right: 0.5rem; }

#input-kyotaku-plan1-page .mr-1 {
  margin-right: 0.5rem; }

#input-kyotaku-plan1-page .border-black {
  border: 1px solid; }

#input-kyotaku-plan1-page .mb-1 {
  margin-bottom: 1.5rem; }

#input-kyotaku-plan1-page .p-1 {
  padding: 0.5rem; }

#input-kyotaku-plan1-page .border-bottom {
  border-bottom: 1px solid; }

#input-kyotaku-plan1-page .flex-end {
  display: flex;
  justify-content: flex-end; }

#input-kyotaku-plan1-page .w-full {
  width: 100%; }

#input-kyotaku-plan1-page .white {
  color: white; }

#input-kyotaku-plan1-page .hoverable-element {
  cursor: pointer;
  caret-color: transparent; }

@media (max-width: 575px) {
  #input-kyotaku-plan1-page .pljp-form-item-label {
    padding-right: 10px;
    padding-bottom: 0; }
  #input-kyotaku-plan1-page .pljp-form-item-label,
  #input-kyotaku-plan1-page .pljp-form-item-control-wrapper {
    width: initial; }
  #input-kyotaku-plan1-page .kaigo-button {
    margin-bottom: -20px !important; } }

#input-kyotaku-plan1-page .kaigo-button {
  float: right;
  margin-bottom: -32px;
  z-index: 1; }

#input-kyotaku-plan1-page .tanto-dropdown {
  width: unset !important;
  min-width: 170px; }

#input-kyotaku-plan2-page .back {
  max-width: 100%;
  max-height: 100%;
  background-color: #fff9c4; }

#input-kyotaku-plan2-page .main {
  background-color: #fffde7; }

#input-kyotaku-plan2-page .pljp-row:not(.submenu):not(.main-header) {
  padding: 0; }

#input-kyotaku-plan2-page .pljp-col-24 .pljp-form-item-control-wrapper {
  width: 100%; }

#input-kyotaku-plan2-page .pljp-form-inline .pljp-form-item > .pljp-form-item-control-wrapper,
#input-kyotaku-plan2-page .pljp-form-inline .pljp-form-item > .pljp-form-item-label {
  vertical-align: middle; }

#input-kyotaku-plan2-page .pljp-form-item-label > label {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.65); }

#input-kyotaku-plan2-page .pljp-btn {
  padding: 0 10px; }

#input-kyotaku-plan2-page .mb-2 {
  margin-bottom: 0.2rem; }

#input-kyotaku-plan2-page .item-riyu-hoka .pljp-form-item-control-wrapper {
  width: 100%; }

#input-kyotaku-plan2-page .item-comment .pljp-form-item-control-wrapper {
  width: 100%; }

#input-kyotaku-plan2-page .fixed-header {
  position: sticky;
  top: 0;
  z-index: 2; }

#input-kyotaku-plan2-page .fixed-footer {
  position: sticky;
  bottom: 0;
  z-index: 2; }

#input-kyotaku-plan2-page .mt-1 {
  margin-top: 0.5rem; }

#input-kyotaku-plan2-page .mr-1 {
  margin-right: 0.5rem; }

#input-kyotaku-plan2-page .text-bold {
  font-weight: bold; }

#input-kyotaku-plan2-page .pr-1 {
  padding-right: 0.5rem; }

#input-kyotaku-plan2-page .border-black {
  border: 1px solid;
  background-color: #fff; }

#input-kyotaku-plan2-page .mb-1 {
  margin-bottom: 1.5rem; }

#input-kyotaku-plan2-page .p-1 {
  padding: 0.5rem; }

#input-kyotaku-plan2-page .border-bottom {
  border-bottom: 1px solid; }

#input-kyotaku-plan2-page .header-table-tab2 {
  background-color: #d3d0d0;
  border-bottom: 1px solid;
  padding: 1%;
  margin-bottom: 0.5rem;
  width: 100%; }

#input-kyotaku-plan2-page .flex-end {
  display: flex;
  justify-content: flex-end; }

#input-kyotaku-plan2-page .w-full {
  width: 100%; }

#input-kyotaku-plan2-page .p-1 {
  padding: 0.5rem; }

#input-kyotaku-plan2-page .pointer {
  cursor: pointer; }

#input-kyotaku-plan2-page .hoverable-element {
  cursor: pointer;
  caret-color: transparent; }

#input-kyotaku-plan2-page .btn-tab-delete,
#input-kyotaku-plan2-page .btn-tab-delete:active,
#input-kyotaku-plan2-page .btn-tab-delete:focus {
  background-color: gray;
  border-color: gray; }

#input-kyotaku-plan2-page .btn-tab-delete:hover {
  background-color: darkgray;
  border-color: darkgray; }

#input-kyotaku-plan2-page .white {
  color: white; }

@media (max-width: 575px) {
  #input-kyotaku-plan2-page .pljp-form-item-label {
    padding-right: 10px;
    padding-bottom: 0; }
  #input-kyotaku-plan2-page .pljp-form-item-label,
  #input-kyotaku-plan2-page .pljp-form-item-control-wrapper {
    width: initial; } }
