.monitoring-evaluation {
  display: flex;
  width: 100%;
  &__text {
    display: flex !important;
    flex-direction: column;
    height: 84px;
    .text-bold {
      white-space: nowrap;
    }
  }
  &__button {
    height: 100px;
  }
  @media (max-width: 767px) {
    &__text {
      height: unset;
      flex-direction: inherit;
    }
    &__button {
      height: unset;
      display: flex !important;
      justify-content: space-between;
      button {
        width: auto !important;
      }
    }
  }
}
