.input-kadai-seiri {
    width: 760px;
    margin: 0px auto;
    padding: 20px 15px 350px 15px;
    background-color: #FFFDE7;

    .input-content-kadai textarea{
        border-top: 1px solid #cccccc;
    }
    .ikou-text{
        border: 1px solid #cccccc !important;
        border-top: none;
        border-bottom: none !important;
        background-color: #fafafa;
    }
    .header-kadai{
        border: 1px solid #cccccc !important;
        border-top: none;
        border-bottom: none !important; 
        background-color: #d8e4bc;
    }
    .group-button-seiri{
        border-top: 1px solid #cccccc;
        background-color: #ddd9c4;
    }
    .pljp-col {
        // border: 1px solid #cccccc;
    }

    .group-item-kadai {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .button-term {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .name-header {
        margin-bottom: 10px;
        margin-left: 10px;
        margin-top: 10px;
    }

    .add-component {
        margin-bottom: 10px;
        margin-top: 10px;
        margin-left: 270px;
        background-color: darkred;
        border-color: darkred;
        color: white;
    }

    .add-component:hover {
        background-color: rgb(99, 1, 1);
        border-radius: 5px;
        color: white;
        border-color: rgb(99, 1, 1);
    }

    .add-component:focus {
        background-color: rgb(99, 1, 1);
        border-radius: 5px;
        color: white;
        border-color: rgb(99, 1, 1);
    }

    .name-header-outlook {
        padding-left: 160px;
        background-color: #d8e4bc;
    }

    .location {
        background-color: #676767;
        border-color: #676767;
        color: white;
    }

    .location:hover {
        background-color: #818080;
        border-radius: 5px;
        color: white;
        border-color: #818080;
    }

    .location:focus {
        background-color: #818080;
        border-radius: 5px;
        color: white;
        border-color: #818080;
    }

    .location:disabled {
        background-color: #676767;
        border-color: #676767;
        color: white;
    }

    .location:disabled:hover {
        background-color: #818080;
        border-radius: 5px;
        color: white;
        border-color: #818080;
    }

    .location:disabled:focus {
        background-color: #818080;
        border-radius: 5px;
        color: white;
        border-color: #818080;
    }
    .content-kadai{
        background-color: #fafafa;
    }
}

.Issue-Summary{
    @media screen and (min-width: 1025px) {
        .button-comment {
            margin-left: 150px;
        }
        .task-kadai-button{
            margin-right: 2vw;
        }
    }
    @media screen and (min-width: 1800px) {
        .task-kadai-button{
            margin-left: 3%;
        }
    }

    @media screen and (min-width: 770px) and (max-width: 1024.9px) {
        .button-comment {
            margin-left: 55px;
        }
        .Issue-Summary__controll1{
            .cal-input{
                margin-top: 5px;
            }
            .name-cal-input{

                width: 100%;
                margin-top: 10px;
            }
        }
    }

    @media screen and (max-width: 770px) {
        .button-comment {
            margin-left: 55px;
        }
        .group-tanto {
            margin-left: 20px;
            margin-top: 15px !important;
        }
        
        .Issue-Summary__header .Issue-Summary__controll1{
            margin: auto;
        }
        .Issue-Summary__header input{
            width: 120px;
            margin-top: 14px !important;
        }
        .cal-input{
            margin-top: -8px ;
        }
        .name-cal-input{
            width: 120px;
            margin-top: 35px;
        }
    }
}
.hidden-scroll-text {
    .pljp-radio-checked .pljp-radio-inner {
        border-color: red !important;
    }

    .pljp-radio-checked .pljp-radio-inner:after {
        background-color: red;
    }

    .pljp-radio:hover .pljp-radio-inner {
        border-color: red;
    }
    textarea {
        overflow: hidden;
    }
}

.name-content-kikan{
    font-family: KozGo !important
}
.text-left{
    text-align: left !important;
}
// @media screen and (max-width: 1800px) {
//     .text-header{
//         font-size: 12px;
//         margin-top: 2px;
//     }
// }
