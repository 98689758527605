
.content-daily {
  max-width: 740px;
  margin: 0px auto;
  padding: 20px 50px 15px 50px;
  background-color: #FFFDE7;

  .group-name-ryousya {
    border: 1px solid black;
    padding: 5px;
    height: 38px;
    text-align: center;
    background-color: #fff;
    .riyousya-name-details {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .group-button {
    padding-bottom: 10px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    padding-left: 150px;
    padding-right: 150px;
  }

  tr,
  td {
    border: 0.25px solid black;
  }

  .pljp-btn {
    background-color: #2687a4;
    color: white;
    border-radius: 5px;
    border-color: #2687a4;
    margin-bottom: 5px;
  }
  .button-save {
    margin-right: 40px;
  }
  .pljp-btn:hover {
    background-color: #58a3b9;
    border-radius: 5px;
    color: white;
    border-color: #2687a4;
  }
  .pljp-btn:focus{
    background-color: #2687a4;
    border-radius: 5px;
    color: white;
    border-color: #2687a4;
  }
  .nameTitleInput {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .pljp-table-thead {
    display: none;
  }

  .button-override {
    position: relative;
    button {
      position: absolute;
      right: 20px;
      padding: 5px 10px;
      z-index: 2;
    }
    textarea {
      position: relative;
      resize: none;
      outline: none;
      padding-right: 50px;
    }
  }
  .table_daily_life {
    border-bottom: 0.5px solid black;
    .pljp-table-tbody > tr > td {
      background-color: #fff;
    }
  }
  .pljp-input {
    height: 20px;
    border: 0.5px solid black;
  }
  .pljp-table-tbody > tr.pljp-table-row-hover:not(.pljp-table-expanded-row):not(.pljp-table-row-selected) > td {
    background-color: white !important;
  }
  .pljp-table-tbody > tr > td {
    border-right: 1px solid #e8e8e8;
  }
  .table_daily_life {
    border-right: 0.25px solid black;
  }
}
.time-record{
  text-align: center;
  background-color: #fff;
}
.ryaku-content{
  white-space: nowrap;
  text-overflow: ellipsis; 
  overflow: hidden;
  width: 570px;
}
  .group-button-save-back {
    float: right;
  }
  .menu-header-web {
    position: sticky;
    background-color: #212121d9;
    color: @white;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .sm-c {
      display: flex;
      justify-content: space-between;
      .sm-c-left {
        h3 {
          color: @white;
        }
        &__name {
          align-items: baseline;
          .title {
            margin-right: 10px;
          }
          display: flex;
          div:last-child {
            font-size: 1.2em;
            font-weight: bold;
          }
        }
      }
      .sm-c-right {
        display: flex;
        .link-item1 {
          color: @white;
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .link-item2 {
          color: @white;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .button-callback {
          background-color: transparent;
          border: none;
          display: flex;
          &:focus {
            outline: none;
          }
          &:hover {
            cursor: pointer;
          }
        }
        p {
          margin-top: 5px;
          color: @white;
        }
      }
    }
    .group-input-tab {
      display: flex;
    }
    .tabinput-content {
      margin-left: 0px;
      margin-right: 50px;
    }
    .group-button-view {
      display: flex;
      .group-2-button{
        display: flex;
      }
      .item-button {
        //   margin: 20px auto 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-line-pack: end;
        align-content: flex-end;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        .button-content {
          border-radius: 3px;
          margin-right: 5px;
          margin-bottom: 5px;
          padding: 0.25em 0.5em;
          display: inline-block;
          color: #000;
          background: burlywood;
          font-size: 1em;
          cursor: pointer;
        } 
          .button-content:hover {
            background-color: #c9af8e;
            border-radius: 5px;
            color: #000000;
            border-color: #000000;
          }
          .button-content:focus{
            background-color: #c9af8e;
            border-radius: 5px;
            color: #ccc;
            border-color: #000000;
          }

          .active-button{
            background-color: coral !important;
            border-radius: 5px !important;
            color: white !important;
            border-color: #000000;
          }

      }
    }
  }


@media screen and (max-width:1023px) {
  .group-input-tab {
    position: relative;
    .tabinput-content {
      width: 400px !important;
      margin-right: 20px;
    }

    .group-button-view {
      display: block !important;
      position: absolute;
      bottom: 0px;
      left: 490px;
    
      .group-2-button {
        display: flex;
      }
    }
  }
}
@media screen and (min-width:1023.1px) and (max-width:1365px) {
  .group-input-tab {
    position: relative;
    .tabinput-content {
      margin-right: 20px;
    }

    .group-button-view {
      display: flex;
      position: absolute;
      bottom: 0px;
      left: 630px;
      margin-left: 25px;
      .group-2-button {
        display: flex;
      }
    }
  }
}