#input-monitoring-page .back {
  max-width: 100%;
  max-height: 100%;
  background-color: #FFF9C4; }

#input-monitoring-page .main {
  background-color: #FFFDE7;
  padding: 20px 15px 500px 15px; }

#input-monitoring-page .fixed-footer {
  position: sticky;
  bottom: 0;
  z-index: 2; }

#input-monitoring-page .text-label {
  padding: 8px;
  border: 1px solid;
  min-height: 100px;
  line-height: 1.3;
  white-space: pre-line;
  word-break: break-all;
  background-color: #fff; }

#input-monitoring-page .modal-add-coppy .pljp-modal-body {
  padding: 0px; }

#input-monitoring-page .modal-add-coppy .pljp-modal-footer {
  padding: 10px 16px;
  text-align: right;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  background-color: lightgray; }

#input-monitoring-page .fixed-header {
  position: sticky;
  top: 0;
  z-index: 2; }

#input-monitoring-page .kaigo-button {
  float: right;
  margin-bottom: -32px;
  z-index: 1; }

#input-monitoring-page .edit-button {
  float: right;
  z-index: 1; }

#input-monitoring-page .document-table-record {
  padding: 16px 8px;
  cursor: pointer;
  background-color: #fff; }
  #input-monitoring-page .document-table-record:hover {
    background-color: #dbe6e6; }

#input-monitoring-page .document-table-record-active {
  padding: 16px;
  cursor: pointer;
  background-color: lightgray; }

#input-monitoring-page .text-bold {
  font-weight: bold; }

#input-monitoring-page .header-table-tab4 {
  background-color: #d3d0d0;
  margin-bottom: 0.5rem;
  width: 60%; }

#input-monitoring-page .header-table-tab42 {
  background-color: #d3d0d0;
  width: 100%; }

#input-monitoring-page .w-full {
  width: 100%; }

#input-monitoring-page .border-black {
  border: 1px solid; }

#input-monitoring-page .mb-1 {
  margin-bottom: 0.5rem; }

@media (max-width: 575px) {
  #input-monitoring-page .pljp-form-inline .pljp-form-item > .pljp-form-item-control-wrapper,
  #input-monitoring-page .pljp-form-inline .pljp-form-item > .pljp-form-item-label {
    vertical-align: middle; }
  #input-monitoring-page .pljp-form-item-label {
    padding-right: 10px;
    padding-bottom: 0; }
  #input-monitoring-page .pljp-form-item-label, #input-monitoring-page .pljp-form-item-control-wrapper {
    width: initial; }
  #input-monitoring-page .monitoring-comment {
    width: 100%;
    margin-right: 0; }
    #input-monitoring-page .monitoring-comment .pljp-form-item-label,
    #input-monitoring-page .monitoring-comment .pljp-form-item-control-wrapper {
      width: 100%; } }

#input-monitoring-page #table-monitoring .short-term {
  line-height: 1.5;
  margin-bottom: 15px; }

#input-monitoring-page #table-monitoring .result {
  padding: 0.5rem; }
  #input-monitoring-page #table-monitoring .result__title {
    padding: 3px;
    background-color: #d3d0d0;
    border-bottom: 1px solid;
    line-height: 2;
    margin-bottom: 10px; }
  #input-monitoring-page #table-monitoring .result__input {
    margin-bottom: 10px;
    line-height: 1.5; }
    #input-monitoring-page #table-monitoring .result__input:last-child {
      margin-bottom: 0; }
  #input-monitoring-page #table-monitoring .result table {
    table-layout: fixed;
    width: 100%; }
    #input-monitoring-page #table-monitoring .result table td {
      background-color: unset !important; }

#input-monitoring-page #table-monitoring .evaluation {
  line-height: 1.5; }
  #input-monitoring-page #table-monitoring .evaluation__buttons {
    line-height: 1.5;
    margin-top: 5px;
    display: flex;
    justify-content: space-between; }

#input-monitoring-page #table-monitoring .monitoring-hyoka-title {
  line-height: 40px; }

#input-monitoring-page #table-monitoring .monitoring-hyoka-info {
  border: 1px solid #d3d0d0;
  border-radius: 4px;
  margin-bottom: 10px; }
  #input-monitoring-page #table-monitoring .monitoring-hyoka-info__title {
    border-radius: 4px 4px 0 0;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 10px; }
  #input-monitoring-page #table-monitoring .monitoring-hyoka-info__title, #input-monitoring-page #table-monitoring .monitoring-hyoka-info__content {
    padding-left: 10px;
    padding-right: 10px; }
  #input-monitoring-page #table-monitoring .monitoring-hyoka-info__content {
    margin-bottom: 10px; }
    #input-monitoring-page #table-monitoring .monitoring-hyoka-info__content .pljp-form-item {
      margin-bottom: 0; }

#input-monitoring-page .table-monitoring-m .header-table-tab42 {
  background-color: unset;
  width: unset; }

#input-monitoring-page .table-monitoring-m .level {
  justify-content: flex-start;
  align-items: flex-end; }
  #input-monitoring-page .table-monitoring-m .level .header-table-tab4 {
    background-color: unset;
    width: unset;
    margin-right: 10px;
    margin-bottom: 5px; }
  #input-monitoring-page .table-monitoring-m .level .pljp-select {
    width: 200px !important; }

#input-monitoring-page .table-monitoring-m .short-term-goal label {
  position: absolute;
  bottom: 0; }

#input-monitoring-page .table-monitoring-m .short-term-goal button {
  bottom: 3px; }

#input-monitoring-page .table-monitoring-m .short-term {
  margin-bottom: 10px; }

#input-monitoring-page .table-monitoring-m .evaluation-buttons {
  margin-bottom: 30px; }
  #input-monitoring-page .table-monitoring-m .evaluation-buttons .pljp-col {
    display: flex;
    justify-content: space-between; }

#input-monitoring-page .table-monitoring-m .results {
  margin-top: 10px; }

#input-monitoring-page .table-monitoring-m .result {
  border: 1px solid #d3d0d0;
  border-radius: 4px;
  margin: 0 0 10px 0 !important; }
  #input-monitoring-page .table-monitoring-m .result__title {
    margin: -10px -10px 10px -10px;
    background: #d3d0d0;
    padding: 5px 10px !important;
    border-radius: 4px 4px 0 0;
    border-bottom: 0 !important; }
  #input-monitoring-page .table-monitoring-m .result__input {
    margin-bottom: 10px !important; }
  #input-monitoring-page .table-monitoring-m .result table {
    width: 100%; }
    #input-monitoring-page .table-monitoring-m .result table tr {
      height: 40px; }
      #input-monitoring-page .table-monitoring-m .result table tr td {
        background-color: unset !important; }
      #input-monitoring-page .table-monitoring-m .result table tr td:last-child .pljp-select {
        width: 170px !important; }

#input-monitoring-page .table-monitoring-m .monitoring-hyoka-title {
  margin-bottom: 3px;
  line-height: 1; }
  #input-monitoring-page .table-monitoring-m .monitoring-hyoka-title label {
    line-height: 1;
    position: absolute;
    bottom: 0; }

#input-monitoring-page .table-monitoring-m .monitoring-hyoka-info {
  border: 1px solid #d3d0d0;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px; }
  #input-monitoring-page .table-monitoring-m .monitoring-hyoka-info__title {
    margin: -10px -10px 10px -10px;
    background: #d3d0d0;
    padding: 5px 10px;
    border-radius: 4px 4px 0 0; }
  #input-monitoring-page .table-monitoring-m .monitoring-hyoka-info__content {
    padding-left: 0 !important;
    padding-right: 0 !important; }
    #input-monitoring-page .table-monitoring-m .monitoring-hyoka-info__content:last-child {
      margin-bottom: 0 !important; }
    #input-monitoring-page .table-monitoring-m .monitoring-hyoka-info__content .pljp-col {
      width: 100%; }
    #input-monitoring-page .table-monitoring-m .monitoring-hyoka-info__content .pljp-form-item {
      margin-right: 0; }
  #input-monitoring-page .table-monitoring-m .monitoring-hyoka-info__date {
    margin-bottom: 10px; }
    #input-monitoring-page .table-monitoring-m .monitoring-hyoka-info__date .pljp-form-item {
      justify-content: flex-start;
      align-items: center; }
      #input-monitoring-page .table-monitoring-m .monitoring-hyoka-info__date .pljp-form-item .pljp-col {
        width: unset; }
  #input-monitoring-page .table-monitoring-m .monitoring-hyoka-info__evaluator .pljp-form-item {
    flex-direction: column; }
  #input-monitoring-page .table-monitoring-m .monitoring-hyoka-info hr {
    display: none; }

#input-monitoring-page .pljp-table-tbody > tr > td {
  padding: 0; }

.modal-add-coppy-m .pljp-modal-footer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .modal-add-coppy-m .pljp-modal-footer button {
    margin-bottom: 5px; }

.progress-record-modal table {
  height: 1px; }

.progress-record-modal tr, .progress-record-modal td {
  height: 100%; }

.progress-record-modal tr:hover td {
  background-color: unset !important; }

.progress-record-modal td {
  padding: 0 !important; }

.progress-record-modal__td {
  white-space: pre-line;
  word-break: break-all;
  height: 100%;
  padding: 8px;
  transition: all 0.35s; }
  .progress-record-modal__td:hover {
    background-color: rgba(186, 207, 205, 0.3) !important; }
  .progress-record-modal__td--selected {
    background-color: #bacfcd !important; }

.progress-record-modal .pljp-modal-body {
  height: calc(100vh - 250px);
  overflow-y: auto; }
