#report-case-page .pljp-layout-header .pljp-row {
  display: flex; }

#report-case-page .pljp-layout-content > .pljp-row {
  display: block; }

#report-case-page table {
  border-collapse: collapse; }

#report-case-page .report-case-table-row {
  padding: 10px;
  background-color: #d6f1ee; }

#report-case-page .report-case-table-row-active {
  padding: 10px;
  background-color: #bacfcd; }

#report-case-page .report-case-table-row:hover {
  background-color: #bacfcd; }

#report-case-page .pljp-table-row td {
  border: 1px solid gray !important;
  border-bottom-color: gray !important; }

#report-case-page .pljp-table-tbody > tr > td {
  padding: 0px 0px; }

#report-case-page #filter input {
  width: calc(100% - 150px); }

#report-case-page .pljp-form-item-label label {
  font-weight: bolder;
  font-size: 1.1em;
  color: rgba(0, 0, 0, 0.65); }

#report-case-page .pljp-col-24 .pljp-form-item {
  width: 100%; }

#report-case-page .pljp-col-24 .pljp-form-item .pljp-form-item-control-wrapper {
  width: calc(100% - 71.6px) !important; }

#report-case-page .rc-input-form .pljp-legacy-form-item {
  width: 100%;
  display: flex; }
