.mobile-header {
  background-color: #0d8760;
  padding: 6px;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  // max-height: 56px;

  &__left {
    display: flex;
    align-items: flex-end;
  }

  &__center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    .sy {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 2px;
    }
    .room {
      font-size: 13px;
    }
  }

  &__right {
    float: right;
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__logo {
    width: 120px;
    margin-right: 5px;
  }

  &__logo-title {
    margin: 0;
    color: @white;
  }

  &__nickname {
    margin: 0;
    color: @white;
  }

  &__avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding-right: 5px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: flex;
    }
    .tanto-name {
      font-size: 10px;
    }
  }

  &__menu {
    border: 1px solid @white;
  }
}
.active-menu {
  background-color: #c3d0d6;
}

// ipad
@media screen and (min-width: 768px) {
  .mobile-header {
    justify-content: space-between;

    &__center {
      margin: auto;
    }

    &__right {
      margin-left: 0;
    }
  }
}
