.menu-header-mobile {
  background-color: #212121d9;
  color: #ffffff;
  padding: 8px 16px;
  .sm-c-left {
    &__name {
      display: flex;
      p {
        margin-right: 5px;
      }
    }
  }
}
.menu-footer-mobile {
  position: fixed;
  background-color: #212121d9;
  color: @white;
  height: 45px;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  width: 100%;
  .sm-c-right {
    display: flex;
    .item-link {
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: @white;
      transition: 0.3s all;
      padding: 2px 8px;
      margin-top: 5px;
    }
  }
}
.button-callback-view {
  background-color: transparent;
  border: none;
  display: flex;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}
