.show-header-table {
  border: 1px solid #cccccc;
  .pljp-col {
    border-right: 1px solid #cccccc;
  }
  .pljp-col:last-child {
    border-right: none;
  }
}

.management-plan2 {
  padding: 0 !important;
  .data-table-plan2 {
    display: flex;
    &:hover {
      background-color: #dbe6e6;
    }
    .row-plan-2 {
      max-height: unset !important;
      padding: 0.5rem;
      border-right: 1px solid #cccccc;
      min-height: 40px;
      // background-color: #fff;
    }
    .row-plan-2:last-child {
      padding-left: 10px;
      border-right: none;
    }
  }
}
.management-footer-table {
  padding: 0 !important;
  margin-top: 0 !important;
  .pljp-col {
    padding: 0.5rem;
    border-right: 1px solid #cccccc;
    min-height: 50px;
  }
  .pljp-col:last-child {
    border-right: none;
  }
  @media (max-width: 737px) {
    position: relative;;
    .pljp-row {
      display: flex;
      .pljp-col {
        border-right: none;
      }
      .pljp-col:nth-child(3) {
        display: none;
      }
      .pljp-col:last-child {
        position: absolute;
        right: 0;
      }
    }
  }
}

.pljp-select-disabled-custom {
  color: rgba(0, 0, 0, 0.65) !important;
}

td:empty::after{
  content: "\00a0";
}
