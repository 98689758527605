#in-out-status-page .filterInOut {
  color: black;
  font-size: smaller;
  position: absolute;
  top: 8px;
  left: 390px;
  z-index: 1;
  background-color: white;
  line-height: 1.5em;
  width: 75px; }
  @media screen and (max-width: 768px) {
    #in-out-status-page .filterInOut {
      left: 90px; } }

#in-out-status-page .menu-inout-footer {
  position: sticky;
  bottom: 0;
  z-index: 2; }

#in-out-status-page .flex-end {
  display: flex;
  justify-content: flex-end; }

#in-out-status-page .flex-start {
  display: flex;
  justify-content: flex-start; }

#in-out-status-page .table-kiroku-date {
  padding: 0 15px; }
  #in-out-status-page .table-kiroku-date .pljp-table-thead > tr > th {
    text-align: left; }
    #in-out-status-page .table-kiroku-date .pljp-table-thead > tr > th.joutaiCol {
      padding-left: 8px !important; }
  #in-out-status-page .table-kiroku-date td.joutaiCol {
    padding: 0px !important; }
  #in-out-status-page .table-kiroku-date .ntk-riyousya-colum > span:not(.ntl-riyousya-gender) {
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 115px;
    white-space: nowrap; }
  #in-out-status-page .table-kiroku-date .nuytaisho-yellow {
    display: inline-block;
    width: 100%;
    padding: 8px;
    text-align: center;
    background: yellow; }
  #in-out-status-page .table-kiroku-date .nuytaisho-white {
    display: inline-block;
    width: 100%;
    padding: 8px;
    text-align: center; }
  #in-out-status-page .table-kiroku-date .pljp-table-tbody tr {
    cursor: pointer; }
  #in-out-status-page .table-kiroku-date .nuytaisho-blue {
    display: inline-block;
    width: 100%;
    padding: 8px;
    text-align: center;
    background: #89e1fd; }
  #in-out-status-page .table-kiroku-date .pljp-table-expanded-row > td > p {
    padding: 4px 0px;
    text-align: center; }
  #in-out-status-page .table-kiroku-date .pljp-table-small > .pljp-table-content > .pljp-table-scroll > .pljp-table-body > table > .pljp-table-tbody > .pljp-table-expanded-row > td {
    padding: 0px; }
  #in-out-status-page .table-kiroku-date .pljp-table-scroll {
    overflow-x: auto; }
  #in-out-status-page .table-kiroku-date .pljp-table-body {
    min-width: 1400px; }

#in-out-status-page .btn-remove {
  background-color: gray; }

#in-out-status-page .btn-group-first {
  padding-left: 10px;
  padding-bottom: 4px; }

#in-out-status-page .btn-group-second {
  padding-left: 220px;
  padding-bottom: 4px; }

@media screen and (max-width: 1200px) {
  #in-out-status-page .but-left {
    justify-content: space-between;
    padding-right: 15px;
    margin-bottom: 10px; }
    #in-out-status-page .but-left > div:not(.wrap-button) {
      max-width: 350px;
      width: 100%; } }

#in-out-status-page .but-left .wrap-button {
  display: flex; }
  #in-out-status-page .but-left .wrap-button > div {
    display: inline-block; }

#in-out-status-page .but-left .pljp-calendar-picker {
  width: 100% !important; }

#in-out-status-page .but-left .datepicker {
  display: flex; }
  #in-out-status-page .but-left .datepicker > button {
    width: 45px; }

#in-out-status-page .but-right > div {
  align-items: center; }

#in-out-status-page .but-right .filter {
  width: 55%; }
  #in-out-status-page .but-right .filter > div {
    width: 100%; }
  #in-out-status-page .but-right .filter input {
    width: 100% !important; }

#in-out-status-page .but-right .search-riyousya input {
  max-width: 150px;
  width: 100%; }

@media screen and (max-width: 1200px) {
  #in-out-status-page .but-right .pljp-col-md-20 {
    justify-content: flex-start; } }

@media screen and (max-width: 767px) {
  #in-out-status-page .but-right {
    padding-right: 15px; }
    #in-out-status-page .but-right .pljp-col-md-20 {
      justify-content: flex-start;
      flex-wrap: wrap; }
    #in-out-status-page .but-right .filter {
      width: calc(100% - 82px);
      margin-bottom: 10px; }
      #in-out-status-page .but-right .filter > div {
        width: 100%; }
      #in-out-status-page .but-right .filter input {
        max-width: none; }
    #in-out-status-page .but-right .search-riyousya {
      width: 100%;
      padding-left: 5px; }
      #in-out-status-page .but-right .search-riyousya input {
        max-width: 100% !important;
        width: 100% !important; } }

#in-out-status-page .io-multi-button {
  align-items: center; }
  #in-out-status-page .io-multi-button .flex > div {
    display: flex !important; }
  #in-out-status-page .io-multi-button .pljp-col-xl-8 > div:not(.wrap-button) {
    display: flex; }
    #in-out-status-page .io-multi-button .pljp-col-xl-8 > div:not(.wrap-button) button {
      flex: 0 0 32px; }

.notif {
  color: red;
  font-size: 12px;
  display: block;
  padding-left: 85px;
  padding-left: 85px;
  margin-bottom: 10px; }

.user-in-out-form .header-form .pljp-legacy-form-item-children {
  display: inline-block;
  height: 33px;
  border-bottom: 1px solid;
  text-overflow: ellipsis;
  max-width: 129px;
  white-space: nowrap;
  overflow: hidden; }

.user-in-out-form .pljp-form-item-label {
  min-width: 85px; }

.user-in-out-form .pljp-form-item-control-wrapper input {
  height: auto; }

.user-in-out-form .pljp-form-item-control-wrapper button {
  margin-left: 5px; }

.user-in-out-form .flex-content .pljp-form-item {
  margin-right: 0;
  width: 100%; }
  .user-in-out-form .flex-content .pljp-form-item .pljp-form-item-control-wrapper {
    width: calc(100% - 85px); }
  .user-in-out-form .flex-content .pljp-form-item .pljp-legacy-form-item-children {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.user-in-out-form-add .pljp-legacy-form-item-children {
  display: flex;
  justify-content: space-between; }

.user-in-out-form-add .pljp-legacy-form .pljp-legacy-form-item {
  margin-bottom: 4px; }

.dp-footer .pljp-calendar-footer-btn {
  display: flex;
  justify-content: space-between; }

.dis-text-long span {
  white-space: pre-wrap; }

.in-out-status-m-c .pljp-legacy-form-item-children {
  display: flex;
  justify-content: space-between; }

.in-out-status-m-c .pljp-legacy-form-inline .pljp-legacy-form-item {
  display: flex;
  justify-content: space-between; }

.in-out-status-m-c .pljp-btn {
  margin-left: 8px; }
