.modal-add-coppy .pljp-modal-body {
  padding: 0px; }

.modal-add-coppy .pljp-modal-footer {
  padding: 10px 16px;
  text-align: right;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  background-color: lightgray; }

#document-list-page .menu-document-footer {
  position: sticky;
  bottom: 0;
  z-index: 2; }

#document-list-page .nameRiyousya {
  font-size: 1.5em; }

#document-list-page .titleRecord {
  font-size: inherit; }

#document-list-page .kubunRiyousya {
  position: absolute;
  right: 0px; }

#document-list-page .float-content {
  float: right; }

#document-list-page .pljp-modal-footer {
  padding: 10px 16px;
  background-color: lightgray;
  text-align: right;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px; }

#document-list-page .pointer-row {
  cursor: pointer; }

#document-list-page .toolbar1-document {
  margin: 0px auto;
  max-width: 90%; }

#document-list-page .toolbar-sort {
  float: right; }

#document-list-page .pljp-table-thead > tr > th,
#document-list-page .pljp-table-tbody > tr > td {
  padding: 0;
  border: 1px solid gray;
  border-top-width: 0;
  background: #d6f1ee; }

#document-list-page .pljp-table-thead > tr:first-child > th,
#document-list-page .pljp-table-tbody > tr:first-child > td {
  border-top-width: 1px; }

#document-list-page .document-table-record:hover {
  background-color: lightgray; }

#document-list-page .document-table-record {
  padding: 16px;
  cursor: pointer;
  background-color: white; }

#document-list-page .document-table-record-active {
  padding: 16px;
  cursor: pointer;
  background-color: lightgray; }

#document-list-page .document-table {
  margin: 0px auto;
  max-width: 1180px;
  padding: 0 10px; }

#document-list-page .pljp-tabs-bar {
  margin: 0 0 0 0; }

#document-list-page .btn-crud,
#document-list-page .btn-crud:hover,
#document-list-page .btn-crud:focus {
  margin: 5px 2px;
  color: white; }

#document-list-page .btn-tab-delete,
#document-list-page .btn-tab-delete:active,
#document-list-page .btn-tab-delete:focus {
  background-color: gray;
  border-color: gray; }

#document-list-page .btn-tab-delete:hover {
  background-color: darkgray;
  border-color: darkgray; }

#document-list-page .btn-tab-edit,
#document-list-page .btn-tab-edit:active,
#document-list-page .btn-tab-edit:focus {
  background-color: darkred;
  border-color: darkred; }

#document-list-page .btn-tab-edit:hover {
  background-color: red;
  border-color: red; }

#document-list-page .btn-tab-create,
#document-list-page .btn-tab-create:active,
#document-list-page .btn-tab-create:focus {
  background-color: rebeccapurple;
  border-color: rebeccapurple; }

#document-list-page .btn-tab-create:hover {
  background-color: mediumpurple;
  border-color: mediumpurple; }

#document-list-page .btn-tab-info,
#document-list-page .btn-tab-info:active,
#document-list-page .btn-tab-info:focus {
  background-color: #00838f;
  border-color: #00838f; }

#document-list-page .btn-tab-info:hover {
  background-color: #00bcd4;
  border-color: #00838f; }

#document-list-page .btn-right {
  float: right; }

#document-list-page .pljp-tabs .table-document-list .pljp-table-placeholder {
  border-right: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8; }

#document-list-page .pljp-tabs.pljp-tabs-card .pljp-tabs-card-bar .pljp-tabs-tab-active {
  height: 50px;
  color: white;
  background: #fff;
  border-color: #e8e8e8;
  border-bottom: 1px solid #fff;
  background: gray; }
  #document-list-page .pljp-tabs.pljp-tabs-card .pljp-tabs-card-bar .pljp-tabs-tab-active .pljp-tabs-tab-btn {
    color: #fff !important; }

#document-list-page .d-flex {
  display: flex; }

#document-list-page .flex-end {
  display: flex;
  justify-content: flex-end; }

#document-list-page .mr-2 {
  margin-right: 1rem; }

#document-list-page .pljp-calendar-picker-input.pljp-input {
  text-shadow: 0 0 0 gray;
  color: transparent !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }
  #document-list-page .pljp-calendar-picker-input.pljp-input:focus {
    outline: none; }

#last-quote-table .pljp-table-thead > tr > th {
  text-align: left; }

#last-quote-table .pljp-table-body {
  overflow-x: auto; }

#last-quote-table .pointer-row {
  cursor: pointer; }

#care-term-table .pljp-table-body {
  overflow-x: auto; }

#care-term-table .pointer-row {
  cursor: pointer; }

#table-kyotaku-plan2 .pljp-table-tbody > tr > td {
  padding: 0 !important; }

#table-kyotaku-plan2 .pointer-row {
  cursor: pointer; }

#riyou-kaigo .pointer-row {
  cursor: pointer; }

#plan-date-top {
  padding: 20px 30px; }
  #plan-date-top .label {
    background-color: #b7dee8;
    display: inline-block;
    font-weight: 600; }
    @media screen and (max-width: 768px) {
      #plan-date-top .label {
        min-width: 110px;
        background-color: transparent; } }
  #plan-date-top .label-date {
    margin-left: 20px;
    border-bottom: 2px solid #000;
    max-width: 200px;
    width: 100%;
    display: inline-block;
    height: 20px;
    vertical-align: bottom; }
  #plan-date-top .pljp-col {
    margin-bottom: 10px;
    display: flex; }

#plan-table {
  padding: 0 30px; }
  #plan-table .kikan {
    display: block; }
  #plan-table td {
    vertical-align: top; }
    #plan-table td ul {
      margin: -5px; }
      #plan-table td ul > .item {
        padding: 5px;
        overflow-y: auto;
        display: block; }
        #plan-table td ul > .item:not(:last-child) {
          border-bottom: 2px solid gray; }
    #plan-table td > p {
      margin-bottom: 0;
      overflow-y: auto; }
  #plan-table .pljp-table-thead > tr > th,
  #plan-table .pljp-table-tbody > tr > td {
    background-color: transparent;
    padding: 5px; }
  #plan-table .pljp-table-thead > tr > th {
    background-color: #b7dee8;
    text-align: left;
    font-weight: 600; }

#plan-date-bottom {
  padding: 20px 30px; }
  #plan-date-bottom .label {
    background-color: #b7dee8;
    font-weight: 600; }
    @media screen and (max-width: 768px) {
      #plan-date-bottom .label {
        background-color: transparent; } }
  #plan-date-bottom .label-date {
    margin-left: 20px;
    border-bottom: 2px solid #000;
    max-width: 200px;
    width: 100%;
    display: inline-block;
    height: 20px;
    vertical-align: bottom; }
  #plan-date-bottom .pljp-col {
    display: flex;
    margin-bottom: 10px; }
    #plan-date-bottom .pljp-col:last-child .label-date {
      width: 100%;
      max-width: none; }
    @media screen and (max-width: 768px) {
      #plan-date-bottom .pljp-col:last-child {
        flex-direction: column; }
        #plan-date-bottom .pljp-col:last-child .label-date {
          margin-left: 0;
          margin-top: 20px; } }
    #plan-date-bottom .pljp-col span:first-child {
      min-width: 80px; }

.plan-mobile {
  padding: 10px 30px; }
  .plan-mobile .pljp-collapse,
  .plan-mobile .pljp-collapse-item,
  .plan-mobile .pljp-collapse-content {
    border: none; }
  .plan-mobile .pljp-collapse {
    background-color: #d9d9d9; }
  .plan-mobile .pljp-typography {
    font-weight: 600; }
    .plan-mobile .pljp-typography span {
      font-weight: 400; }
  .plan-mobile .mobile-table {
    border: 1px solid grey; }
    .plan-mobile .mobile-table .pljp-col {
      padding: 5px; }
    .plan-mobile .mobile-table .item-detail {
      display: flex;
      clear: both; }
      .plan-mobile .mobile-table .item-detail .pljp-col {
        border-top: 1px solid grey;
        border-right: 1px solid grey; }

#time-component {
  margin-bottom: 1rem; }
  #time-component .flex-center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  #time-component .font-1 {
    font-size: 1.2rem; }
  #time-component .border {
    border: 1px solid #e5e5e5; }
  #time-component .title-time {
    padding: 5px;
    border-bottom: 1px solid #e5e5e5; }
  #time-component .content-time {
    padding: 5px;
    min-height: 71%; }
  #time-component .mb-1 {
    margin-bottom: 0.5rem; }
  #time-component .h-100 {
    height: 100%; }
  #time-component .radius-3 {
    border-radius: 3px; }

.date-picker-new-option .pljp-calendar-footer-btn {
  position: relative; }

.date-picker-new-option .pljp-calendar-today-btn {
  position: absolute;
  top: 0;
  right: 0; }

#input-kyotaku-dailyplan-page .back {
  max-width: 100%;
  max-height: 100%;
  background-color: #fff9c4; }

#input-kyotaku-dailyplan-page .main {
  background-color: #fffde7;
  padding: 20px 15px 15px 15px !important; }

#input-kyotaku-dailyplan-page .pljp-table-thead > tr > th .pljp-table-header-column {
  display: unset; }

#input-kyotaku-dailyplan-page .pljp-table-thead > tr > th,
#input-kyotaku-dailyplan-page .pljp-table-tbody > tr > td {
  border: 1px solid gray; }

#input-kyotaku-dailyplan-page .pljp-table-thead > tr > th {
  text-align: center;
  background-color: skyblue; }

#input-kyotaku-dailyplan-page .pljp-btn {
  background-color: #2687a4;
  color: white;
  border-radius: 5px;
  border-color: #2687a4; }

#input-kyotaku-dailyplan-page .pljp-btn:hover {
  background-color: #00bcd4;
  color: white;
  border-radius: 5px;
  border-color: #2687a4; }

#input-kyotaku-dailyplan-page .btn-popover {
  padding: 0px;
  border-radius: 50%;
  width: 32px;
  color: white;
  background-color: darkcyan;
  font-weight: bold; }

#input-kyotaku-dailyplan-page .btn-remove-popover {
  padding: 0px;
  border-radius: 50%;
  width: 32px;
  color: white;
  background-color: gray;
  font-weight: bold;
  margin-left: 10px; }

#input-kyotaku-dailyplan-page .pljp-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: unset;
  border-radius: 4px 4px 0 0; }

#input-kyotaku-dailyplan-page .btn-change {
  border: 1px solid;
  padding: 0 5px;
  border-bottom: none; }

#input-kyotaku-dailyplan-page .pljp-radio-button-wrapper-checked:not(.pljp-radio-button-wrapper-disabled) {
  color: white;
  background: gray;
  border: 1px solid black;
  border-bottom: none; }

#input-kyotaku-dailyplan-page .expand-dailyplan {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px; }

#input-kyotaku-dailyplan-page .checkInputYogo {
  color: red;
  border: 1px solid red;
  margin-bottom: 5px; }

#input-kyotaku-dailyplan-page .cancelBtn {
  border: 1px solid #c5bdbd;
  background: white;
  color: black; }

#input-kyotaku-dailyplan-page .cancelBtn:hover {
  border: 1px solid #00838f;
  background: white;
  color: #2687a4; }

#input-kyotaku-dailyplan-page .table-popover {
  zoom: 1;
  max-height: 250px;
  overflow: auto; }

#input-kyotaku-dailyplan-page .daily-table .pljp-table-tbody tr {
  background-color: #fff; }

.row-plan-2 {
  word-wrap: break-word;
  padding-right: 0.5rem;
  max-height: 100px;
  overflow: hidden; }

.table_daily_plan {
  height: 40px; }

.kaigo_modal {
  top: 200px !important; }

.tanto-kaigi-modal .pljp-modal-body {
  height: calc(100vh - 300px);
  overflow-y: auto; }

.tanto-kaigi-modal__td {
  white-space: pre-line;
  word-break: break-all;
  height: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.35s;
  padding: 8px; }
  .tanto-kaigi-modal__td:hover {
    background-color: #bacfcd !important; }
  .tanto-kaigi-modal__td--selected {
    background-color: #bacfcd; }

.tanto-kaigi-modal__comment {
  border-right: 1px solid #d9d9d9 !important;
  position: relative;
  vertical-align: initial; }
  .tanto-kaigi-modal__comment .tanto-kaigi-modal__td {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-right: 0; }

.tanto-kaigi-modal__evaluate {
  min-height: 20px;
  max-height: 70px; }

.tanto-kaigi-modal td {
  border-right: 0;
  cursor: pointer; }

.tanto-kaigi-modal tr {
  border: 1px solid #bacfcd; }
  .tanto-kaigi-modal tr th {
    border: 1px solid #bacfcd;
    border-right: 1px solid #d9d9d9; }
    .tanto-kaigi-modal tr th:last-child {
      border-right: 0; }
  .tanto-kaigi-modal tr:hover td {
    background-color: unset !important; }

.keika-kiroku-modal {
  width: 800px !important;
  max-width: calc(100% - 30px) !important; }
  .keika-kiroku-modal .keika-kiroku__item {
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding: 5px;
    margin-bottom: 5px; }
    .keika-kiroku-modal .keika-kiroku__item:hover {
      background-color: #bacfcd; }
    .keika-kiroku-modal .keika-kiroku__item .text-bold {
      font-weight: bold;
      width: 60px;
      display: inline-block; }
  .keika-kiroku-modal .keika-kiroku__value {
    display: inline-block;
    width: calc(100% - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.no-margin-bottom {
  margin-bottom: 0; }
  .no-margin-bottom .pljp-form-item-control {
    line-height: 1; }

.table-cell-wordbreak {
  word-break: break-all !important;
  white-space: pre-line !important; }

.pljp-select-dropdown-menu .pljp-select-dropdown-menu-item {
  white-space: normal !important;
  word-break: break-all !important; }

.staff-meeting-table table {
  table-layout: fixed !important; }
