#input-keika-kiroku-page .back {
  max-width: 100%;
  max-height: 100%;
  background-color: #FFF9C4; }

#input-keika-kiroku-page .main {
  background-color: #FFFDE7;
  padding: 20px 15px 380px 15px !important; }

#input-keika-kiroku-page .fixed-footer {
  position: sticky;
  bottom: 0;
  z-index: 2; }

#input-keika-kiroku-page .text-bold {
  font-weight: bold; }

#input-keika-kiroku-page .progress-record-button button {
  width: unset !important; }

#input-keika-kiroku-page .progress-record-buttons .text-bold {
  margin-right: 5px; }

#input-keika-kiroku-page .progress-record-buttons__control5 .pljp-calendar-picker .anticon-close-circle {
  display: flex !important; }

#input-keika-kiroku-page .progress-record-buttons__control2 .pljp-time-picker {
  width: 170px; }

#input-keika-kiroku-page .progress-record-buttons__control4, #input-keika-kiroku-page .progress-record-buttons__control7 {
  text-align: right; }

#input-keika-kiroku-page .progress-record-buttons__control6 .pljp-select:last-child, #input-keika-kiroku-page .progress-record-buttons__control7 .pljp-select:last-child {
  width: 205px !important; }

#input-keika-kiroku-page .progress-record-form {
  margin-top: 40px; }
  #input-keika-kiroku-page .progress-record-form p.text-bold {
    margin-bottom: 0; }
  #input-keika-kiroku-page .progress-record-form__naiyou textarea {
    height: 248px; }
  #input-keika-kiroku-page .progress-record-form__naiyou-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 5px; }
  #input-keika-kiroku-page .progress-record-form__handan textarea, #input-keika-kiroku-page .progress-record-form__kangae textarea {
    height: 100px; }
  #input-keika-kiroku-page .progress-record-form__handan-title, #input-keika-kiroku-page .progress-record-form__kangae-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 5px; }
  #input-keika-kiroku-page .progress-record-form__handan {
    margin-bottom: 10px; }

#input-keika-kiroku-page .progress-record-button-dialog {
  display: none;
  margin-top: 10px; }

@media (max-width: 992px) {
  #input-keika-kiroku-page .progress-record-button button {
    display: none; }
  #input-keika-kiroku-page .progress-record-buttons .text-bold {
    display: inline-block;
    width: 80px; }
  #input-keika-kiroku-page .progress-record-buttons .pljp-row {
    margin-bottom: 0 !important; }
  #input-keika-kiroku-page .progress-record-buttons .pljp-col {
    margin-bottom: 10px !important; }
  #input-keika-kiroku-page .progress-record-buttons__control2 .pljp-time-picker {
    width: unset; }
  #input-keika-kiroku-page .progress-record-buttons__control4, #input-keika-kiroku-page .progress-record-buttons__control7 {
    text-align: left; }
  #input-keika-kiroku-page .progress-record-buttons__control6 .pljp-select:last-child, #input-keika-kiroku-page .progress-record-buttons__control7 .pljp-select:last-child {
    width: 170px !important; }
  #input-keika-kiroku-page .progress-record-form__naiyou {
    margin-bottom: 10px; }
    #input-keika-kiroku-page .progress-record-form__naiyou textarea {
      height: 200px; }
  #input-keika-kiroku-page .progress-record-button-dialog {
    display: block; } }

@media (max-width: 575px) {
  #input-keika-kiroku-page .progress-record-buttons__control1 .pljp-calendar-picker,
  #input-keika-kiroku-page .progress-record-buttons__control2 .pljp-time-picker,
  #input-keika-kiroku-page .progress-record-buttons__control3 > div,
  #input-keika-kiroku-page .progress-record-buttons__control4 > div,
  #input-keika-kiroku-page .progress-record-buttons__control5 .pljp-calendar-picker,
  #input-keika-kiroku-page .progress-record-buttons__control6 > div,
  #input-keika-kiroku-page .progress-record-buttons__control7 > div {
    width: calc(100% - 90px) !important; }
  #input-keika-kiroku-page .progress-record-buttons__control3 .pljp-select,
  #input-keika-kiroku-page .progress-record-buttons__control4 .pljp-select {
    width: 100% !important; }
  #input-keika-kiroku-page .progress-record-buttons__control6 .pljp-select:first-child, #input-keika-kiroku-page .progress-record-buttons__control7 .pljp-select:first-child {
    width: 35% !important; }
  #input-keika-kiroku-page .progress-record-buttons__control6 .pljp-select:last-child, #input-keika-kiroku-page .progress-record-buttons__control7 .pljp-select:last-child {
    width: calc(65% - 5px) !important; }
  #input-keika-kiroku-page .progress-record-form {
    margin-top: 20px; }
    #input-keika-kiroku-page .progress-record-form__handan .text-bold {
      font-size: 13px; } }
