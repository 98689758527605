.document-tab-list-view {
  margin: 0 auto;
  max-width: 1080px;
  padding: 0 10px;

  .toolbar-document-list {
    padding: 10px 0px;
    position: relative;
    height: auto;
    margin-right: 0;
    margin-left: 0;
    zoom: 1;
    display: block;
    text-align: end;
  }

  .contents-document-list {
    .pljp-tabs-bar {
      margin-bottom: 0px !important;
    }

    .pljp-tabs-tab {
      height: 40px;
      margin: 0;
      margin-right: 2px;
      padding: 0 16px;
      line-height: 38px;
      background: #fafafa;
      border: 0.5px solid #e8e8e8;
      border-radius: 4px 4px 0 0;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .pljp-tabs-tab-active {
      height: 40px;
      border-color: #e8e8e8;
      border: 0.5px solid #3f3e3e;
      background: gray;

      .pljp-tabs-tab-btn{
        color: #fff !important  ;
      }

    }

    .pljp-tabs-bar {
      margin-top: 0px !important;
    }

    .tab-document-list {
      table,
      tr,
      td {
        text-align: left;
        border-radius: 4px 4px 0 0;
        border: 1px solid #c6c6c6;
        border-collapse: separate;
        border-spacing: 0;
        padding: 0;

        .btn-right {
          float: right;
        }

        .btn-crud {
          margin: 5px 2px;
          color: #fff;
        }

        .btn-tab-delete {
          background-color: gray;
          border-color: gray;
        }

        .btn-tab-edit {
          background-color: darkred;
          border-color: darkred;
        }
      }
      td {
        padding: 0px;
        .button-icon-group {
          &__none {
            padding: 16px;
            cursor: pointer;
          }
          &__expand {
            padding: 16px;
            cursor: pointer;
            background-color: gainsboro;
          }
        }
      }
      .group-content-tab{
        display: flex;
        justify-content: space-between;
        .item {
          width: 30.5%;
        }
        .item-icon{
          width: 8.5%;
        }
        .icon-start{
          align-items: flex-start;
        }
        .itemtext{
          width: 91.5%;
          justify-content: space-between;
          display: inline-flex;
          margin-left: 12%;
        }
        .item2{
          width: 45.75%;
        }
        .item4{
          width: 22.875%;
        }
      }

      .row-comment{
        display: flex;
        justify-content: left;
        .item-icon{
          width: 8.5%;
        }
        .item-comment{
          width: 100%;
          .exprow{
            // width: 100%;
            display: block;
            .itemnum{
              width: 12.5%;
              display: inline-block;
              margin-left: 3.5%;
              margin-right: 3.5%;
            }
            .itemtext2{
              display: inline-block;
              width: 80%;
            }
          }
        }
      }
    }
  }

  .pljp-tabs-top >.pljp-tabs-nav{
    margin: 0 !important;
  }

}

.document-list-tab-content{
  td{
    padding: 0px !important;
    .document-table-record{
      padding: 16px ;
    }
    .document-table-record-active{
      padding: 16px ;
      background-color: lightgray;
    }
  }
}


@media only screen and (max-device-width: 767.99px) {
 // display: inline !important;
  .group-content-tab{
    display: inline !important;
    .item-icon{
      width: 0px !important;
    }
    .item{
      width: 100% !important;
    }
    .item2{
      width: 100% !important;
    }
    .item3{
      width: 100% !important;
    }
    .item4{
      width: 100% !important;
    }
  }
    .row-comment{
      .item-icon{
        width: 0px !important;
    }
  }
  .sub-header-v2-top{
    margin-bottom: 10px;
  }
  .pljp-btn .kmu-menu-detail .pljp-btn-primary{
    margin-top: 10px;
  }
  .group-button-menu{
    .button-search-name {
      margin-top: 7px;
    }
  }
}


@media only screen and (min-device-width: 768px) {
  .group-button-menu{
    .button-search-name {
      display: inherit;
    }
  }
  #input-monitoring-page .edit-button{
    margin-right: 80px;
  }
}