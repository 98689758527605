#user-management-page .user-management-content {
  max-width: 960px;
  margin: 0 auto; }
  #user-management-page .user-management-content .pljp-avatar {
    height: 64px;
    width: 64px; }
  #user-management-page .user-management-content .pljp-list-bordered .pljp-list-item {
    padding-right: 16px;
    padding-left: 16px; }
  #user-management-page .user-management-content .pljp-list-item {
    padding-top: 8px;
    padding-bottom: 4px; }

.user-management-edit .pljp-avatar {
  height: 128px;
  width: 128px; }
