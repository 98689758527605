#dengon-page .pljp-layout-content .pljp-row {
  display: block; }

#dengon button {
  background: #00838f; }

#dengon .pljp-col-24 {
  padding-top: 16px; }

#dengon .dg-bg-header {
  background: #ddd;
  padding-bottom: 16px; }

#dengon .dg-list-user {
  max-height: 200px;
  overflow: auto;
  max-width: 483px;
  margin-top: 16px;
  border-top: 1px solid #3333;
  border-bottom: 1px solid #3333; }
  #dengon .dg-list-user .pljp-checkbox-group-item {
    padding-bottom: 16px;
    width: 45%; }

#dengon .dg-pu-c-all {
  padding-top: 6px; }
  #dengon .dg-pu-c-all button {
    background: white;
    color: #333333;
    border: 1px solid #cacaca; }
  #dengon .dg-pu-c-all .dg-pu-c-all-btn-off {
    margin-left: 16px; }

#dengon .dg-bd-popup {
  background: #ddd;
  padding-bottom: 16px; }

#dengon .dg-pu-btn-end {
  max-width: 483px;
  display: flex;
  justify-content: right; }
  #dengon .dg-pu-btn-end .dg-pu-btn-e-r {
    margin-right: 16px; }

#dengon .dg-list-user .pljp-checkbox-group-item {
  min-width: 200px; }

#dengon .tag-user {
  padding: 2px 3px;
  margin-top: 5px;
  border-radius: 3px;
  background-color: #00838f;
  margin-right: 5px;
  color: #fff;
  display: inline-block;
  font-size: 12px; }

.maxw-960 {
  max-width: 768px;
  margin: 0 auto; }
  @media screen and (max-width: 784px) {
    .maxw-960 {
      padding: 0 16px; } }

.dg-bd-message {
  margin: 0 auto !important; }
  .dg-bd-message .pljp-list-item {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px; }
    .dg-bd-message .pljp-list-item .pljp-list-item-content {
      display: block; }
  .dg-bd-message .dg-bd-m-text {
    padding: 8px 0px; }
    .dg-bd-message .dg-bd-m-text p {
      padding: 8px;
      background: #dcedc8;
      border-radius: 8px;
      margin-bottom: 0px;
      display: inline-block;
      min-width: 250px;
      white-space: pre-line; }
  .dg-bd-message .pljp-list-item-meta-description {
    line-height: 8px;
    font-size: 12px;
    padding-top: 8px; }

.loading {
  position: fixed;
  bottom: 10%;
  left: 50%; }

.loading-bar {
  display: inline-block;
  width: 4px;
  height: 18px;
  border-radius: 4px;
  animation: loading 1s ease-in-out infinite; }

.loading-bar:nth-child(1) {
  background-color: #3498db;
  animation-delay: 0; }

.loading-bar:nth-child(2) {
  background-color: #c0392b;
  animation-delay: 0.09s; }

.loading-bar:nth-child(3) {
  background-color: #f1c40f;
  animation-delay: 0.18s; }

.loading-bar:nth-child(4) {
  background-color: #27ae60;
  animation-delay: 0.27s; }

@keyframes loading {
  0% {
    transform: scale(1); }
  20% {
    transform: scale(1, 2.2); }
  40% {
    transform: scale(1); } }

.pljp-list-empty-text {
  display: none; }
