#send-group-page .send-group-content {
  max-width: 960px;
  margin: 40px auto; }
  #send-group-page .send-group-content .kanri-page-send-group {
    background-color: #00838f;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    display: flex;
    color: white; }
  #send-group-page .send-group-content .kanri-page-send-group:hover {
    background-color: #19959c; }
  #send-group-page .send-group-content .krsg-select-area .pljp-checkbox-group-item {
    display: block;
    margin: 8px; }
