.monitoring-input {
  padding: 0;
  margin: 0;
  .menu-header-web {
    height: 60px;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #212121d9;
    color: @white;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    .sm-c-left {
      display: flex;
      align-items: center;
      h3 {
        color: @white;
        padding-right: 5px;
        font-size: 20px;
        font-weight: 400;
        margin: 0;
        margin-right: 10px;
      }
      button {
        background-color: #e46c0a;
        color: @white;
        border: none;
        transition: 0.3s all;
        &:hover {
          background-color: #ec8f43;
          color: @white;
        }
      }
      &__name {
        .title {
          margin-right: 10px;
        }
        display: flex;
        div:last-child {
          font-weight: 600;
        }
      }
    }
  }
  &__main {
    max-width: 1081px;
    margin: 20px auto;
    padding: 0 15px;
  }
  &__toobar {
    &__row {
      display: flex;
      justify-content: space-between;
    }
    &__date-picker {
      display: flex;
      align-items: center;
      white-space: nowrap;
      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }
    &__tanto {
      display: flex;
      align-items: center;
      white-space: nowrap;
      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }
  }
  .monitoring-input__button {
    margin: 10px 0;
    .monitoring-input__button__1 {
      background-color: #a30000;
      border: none;
      margin-right: 5px;
      transition: 0.3s all;
      &:hover {
        background-color: #ac4a4a;
      }
    }
    .monitoring-input__button__2 {
      transition: 0.3s all;
      background-color: #a30000;
      border: none;
      &:hover {
        background-color: #ac4a4a;
      }
    }
  }
  .monitoring-input__button__1:focus {
    background-color: #a30000;
  }
  .monitoring-input__button__2:focus {
    background-color: #a30000;
  }
  &__list {
    .tab-life-list {
      border-radius: 4px;
      transition: 0.3s all;
      table {
        border: 2px solid #000000 !important;
        td {
          padding: 0;
          border-color: #000000;
          .button-icon-group__expand {
            padding: 16px 16px;
            background-color: #95afbd;
          }
          .button-icon-group__none {
            padding: 16px 16px;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    &__infos {
      margin-bottom: 10px;
    }
    &__info {
      display: flex;
      justify-content: space-between;
      &__item1 {
        display: flex;
        width: 23%;
        margin-right: 20px;
        .comment {
          word-break: break-all;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 125px;
        }
        p:first-child {
          font-weight: 600;
          margin-right: 5px;
          white-space: nowrap;
        }
        p:last-child {
          word-break: break-all;
        }
      }
      &__item1:last-child {
        justify-content: flex-end;
      }
      &__item_in_use{
        display: flex;
        width: 8%;
        p:first-child {
          font-weight: 600;
          margin-right: 5px;
          white-space: nowrap;
        }
        p:last-child {
          word-break: break-all;
        }
      }
      &__item1:last-child {
        justify-content: flex-end;
      }

    }
    &__button {
      display: flex;
      justify-content: center;
      button {
        margin: 0 3px;
      }
      .button-link-input {
        color: @white;
        background-color: #a30000;
        border: none;
        transition: 0.3s all;
        &:hover {
          background-color: #a12f2f;
          border: none;
          color: #ffffff;
        }
      }
      .button-delete {
        color: @white;
        background-color: #808080;
        border: none;
        transition: 0.3s all;
        &:hover {
          background-color: #a0a0a0;
          border: none;
          color: #ffffff;
        }
      }
      .button-user {
        color: @white;
        background-color: #29632c;
        border: none;
        transition: 0.3s all;
        &:hover {
          background-color: #218b26;
          border: none;
          color: #ffffff;
        }
      }
    }
  }
  @media (max-width: 992px) {
    &__toobar {
      &__row {
        display: block;
      }
      &__date-picker {
        display: inline-flex !important;
        margin: 0 20px 10px 0;
      }
      &__tanto {
        display: inline-flex !important;
      }
    }
  }
  @media (max-width: 575px) {
    &__toobar {
      &__row {
        display: block;
      }
      &__date-picker {
        p {
          margin-right: 61px;
        }
      }
      &__tanto {
        margin-bottom: 10px !important;
      }
      &__search {
        display: flex;
        input {
          width: 97px !important;
        }
      }
    }
    &__list {
      &__info {
        display: block;
        &__item1 {
          width: auto;
        }
      }
    }
  }
}
