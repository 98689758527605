.Inquiry-Request {
  .menu-header-web {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #212121d9;
    color: @white;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .sm-c {
      display: flex;
      justify-content: space-between;
      .sm-c-left {
        h3 {
          color: @white;
        }
        &__name {
          align-items: baseline;
          .title {
            margin-right: 10px;
          }
          display: flex;
          div:last-child {
            font-size: 1.2em;
            font-weight: bold;
          }
        }
      }
      .sm-c-right {
        display: flex;
        .link-item1 {
          color: @white;
          margin-right: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .link-item2 {
          color: @white;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .button-callback {
          background-color: transparent;
          border: none;
          display: flex;
          &:focus {
            outline: none;
          }
          &:hover {
            cursor: pointer;
          }
        }
        p {
          margin-top: 5px;
          color: @white;
        }
      }
    }
  }
  .menu-footer {
    position: fixed;
    bottom: 0;
    z-index: 2;
    background-color: #212121d9;
    color: @white;
    padding: 8px 16px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .sm-c-right {
      display: flex;
      .link-item1 {
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .button-callback {
        background-color: transparent;
        border: none;
        display: flex;
        &:focus {
          outline: none;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .link-item2 {
        color: @white;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      p {
        margin-top: 5px;
        color: @white;
      }
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    color: @black;
    margin-bottom: 15px;
  }
  &__controll1,
  &__controll2,
  &__controll3 {
    display: flex;
    height: min-content;
    p {
      display: flex;
      align-items: center;
      margin-right: 5px;
      white-space: nowrap;
    }
    span {
      width: 200px;
    }
  }
  &__controll4 {
    button {
      background-color: @blue;
      color: #ffffff;
      transition: 0.3 all;
      border: none;
    }
  }
  &__controll4 {
    button:hover {
      background-color: #09a2b0;
      color: #ffffff;
    }
  }
  &__input-text {
    margin-bottom: 15px;
    &__title {
      display: flex;
      color: #000000;
      align-items: center;
      margin-bottom: 5px;
      p {
        margin-right: 5px;
      }
    }
  }
  .Inquiry-Request__button {
    border-radius: 50%;
    width: 27px;
    height: 27px;
    background-color: @blue;
    border: none;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all;
    margin-left: 10px;
  }
  .Inquiry-Request__button:hover {
    background-color: #09a2b0;
    color: #ffffff;
  }
  &__table-main {
    display: flex;
    align-items: center;
  }
  &__table {
    .pljp-table-thead {
      white-space: nowrap;
    }
    flex: 1;
  }
  &__th-column {
    display: flex;
    align-items: center;
  }
  &__th-column-p {
    margin-right: 10px;
  }
  &__add-reference {
    button {
      border: none;
      background-color: #ff4d4f;
      color: #ffffff;
      transition: 0.3s all;
      margin-top: 10px;
    }
  }
  &__add-reference button:hover {
    background-color: #e76d60;
    color: #ffffff;
  }
  &__button-del {
    background-color: #808080;
    border-color: #808080;
    color: #ffffff;
    margin-left: 5px;
    width: 55px;
    height: 30px;
    border-radius: 4px;
    border: none;
    padding: 0;
    transition: 0.3s all;
  }
  &__button-del:hover {
    background-color: #a0a0a0;
    border-color: #a0a0a0;
    color: #ffffff;
  }
  &__button-del:focus {
    outline: none;
  }

  @media (max-width: 992px) {
    &__controll1,
    &__controll2 {
      span {
        width: 120px;
      }
    }
    &__controll3 {
      .tanto-master {
        display: flex !important;
        &__select:last-child {
          width: 120px !important;
        }
      }
    }
  }

  @media (max-width: 575px) {
    &__header {
      flex-direction: column;
      div:nth-child(1n) {
        margin-bottom: 5px;
      }
    }
    &__controll1,
    &__controll2 {
      p {
        margin-right: 78px;
      }
      span {
        width: 220px;
      }
    }
    &__controll3 {
      p {
        margin-right: 22px;
        margin-top: 5px;
        align-items: baseline !important;
      }
      .tanto-master {
        .tanto-master__select:last-child {
          width: 146px !important;
        }
      }
    }
  }
  &__mobile {
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-weight: 600;
      }
    }
    &__item3,
    &__item4,
    &__item5,
    &__item6 {
      margin: 15px 0;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0;
        p {
          margin-right: 5px;
          font-weight: 600;
        }
      }
    }
    &__item1,
    &__item2 {
      margin: 15px 0;
      display: flex;
      white-space: nowrap;
      align-items: center;
      p {
        margin-right: 5px;
        font-weight: 600;
      }
    }
  }
}
.search-service-kikan {
  &:hover {
    cursor: pointer
  }
}
.search-service-kikan-none {
  &:hover {
    cursor: not-allowed
  }
}
