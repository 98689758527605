.sub-menu-top {
  * {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 575px) {
    // height: 70px;
    .m-left {
      display: block !important;
      .text-tool-bar {
        margin-top: 0 !important;
      }
      div:last-child {
        margin-left: 0 !important;

      }
    }
  }
  background-color: #212121d9;
  display: flex;
  justify-content: space-between;
  // height: 73px;
  padding: 8px 16px;
  .m-left {
    white-space: nowrap;
    .button-link-monitoring {
      width: 140px;
      border-radius: 4px;
      background-color: #e46c0a;
      color: @white;
      border: none;
      transition: 0.3s all;
      &:hover {
        background-color: #ec8f43;
        color: @white;
      }
    }
    .button-temp {
      width: 100px;
      border-radius: 4px;
      background-color: red;
      color: @white;
      border: none;
      transition: 0.3s all;
      &:hover {
        background-color: #fa4221;
        color: @white;
      }
    }
  }
  .m-right {
    display: flex;
    justify-content: space-between;
    a{
      padding: 4px;
    }
    .link-item {
      color: #fff;
      display: flex;
      flex-direction: column;
      transition: 0.3s all;
      margin-right: 10px;
      padding: 2px 5px;
      border-radius: 3px;
      text-align: center;
      p {
        padding-top:4px;
        white-space: nowrap;
      }
    }
    .link-item:hover {
      background-color: #c9bfbf;
    }
    .active {
      border: 1px solid #fff;
      border-radius: 3px;
      background-color: #aaa;

      i {
        color: yellow;
      }
    }
  }
}

.sub-menu-top-isdengon {
  * {
    margin: 0;
    padding: 0;
  }
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  .m-right {
    display: flex;
    justify-content: space-between;
    a{
      padding: 4px;
    }
    .link-item {
      color: #fff;
      display: flex;
      flex-direction: column;
      transition: 0.3s all;
      margin-right: 10px;
      padding: 2px 5px;
      border-radius: 3px;
      p {
        padding-top: 4px;
        white-space: nowrap;
      }
    }
    .link-item:hover {
      background-color: #c9bfbf;
      @media screen and (max-device-width: 850px) {
        background-color: unset;
      }
    }
    .active {
      border: 1px solid #fff;
      border-radius: 3px;
      background-color: #aaa;

      i {
        color: yellow;
      }
    }
  }

}
