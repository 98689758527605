.container-usage-ticket {
  .body {
    padding: 16px;
  }
  .header {
    padding: 14px 20px;
    .header-title-name {
      color: white;
    }
    .number-item {
      font-size: 15px;
      color: white;
      margin-left: 20px;
    }
  }
  .mr-5 {
    margin-right: 5px;
  }

  .ml-5 {
    margin-left: 5px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .py-16 {
    padding: 16px 0;
  }

  .px-11 {
    padding: 0 9px;
  }

  .px-7 {
    padding: 0 7px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .ml-4 {
    margin-left: 4px;
  }

  .mr-15 {
    margin-right: 15px;
  }

  .ml-15 {
    margin-left: 15px;
  }

  .ml-20 {
    margin-left: 20px;
  }

  .w-120 {
    width: 120px;
  }

  .w-143 {
    width: 143px !important;
  }
  
  .w-150 {
    width: 150px !important;
  }

  .w-190 {
    width: 190px;
  }

  .w-180 {
    width: 180px;
  }

  .w-200 {
    width: 200px;
  }


  .mt-10 {
    margin-top: 10px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .w-full {
    width: 100%;
  }

  .line-h-32 {
    line-height: 32px;
  }

  .items-center {
    align-items: center;
  }
  .content-between {
    justify-content: space-between;
  }
  .justify-center {
    justify-content: center;
  }
  .pljp-table-row {
    cursor: pointer;
  }

  .flex {
    display: flex;
  }

  .no-white-space {
    white-space: pre-wrap !important;
  }

  .text-center {
    text-align: center;
  }

  .text-default {
    text-align: initial !important;
  }

  .text-service-ryaku:last-child {
    height: auto !important;
    border-bottom: 1px solid white!important;
  }
  
  .text-ryaku:last-child {
    border-bottom: 1px solid white!important;
  }

  .text-dots {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .list-action {
    display: flex;
    align-items: center;
  }


  .pljp-table-small > .pljp-table-content > .pljp-table-scroll > .pljp-table-body > table > .pljp-table-tbody > tr > td {
    max-width: unset;
  }

  .attached-table {
    .custom-table {
      .pljp-table-thead {
        tr {
          th:not(:nth-of-type(2)) {
            text-align: center !important;
          }
        }
      }

      .pljp-table-tbody {
        tr {
          td:first-of-type {
            overflow: initial !important;
            //padding: 8px 4px 8px 3px !important;
          }
          td:not(:nth-of-type(2)):not(:nth-of-type(9)):not(:nth-of-type(10)):not(:nth-of-type(11)) {
            text-align: center;
          }

          td:nth-of-type(9) {
            text-align: right;
          }
        }
      }

      .tantoMasterName {
        position: relative;
        .tantoMasterName-title {
          position: absolute;
          top: 0;
          left: 0;
          padding: 8px;
        }
      }
    }

    .custom-table-detail {
      .pljp-table-tbody {
        tr {
          td:nth-of-type(4),
          td:nth-of-type(5),
          td:nth-of-type(8) {
            text-align: right !important;
          }

          td:nth-of-type(7) {
            text-align: left !important;
          }

          td:last-of-type {
            text-align: center !important;
          }
        }
      }
    }

    #custom-table-plan {
      .row-selected {
        tr,
        td {
          border: none !important;
        }
        .item-detail-office {
          border: none;
        }
      }
      .pljp-table-thead {
        tr {
          th {
            height: 38px;
          }
          th:not(:nth-of-type(8)) {
            height: unset;
            padding: 8px 4px !important;
          }

          th:nth-of-type(8) {
            padding: 8px 0px !important;
          }
        }
      }

      .pljp-table-tbody {
        tr {
          td:not(:nth-of-type(12)) {
            text-overflow: initial;
          }

          td:nth-of-type(8) {
            .item-detail-office {
              text-align: center;
              padding: 5px 0;
            }
          }
        }
      }
    }

    #custom-table-final {
      .row-selected {
        tr,
        td {
          border: none !important;
        }
        .item-detail-office {
          border: none;
        }
      }

      .pljp-table-thead {
        tr {
          th {
            height: unset;
            padding: 8px 4px !important;
          }
        }
      }

      .pljp-table-tbody {
        tr {
          td:not(:nth-of-type(14))  {
            text-overflow: initial;
          }

          td.text-serviceSyuruiCd {
            text-overflow: ellipsis;
          }
        }
      }
    }

    .mode-details {
      #custom-table-final {
        .row-selected {
          td {
            border-right:0.8px solid black  !important;
            border-bottom: 0.8px solid black !important;
          }

          .item-detail-office:not(:last-of-type) {
            border-bottom: 0.5px solid black;
          }
        }
      
    }
    }

   

    #custom-table-addition {
      .row-selected {
        tr,
        td {
          border: none !important;
        }
        .item-detail-office {
          border: none;
        }
      }
      
      .pljp-table-thead {
        tr {
          th {
            height: unset;
            padding: 8px 4px !important;
          }
        }
      }

      .pljp-table-tbody {
        tr {
          td {
            text-overflow: initial;
          }
        }
      }
    }
    }

  .support-addition-table {
    .custom-table {
      .pljp-table-tbody {
        tr {
          td:nth-of-type(9) {
            text-align: initial;
          }
        }
      }
    }
  }

  .custom-table {
    .pljp-table {
      border-color: black;
    }

    .pljp-table-bordered .pljp-table-content {
      border-right: 1px solid black !important;
    }
    .pljp-table-thead,
    .pljp-table-tbody {
      tr {
        th,
        td {
          border-color: black !important;
        }
        td:last-child{
          // border-right: 0.5px solid black !important;
        }
        th:last-child{
          // border-right: 0.5px solid black !important;
        }
      }
    }

    .pljp-table-tbody {
      tr:last-child {
        td {
          border-bottom: none !important;
        }
      }
    }

    .pljp-table-placeholder {
      border-color: black !important;
    }

    @media (max-width: 1050px) {
      .row-render-plan {
        width: 90%;
      }

      .row-render-actual {
        width: 84%;
      }
    }
  }

  .pljp-table-expanded-row {
    background-color: #bacfcd;
  }
  .pljp-table-expanded-row:hover {
    background-color: #bacfcd;
  }

  .item-detail-office {
    padding: 5px 10px;
    border-bottom: 0.5px solid black;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .row-selected {
    tr,
    td {
      // border: none !important;
    }
    .item-detail-office {
      // border: none;
    }
  }

  .item-detail-office:last-child {
    border-bottom: none;
  }

  .row-no-padding {
    padding: 0 !important;
  }



  .pljp-tabs-tab {
    height: 40px !important;
    margin: 0 !important;
    margin-right: 2px !important;
    padding: 0 16px !important;
    line-height: 38px !important;
    background: #fafafa;
    border: 0.5px solid #e8e8e8;
    border-radius: 4px 0 !important;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }

  .pljp-tabs-tab-active {
    color: #fff !important ;
    border-color: #e8e8e8 !important;
    border: 0.5px solid #3f3e3e !important;
    background: gray !important;
  }

  .pljp-tabs-bar {
    margin-top: 0px !important;
  }

  .text-black {
    color: black;
  }

  .date-picker-useslip {
    .pljp-picker-input >input{
      right: -23px;
    }
    .pljp-picker-suffix {
      order: -1;
      color:white;
      z-index: 1;
      position: absolute;
      left: -8px !important;
    }
    .pljp-picker-footer-extra{
      width: 100% !important;
    }
    .pljp-picker-suffix:hover{
      color: white !important;
    }
  }
  
  .date-picker-useslip::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 23%;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #00838f;
  }
  .trun-cate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .pljp-table-container{
    border: 1px solid !important;
  }
  .pljp-table-wrapper .pljp-table-container table>thead>tr:first-child >*:last-child{
    border-start-end-radius: unset !important;
  }
  @media (max-width: 1517px) {
    .filter-condition {
      display: block;
    }
    .list-action {
      margin-bottom: 10px;
      justify-content: space-between;
    }
  }
  .pljp-tabs-nav-wrap{
    border-bottom: 4px solid #e8e8e8;
  }
}

.container-check-mess {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .m-left {
      .header-title-name {
        font-size: 1.6em;
        color: #fff;
        font-weight: initial;
      }
    }

    .m-right {
      display: flex;
      justify-content: space-between;
      margin-top: 6px;
      .link-item {
        cursor: pointer;
        color: #fff;
        display: flex;
        flex-direction: column;
        transition: 0.3s all;
        margin-right: 10px;
        padding: 2px 5px;
        border-radius: 3px;
        align-items: center;
        p {
          padding-top: 2px;
          white-space: nowrap;
        }
      }
      .link-item:hover {
        background-color: #c9bfbf;
      }
      .active {
        border: 1px solid #fff;
        border-radius: 3px;
        background-color: #aaa;

        i {
          color: yellow;
        }
      }
    }
  }

  .section-check-mess {
    padding: 0 110px;
    .check-mess-title {
      border-bottom: 1px solid black;
      display: inline-block;
      line-height: 26px;
    }

    .table-check-mess {
      padding-bottom: 5px;
      .item-detail-office {
        padding: 5px 10px;
        border-bottom: 1px solid black;
      }

      .item-detail-office:last-child {
        border-bottom: none;
      }

      .pljp-table-thead > tr > th {
        height: initial;
      }

      .pljp-table-body > tr > td {
        padding: 8px 4px;
      }

      .pljp-table-body tr td:last-of-type {
        padding: 0 !important;
      }

      .custom-table-check-mess {
        td, th {
          border: 1px solid black;
        }

        tr {
          th {
            padding: 5px 7px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            text-wrap: nowrap;
          }

          td {
            padding: 5px 10px;
            text-wrap: nowrap;
            &:last-of-type {
              text-wrap: initial;
            }
          }
        }
      }

      .custom-table {
        tr {
          th {
            padding: 5px 7px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            text-wrap: nowrap;
          }

          td {
            padding: 5px 10px;
            text-wrap: nowrap;
            &:last-of-type {
              text-wrap: initial;
            }
          }
        }

        .pljp-table-thead {
          tr th:last-of-type {
            border-top-right-radius: initial;
          }

        }
        .pljp-table {
          border-color: black !important;
        }

        .pljp-table-small.pljp-table-bordered .pljp-table-content {
          border-right: 1px solid black;
        }

        .pljp-table-thead,
        .pljp-table-tbody {
          tr {
            th,
            td {
              border-color: black !important;
            }
            td:last-child{
              border-right: 0.5px solid black !important;
            }
            th:last-child{
              border-right: 0.5px solid black !important;
            }
          }
          tr:last-child {
            td {
              border-bottom: none !important;
            }
          }
        }

        .pljp-table-placeholder {
          border-color: black !important;
          // border-right: 0.5px solid black !important;
        }
      }
    }
  }
}

.modal-batch-create {
  .pljp-table-thead > tr > th {
    text-align: left;
  }

  .pljp-table {
    border-color: black;
  }

  .pljp-table-bordered .pljp-table-content {
    border-right: 1px solid black !important;
  }
  .pljp-table-thead,
  .pljp-table-tbody {
    tr {
      th,
      td {
        border-color: black !important;
      }
      td:last-child{
        // border-right: 0.5px solid black !important;
      }
      th:last-child{
        // border-right: 0.5px solid black !important;
      }
    }
  }

  .pljp-table-tbody {
    tr:last-child {
      td {
        border-bottom: none !important;
      }
    }
  }

  .pljp-table-placeholder {
    border-color: black !important;
  }
}

.pljp-picker-dropdown{
  .pljp-picker-footer-extra{
    width: 100%;
  }
  .pljp-picker-ranges{
    width: 30%;
  }
}


@media only screen and (min-device-width: 1500px) and (max-device-width: 1600px) {
  .container-usage-ticket {
    .custom-table {
      .pljp-table-header {
        .pljp-table-fixed {
          border-bottom: 1px solid black !important;
        }
      }
      .pljp-table-body {
        .pljp-table-fixed {
          border-top: 1px solid black !important;
        }
      }

      .pljp-table-thead tr th:last-of-type {
        border-radius: initial;
      }
    }
  }
}
