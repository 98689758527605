.tabrecords {
  background-color: rgba(33, 33, 33, 0.85);
  padding: 4px 16px; }

.dr-datapicker {
  margin: 0 auto;
  padding: 16px 0; }

#dailyrecords {
  margin: 0 auto;
  padding: 16px 0; }

.dr-datapicker .pljp-btn-group > div {
  display: inline; }
  .dr-datapicker .pljp-btn-group > div input {
    border: 1px solid #d9d9d9;
    padding: 5px;
    text-align: center; }

.s-filter-form .sa-f-item-l {
  float: right;
  margin-right: 0px;
  margin-left: 8px; }

.s-filter-form .sa-f-search-content {
  margin-top: 4px;
  padding: 0 8px;
  border: 1px solid #444444;
  line-height: 32px;
  min-width: 350px;
  min-height: 32px; }

.a-filter-form .sa-f-item-l {
  float: right;
  margin-right: 0px;
  margin-left: 8px; }

.a-filter-form .f-by-keyboard .pljp-form-item {
  margin-right: 4px; }

.a-filter-form .sa-f-search-content {
  margin-top: 5px;
  padding: 0 8px;
  border: 1px solid #444444;
  line-height: 32px;
  min-width: 300px;
  min-height: 32px;
  border-radius: 4px;
  float: left; }

.a-filter-form .pljp-calendar-picker {
  top: -1px; }

.a-filter-form .pljp-legacy-form-item-children {
  display: inline-flex; }

.table-row-bt button {
  margin-right: 8px;
  padding: 0 8px;
  height: 28px; }

.table-row-bt :last-child {
  margin-right: 0px; }

.table-row-bt:parrent {
  text-align: center; }

.kn-search-con .pljp-form-item {
  padding-right: 16px;
  margin-bottom: 16px; }

.rl-mobile .pljp-list-item-content {
  display: unset; }

.rl-mobile .rl-m-item {
  margin-top: 8px;
  padding-right: 16px; }
  .rl-mobile .rl-m-item .table-row-bt button {
    margin-top: 8px; }

.nts-gender-red {
  color: red; }

.nts-gender-blue {
  color: blue; }

.nts-haiben-red {
  color: red; }

.nts-haiben-block {
  color: black; }

.ntk-riyousya-colum .ntl-riyousya-gender {
  float: right; }

.pljp-table-small > .pljp-table-content > .pljp-table-body {
  margin: 0px; }

.table-kiroku-date .joutaiCol {
  padding: 0px !important;
  overflow: hidden;
  position: relative; }

.table-kiroku-date .nuytaisho-yellow {
  display: inline-block;
  width: 100%;
  padding: 8px;
  text-align: center;
  background: yellow;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%; }

.table-kiroku-date .nuytaisho-blue {
  display: inline-block;
  width: 100%;
  padding: 8px;
  text-align: center;
  background: #89e1fd;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%; }

.table-kiroku-date .haisetu-yellow {
  background: yellow; }

.table-kiroku-date .pljp-table-expanded-row > td > p {
  padding: 4px 0px;
  text-align: center; }

.table-kiroku-date .pljp-table-small
> .pljp-table-content
> .pljp-table-scroll
> .pljp-table-body
> table
> .pljp-table-tbody
> .pljp-table-expanded-row
> td {
  padding: 0px; }

.mff-row-2 .pljp-form-item {
  margin-right: 8px; }
  .mff-row-2 .pljp-form-item input {
    max-width: 140px; }

.mff-row-2 .pljp-form-item:last-child {
  margin-right: 0; }

.pljp-table-body-inner .pljp-table-fixed .pljp-table-expanded-row td {
  height: 37px; }
  .pljp-table-body-inner .pljp-table-fixed .pljp-table-expanded-row td > p {
    display: none; }

.icon-table {
  text-align: center;
  width: 100%; }

.add-edit {
  width: 100%;
  height: 30xp !important;
  background: red; }

.pljp-tooltip-inner {
  background: #00838f; }

.pljp-tooltip-placement-bottomRight .pljp-tooltip-arrow {
  border-bottom-color: #00838f; }

.pljp-table-tbody .pljp-table-row .timeCol {
  padding: 0px !important;
  overflow: hidden;
  position: relative; }
  .pljp-table-tbody .pljp-table-row .timeCol .bg_green {
    display: inline-block;
    width: 100%;
    padding: 8px;
    text-align: center;
    background: #80c380;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }
  .pljp-table-tbody .pljp-table-row .timeCol .bg_pink {
    display: inline-block;
    width: 100%;
    padding: 8px;
    text-align: center;
    background: pink;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }

.pljp-table-tbody .pljp-table-row .haisetuCol {
  padding: 8px;
  overflow: hidden;
  position: relative; }
  .pljp-table-tbody .pljp-table-row .haisetuCol .bg_haiben {
    display: inline-block;
    width: 100%;
    padding: 8px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0; }

.anticon-close-circle,
.pljp-calendar-picker-clear,
.pljp-calendar-input-wrap,
.pljp-calendar-range-middle {
  display: none; }

.pljp-input:focus {
  box-shadow: none; }

.pljp-input {
  border-radius: 0px; }

.pljp-table-small > .pljp-table-content > .pljp-table-scroll > .pljp-table-body > table > .pljp-table-tbody > tr > td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px; }

.pljp-tooltip-placement-bottomLeft .pljp-tooltip-arrow {
  border-bottom-color: #00838f; }

.pljp-tooltip-placement-bottomRight .pljp-tooltip-arrow {
  border-bottom-color: #00838f; }

.pljp-tooltip-placement-topLeft .pljp-tooltip-arrow {
  border-top-color: #00838f; }

.pljp-tooltip-placement-topRight .pljp-tooltip-arrow {
  border-top-color: #00838f; }

#gazouDrawerImage img {
  max-width: 100%; }

.drawerGazou .pljp-drawer-body {
  padding: 0px;
  height: 85%; }

.action-kiroku button {
  height: 21px;
  padding: 1px 2px;
  font-size: 12px; }

.inputMode {
  padding: 0px;
  height: 20px;
  min-width: 35px;
  font-size: 13px; }

.inputModeSpo2 {
  position: relative; }
  .inputModeSpo2 .anticon {
    width: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    color: blue; }

.konkaistyle {
  color: green; }

.title-common-value {
  float: right;
  padding-right: 50px; }

.model-top-row {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85); }
  .model-top-row .mtr-col1 {
    text-align: right;
    font-size: 14px; }

.pljp-modal-body {
  padding: 16px 24px 24px 24px; }

.number-pro button {
  width: 50px;
  height: 50px; }

.action-col .pljp-btn-background-ghost.pljp-btn-primary {
  color: red; }

.input-select .pljp-select-selection {
  background-color: #00838f;
  color: white;
  border: none; }

.input-select-disabled .pljp-select-selection {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none; }

.text-base-color {
  color: rgba(0, 0, 0, 0.65); }

.inputNumber-pre,
.inputNumber-aft {
  border-radius: 4px;
  text-align: right;
  font-size: 20px;
  height: 40px;
  background: #fff !important;
  max-width: 196px; }

.text-area-select {
  max-width: 450px; }

.pad-bt-8 {
  padding-bottom: 8px; }

#report-page-time-pro {
  width: 400px; }

.hm-item-hira-kb {
  height: 40px;
  width: 50px; }

.kiroku-input-modal .pljp-modal-footer {
  text-align: center; }

.kiroku-right-drawer .pljp-drawer-content-wrapper,
.kiroku-drawer .pljp-drawer-content-wrapper {
  width: calc(100% - 60px) !important;
  max-width: 720px !important; }

.kiroku-right-drawer .pljp-drawer-body,
.kiroku-drawer .pljp-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0; }

.kiroku-right-drawer__title,
.kiroku-drawer__title {
  padding: 15px 20px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85); }

.kiroku-right-drawer__content,
.kiroku-drawer__content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow-y: auto;
  transition: all 0.35s; }
  .kiroku-right-drawer__content .pg-viewer-wrapper,
  .kiroku-drawer__content .pg-viewer-wrapper {
    overflow-y: unset;
    width: 100% !important; }
  .kiroku-right-drawer__content .unsupported-message,
  .kiroku-drawer__content .unsupported-message {
    height: 100%;
    display: flex;
    align-items: center; }
  .kiroku-right-drawer__content .photo-viewer-container,
  .kiroku-drawer__content .photo-viewer-container {
    width: 100% !important;
    height: 100% !important; }
    .kiroku-right-drawer__content .photo-viewer-container img,
    .kiroku-drawer__content .photo-viewer-container img {
      max-width: 100% !important;
      max-height: 100% !important;
      object-fit: contain;
      cursor: zoom-in; }
  .kiroku-right-drawer__content .pdf-canvas,
  .kiroku-drawer__content .pdf-canvas {
    display: flex; }
    .kiroku-right-drawer__content .pdf-canvas canvas,
    .kiroku-drawer__content .pdf-canvas canvas {
      margin: 0 auto; }
  .kiroku-right-drawer__content .loading-container,
  .kiroku-right-drawer__content .pdf-loading,
  .kiroku-drawer__content .loading-container,
  .kiroku-drawer__content .pdf-loading {
    display: none !important; }
  .kiroku-right-drawer__content--zoom-image,
  .kiroku-drawer__content--zoom-image {
    padding-top: 0;
    padding-bottom: 0; }
    .kiroku-right-drawer__content--zoom-image .photo-viewer-container,
    .kiroku-drawer__content--zoom-image .photo-viewer-container {
      height: auto !important; }
      .kiroku-right-drawer__content--zoom-image .photo-viewer-container img,
      .kiroku-drawer__content--zoom-image .photo-viewer-container img {
        object-fit: cover;
        cursor: zoom-out; }

.kiroku-right-drawer__thumb-icon,
.kiroku-drawer__thumb-icon {
  width: 80px !important;
  height: 80px !important; }

.kiroku-right-drawer__footer,
.kiroku-drawer__footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  border-top: 1px solid #e8e8e8; }
  .kiroku-right-drawer__footer button:first-child,
  .kiroku-drawer__footer button:first-child {
    margin-right: 10px; }
    .kiroku-right-drawer__footer button:first-child a,
    .kiroku-drawer__footer button:first-child a {
      margin-left: 5px; }
  .kiroku-right-drawer__footer button:disabled,
  .kiroku-drawer__footer button:disabled {
    color: #bbb; }
    .kiroku-right-drawer__footer button:disabled a,
    .kiroku-drawer__footer button:disabled a {
      color: inherit !important; }

.kiroku-file-modal .pljp-modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px; }
  .kiroku-file-modal .pljp-modal-title span:first-child {
    font-size: 25px;
    font-weight: 600; }

.kiroku-file-modal .pljp-modal-body {
  background-color: white; }
  .kiroku-file-modal .pljp-modal-body span:first-child {
    font-size: 16px;
    font-weight: 500; }

.kiroku-file-modal .pljp-modal-footer {
  display: flex;
  justify-content: center;
  align-items: center; }

.kiroku-file-modal .time-bk-current {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 10px; }
  .kiroku-file-modal .time-bk-current__input {
    width: 100px;
    height: 40px;
    background-color: #fff;
    color: #333;
    border-radius: 4px;
    font-weight: 600;
    font-size: 24px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid; }
  .kiroku-file-modal .time-bk-current button {
    margin-left: 10px; }

#kiroku-files-page .pljp-table-body {
  margin-left: 0;
  margin-right: 0; }

#kiroku-files-page .attendance-Gray {
  background-color: #c3c3c3;
  text-align: center; }

#kiroku-files-page .time-pink {
  background-color: pink;
  text-align: center; }

#kiroku-files-page .kiroku-files__filter {
  padding: 8px 16px; }
  #kiroku-files-page .kiroku-files__filter-top {
    display: flex;
    align-items: center; }
    #kiroku-files-page .kiroku-files__filter-top .kiroku-filter__datepicker,
    #kiroku-files-page .kiroku-files__filter-top .kiroku-filter__labels {
      margin-right: 15px;
      margin-bottom: 10px; }
    #kiroku-files-page .kiroku-files__filter-top .kiroku-filter__topright {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 10px; }
  #kiroku-files-page .kiroku-files__filter-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    #kiroku-files-page .kiroku-files__filter-bottom .kiroku-filter__select,
    #kiroku-files-page .kiroku-files__filter-bottom .kiroku-filter__search {
      margin-right: 10px; }
  #kiroku-files-page .kiroku-files__filter--detail .dayfile-datepicker {
    width: 250px;
    margin-right: 15px; }
  #kiroku-files-page .kiroku-files__filter--detail .dayfile-show {
    float: right; }

#kiroku-files-page .kiroku-files__table {
  white-space: nowrap;
  padding: 0 16px; }
  #kiroku-files-page .kiroku-files__table .pljp-table-body {
    margin: 0 !important; }
  #kiroku-files-page .kiroku-files__table table {
    table-layout: fixed; }
    #kiroku-files-page .kiroku-files__table table td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; }
  #kiroku-files-page .kiroku-files__table--expanded table td {
    white-space: normal;
    word-break: break-all; }
  #kiroku-files-page .kiroku-files__table .input-mode.input-mode-last {
    border-right: 1px solid #00838f; }

#kiroku-files-page .pljp-table-fixed-left .pljp-table-expanded-row td {
  height: 49px; }
  #kiroku-files-page .pljp-table-fixed-left .pljp-table-expanded-row td > div {
    display: none; }

@media screen and (max-width: 820px) {
  #kiroku-files-page .kiroku-files__filter-bottom {
    align-items: flex-start; }
    #kiroku-files-page .kiroku-files__filter-bottom .kiroku-filter__search-input,
    #kiroku-files-page .kiroku-files__filter-bottom .kiroku-filter__search {
      flex: 1; }
    #kiroku-files-page .kiroku-files__filter-bottom .kiroku-filter__select,
    #kiroku-files-page .kiroku-files__filter-bottom .kiroku-filter__search {
      margin-bottom: 10px; } }

.kiroku-right-drawer .pljp-drawer-content-wrapper,
.kiroku-drawer .pljp-drawer-content-wrapper {
  width: calc(100% - 60px) !important;
  max-width: 720px !important; }

.kiroku-right-drawer .pljp-drawer-body,
.kiroku-drawer .pljp-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0; }

.kiroku-right-drawer__title,
.kiroku-drawer__title {
  padding: 15px 20px;
  border-bottom: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85); }

.kiroku-right-drawer__content,
.kiroku-drawer__content {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow-y: auto;
  transition: all 0.35s; }
  .kiroku-right-drawer__content .pg-viewer-wrapper,
  .kiroku-drawer__content .pg-viewer-wrapper {
    overflow-y: unset;
    width: 100% !important; }
  .kiroku-right-drawer__content .unsupported-message,
  .kiroku-drawer__content .unsupported-message {
    height: 100%;
    display: flex;
    align-items: center; }
  .kiroku-right-drawer__content .photo-viewer-container,
  .kiroku-drawer__content .photo-viewer-container {
    width: 100% !important;
    height: 100% !important; }
    .kiroku-right-drawer__content .photo-viewer-container img,
    .kiroku-drawer__content .photo-viewer-container img {
      max-width: 100% !important;
      max-height: 100% !important;
      object-fit: contain;
      cursor: zoom-in; }
  .kiroku-right-drawer__content .pdf-canvas,
  .kiroku-drawer__content .pdf-canvas {
    display: flex; }
    .kiroku-right-drawer__content .pdf-canvas canvas,
    .kiroku-drawer__content .pdf-canvas canvas {
      margin: 0 auto; }
  .kiroku-right-drawer__content .loading-container,
  .kiroku-right-drawer__content .pdf-loading,
  .kiroku-drawer__content .loading-container,
  .kiroku-drawer__content .pdf-loading {
    display: none !important; }
  .kiroku-right-drawer__content--zoom-image,
  .kiroku-drawer__content--zoom-image {
    padding-top: 0;
    padding-bottom: 0; }
    .kiroku-right-drawer__content--zoom-image .photo-viewer-container,
    .kiroku-drawer__content--zoom-image .photo-viewer-container {
      height: auto !important; }
      .kiroku-right-drawer__content--zoom-image .photo-viewer-container img,
      .kiroku-drawer__content--zoom-image .photo-viewer-container img {
        object-fit: cover;
        cursor: zoom-out; }

.kiroku-right-drawer__thumb-icon,
.kiroku-drawer__thumb-icon {
  width: 80px !important;
  height: 80px !important; }

.kiroku-right-drawer__footer,
.kiroku-drawer__footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  border-top: 1px solid #e8e8e8; }
  .kiroku-right-drawer__footer button:first-child,
  .kiroku-drawer__footer button:first-child {
    margin-right: 10px; }
    .kiroku-right-drawer__footer button:first-child a,
    .kiroku-drawer__footer button:first-child a {
      margin-left: 5px; }
  .kiroku-right-drawer__footer button:disabled,
  .kiroku-drawer__footer button:disabled {
    color: #bbb; }
    .kiroku-right-drawer__footer button:disabled a,
    .kiroku-drawer__footer button:disabled a {
      color: inherit !important; }

.kiroku-drawer-form-file .pljp-drawer-body {
  overflow: auto;
  height: calc(100% - 90px); }
  .kiroku-drawer-form-file .pljp-drawer-body .pljp-spin-nested-loading,
  .kiroku-drawer-form-file .pljp-drawer-body .pljp-spin-container {
    height: 100%; }
  .kiroku-drawer-form-file .pljp-drawer-body .pljp-spin-container {
    display: flex;
    flex-direction: column; }
  .kiroku-drawer-form-file .pljp-drawer-body .unsupported-message {
    background-color: unset; }

#report-page .table-kiroku-date .pljp-table-bordered,
#report-page .table-kiroku-date .pljp-table-bordered > .pljp-table-content,
#report-page .table-kiroku-date .pljp-table-bordered .pljp-table-tbody > tr > td,
#report-page .table-kiroku-date .pljp-table-bordered .pljp-table-thead > tr > th,
#report-page .table-kiroku-date .pljp-table-small.pljp-table-bordered .pljp-table-fixed-left .pljp-table-thead > tr > th:last-child,
#report-page .table-kiroku-date .pljp-table-small.pljp-table-bordered .pljp-table-fixed-left .pljp-table-tbody > tr > td:last-child {
  border-color: #ccc; }

#report-page .pljp-table-scroll .pljp-table-body,
#report-page .pljp-table-fixed-left .pljp-table-body-inner,
#report-page .pljp-table-fixed-right .pljp-table-body-inner {
  overflow: scroll !important;
  max-height: calc(100vh - 370px) !important; }

#report-page .pljp-table-empty.pljp-table-fixed-header #report-page .pljp-table-scroll .pljp-table-body .pljp-table-fixed {
  display: block;
  height: 1px; }

#report-page .pljp-table-expanded-row-fixed {
  display: flex;
  justify-content: center; }

.kiroku-drawer-form .pljp-drawer-content-wrapper .pljp-legacy-form-item {
  margin-bottom: 8px; }

.kiroku-drawer-form .pljp-drawer-content-wrapper .pljp-drawer-header {
  background: white; }

.kiroku-drawer-form .pljp-drawer-content-wrapper .pljp-upload-wrapper .pljp-upload-drag {
  background-color: white; }
