#riyousya-detail-page {
  white-space: pre-wrap; }
  #riyousya-detail-page .riyousya-detail-content {
    max-width: 960px;
    margin: 0 auto; }
    #riyousya-detail-page .riyousya-detail-content .rdc-avatar .pljp-avatar {
      max-width: 200px;
      max-height: 200px;
      min-width: 120px;
      min-height: 120px;
      border-radius: 0px; }
    #riyousya-detail-page .riyousya-detail-content .rdc-avatar .rdc-avatar-img {
      padding: 16px; }
    #riyousya-detail-page .riyousya-detail-content .rdc-avatar .rdc-avatar-info span {
      display: block; }
    #riyousya-detail-page .riyousya-detail-content .rdc-avatar .rdc-avatar-info .pljp-row {
      margin-top: 16px; }
    #riyousya-detail-page .riyousya-detail-content .rdc-avatar .rdc-avatar-info {
      padding: 0 16px; }
      #riyousya-detail-page .riyousya-detail-content .rdc-avatar .rdc-avatar-info button:first-of-type {
        margin-right: 16px; }
    #riyousya-detail-page .riyousya-detail-content .pljp-collapse-content {
      background-color: #ffffff;
      padding: 16px 0px; }
      #riyousya-detail-page .riyousya-detail-content .pljp-collapse-content .pljp-divider-horizontal {
        margin: 8px 0px; }
    #riyousya-detail-page .riyousya-detail-content .rdc-list .expand-btn {
      margin: 16px 0;
      text-align: right; }
    #riyousya-detail-page .riyousya-detail-content .rdp-tab-kankei span {
      display: block; }
    #riyousya-detail-page .riyousya-detail-content .rdp-tab-title, #riyousya-detail-page .riyousya-detail-content .rdp-basic-tab-title {
      font-weight: 600;
      color: #000000; }
    #riyousya-detail-page .riyousya-detail-content .rdp-tab-kankei-urgent {
      background-color: #00838F;
      padding: 4px 0px;
      color: #ffffff;
      text-align: center;
      max-width: 80px;
      border-radius: 4px; }
    #riyousya-detail-page .riyousya-detail-content .pljp-collapse-header {
      font-size: 18px; }
