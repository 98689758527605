.life-table {
  margin: 16px auto !important;
  max-width: 1080px;
  // padding: 0 10px;

  table,
  tr {
    border: 1px solid #e8e8e8;
    cursor: pointer;
  }

  table,
  tr,
  td {
    // border: 1px solid #e8e8e8;
    cursor: pointer;
  }

  .nameRiyousya {
    font-size: 1.5em;
  }

  .pljp-tabs-nav-wrap .pljp-tabs-tab {
    border-radius: 6px 6px 0 0;
    //border: 1px solid #e8e8e8;
    margin: 0 4px 0 0;
    padding: 8px 16px;
    background: rgba(0, 0, 0, .02);
    border: 1px solid rgba(5, 5, 5, .06);
  }

  .pljp-tabs-nav-wrap .pljp-tabs-tab-active {
    background: white;
  }
}

.menu-header {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #212121d9;
  color: @white;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;

  .sm-c-left {
    display: flex;
    color: @white;
    align-items: center;

    p {
      margin-right: 5px;
    }

    p:nth-child(2),
    p:nth-child(3) {
      font-size: 20px;
    }
  }

  .sm-c-center {
    display: flex;
    align-items: center;

    .item-button {
      cursor: pointer;
      display: flex;

      span {
        border: 1px solid;
        padding: 7px;
        font-size: 15px;
        background: #F2F2F2;
        color: black;
        border-radius: 5px;
      }
    }
  }

  .sm-c-right {
    display: flex;

    .item-link {
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: @white;
      transition: 0.3s all;
      padding: 2px 8px;

      &:hover {
        background-color: #e0e0e0;
        border-radius: 4px;
      }
    }
  }

  @media (max-width: 1024px) {
    .sm-c-right {
      .item-link {
        &:hover {
          background-color: unset;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) {
  .life-header {
    padding: 12px;

    .life-header__logo {
      height: 40px;
      width: auto;
    }
  }

  .life-table {
    .btn-row {
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;

      .btn-crud {
        margin-right: 10px;
        margin-bottom: 10px;
        color: #fff;
      }
    }

    .toolbar-row {
      display: flex;
      justify-content: space-between;

      .toolbar-checkbox-div {
        padding-top: 8px;
      }
    }

    .pljp-select-enabled {
      width: 200px !important;
    }

    .pljp-select-selection--single {
      width: 200px;
    }

    .row-button {
      text-align: right;

      .btn-right {
        .pljp-btn {
          margin-right: 5px;
          float: right;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 991.99px) {
  .life-table {
    #record-active-tab1 {
      .pljp-row .btn-row {
        display: block !important;
      }
    }
  }
}

@media only screen and (min-device-width: 992px) {
  .life-table {
    #record-active-tab1 {
      .btn-row {
        // display: flex;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-device-width: 767.99px) {
  .sub-menu-top {
    padding-bottom: 0px !important;
  }

  .life-header {
    height: 64px;

    .life-header__left {
      width: 50%;
      display: inline-block !important;
      padding-left: 10px;

      .life-header__logo {
        height: 20px;
        width: 72px !important;
      }

      .life-header__logo-title {
        font-size: 1.3em;
      }
    }

    .life-header__right {
      .life-header__nickname {
        font-size: 1em !important;
      }
    }
  }

  .text-tool-bar {
    margin-top: 10px !important;

    span {
      font-size: 15px !important;
    }
  }

  .life-table {
    .btn-row {
      justify-content: flex-start;
      margin-top: 10px;
    }

    .toolbar-row {
      .pljp-checkbox-wrapper {
        margin-bottom: 10px;
        padding-left: 0px !important;
      }

      .pljp-select {
        margin-bottom: 10px;
      }

      .date-picker {
        margin-bottom: 10px;
      }

      .search-riyousya {
        margin-bottom: 10px;
      }

      .sort-handle {
        margin-left: 0px !important;
      }

      .pljp-select-enabled {
        width: 120px !important;
      }

      .pljp-select-selection--single {
        width: 120px;
      }
    }

    .btn-right {
      .btn-crud {
        margin-right: 10px;
        margin-bottom: 10px;
      }

      .pljp-btn {
        margin-right: 5px;
        float: left;
      }
    }

    .pljp-row {
      .pljp-col {
        width: 100% !important;
      }

      .titleRecord {
        float: left !important;
        margin-bottom: 10px;
      }
    }
  }
}

.fixed-header {
  position: sticky;
  top: 0;
  z-index: 2;
}

.kobetsu-add-form {
  margin: 16px auto;
  max-width: 1451px;

  .pljp-tabs-tab {
    //   height: 40px;
    //   margin: 0 !important;
    //   margin-right: 2px;
    //   padding: 0 16px;
    //   line-height: 14px;
    //   cursor: pointer;
    //   transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background: #fafafa;
    border-radius: 4px 4px 0 0;
    border: 0.5px solid #e8e8e8;
    font-size: 12px;
  }

  .pljp-tabs-tab-active {
    // height: 40px;
    // color: black !important;
    // background: #fff;
    // border-color: #e8e8e8;
    // margin: 20px;
    border: 0.5px solid #3f3e3e;
    font-size: 25px;
  }

  .pljp-collapse-borderless>.pljp-collapse-item>.pljp-collapse-content {
    background-color: #fff;
  }

}

.kobetsu-detail-page {
  .pljp-row {
    margin-top: 16px;
  }
}

.page-kobetsu-riyou {
  .btn-row {
    margin-top: 16px;

    button {
      // min-width: 110px;
      margin-right: 16px;
    }
  }

  .tab-life-list {

    table,
    tr,
    td {
      text-align: left;
      border-radius: 4px 4px 0 0;
      border: 1px solid #c6c6c6;
      border-collapse: separate;
      border-spacing: 0;
      padding: 0;

      .btn-right {
        float: right;
      }

      .btn-crud {
        margin: 5px 2px;
        color: #fff;
      }

      .btn-tab-delete {
        background-color: gray;
        border-color: gray;
      }

      .btn-tab-edit {
        background-color: darkred;
        border-color: darkred;
      }
    }

    td {
      padding: 0px;

      .button-icon-group {
        &__none {
          cursor: pointer;
        }

        &__expand {
          cursor: pointer;
        }
      }
    }

    .group-content-tab {
      display: flex;
      justify-content: space-between;

      .item {
        width: 30.5%;
      }

      .item-icon {
        width: 8.5%;
      }

      .item2 {
        width: 45.75%;
      }

      .item4 {
        width: 22.875%;
      }
    }

    .row-comment {
      display: flex;
      justify-content: left;

      .item-icon {
        width: 8.5%;
      }

      .item-comment {
        width: 91.5%;
      }
    }
  }
}

.pinkM {
  background-color: @pinkM;
  border: @pinkM;
  color: black;

  &:hover {
    background-color: @pinkMCus;
    border: @pinkMCus;
    color: black;
  }

  &:active {
    background-color: @pinkMCus;
    border: @pinkMCus;
    color: black;
  }

  &:focus {
    background-color: @pinkMCus;
    border: @pinkMCus;
    color: black;
  }
}

.inout_required {
  color: #C62828;
  text-decoration: underline;
  text-decoration-color: #C62828;
}

.inout_required_jokuso {
  margin-top: 10px;
  p {
    font-size: 14px;
    color: #C62828;
    text-decoration: underline;
    text-decoration-color: #C62828;
  }
}

.inout_required_jokuso2 {
  margin-top: 10px;
  p {
    float: right;
    text-decoration: underline;
    text-decoration-color: #C62828;
    font-size: 14px;
    color: #C62828;
  }
}
.inout_required_jokuso3 {
  margin-top: 10px;
  p {
    font-size: 14px;
    color: #C62828;
  }
}

.multi-title {
  display: flex;
  justify-content: space-between;
}

.kobetuContainer-popover {
  left: 120px;
  width: 80%;
}

#kobetsu-detai-page #view-doc-list-page .view-jokuso-plan-container {
  .pljp-table-tbody>tr>td {
    border: 1px solid gray;
    // border-bottom: unset;
  }

  .view-plan-table1 {
    table>tbody>tr>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid white;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    table>tbody>tr>td:nth-child(3),
    table>tbody>tr>td:nth-child(5),
    table>tbody>tr>td:nth-child(7),
    table>tbody>tr>td:nth-child(9) {
      border-bottom: 1px solid #00838F;
    }
  }

  .view-plan-table2 {
    table>tbody>tr>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid white;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    table>tbody>tr>td:nth-child(n+1) {
      border-top: none;
    }

    table>tbody>tr>td:nth-child(3) {
      border-bottom: 1px solid #00838F;
    }

    table>tbody>tr>td:nth-child(5) {
      border-bottom: 1px solid #00838F;
    }
  }

  .view-plan-table3 {
    table>tbody>tr>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    table>tbody>tr>td:nth-child(n+1) {
      border-top: none;
    }
  }

  .view-plan-table5 {
    table>tbody>tr>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid white;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    table>tbody>tr>td:nth-child(3) {
      border-bottom: 1px solid white;
    }
  }

  .view-plan-table6 {
    table>tbody>tr>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid white;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    table>tbody>tr>td:nth-child(n+1) {
      border-top: none;
    }

    table>tbody>tr>td:nth-child(3) {
      border-bottom: 1px solid white;
    }
  }

  .view-plan-table7 {
    table>tbody>tr>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    table>tbody>tr>td:nth-child(n+1) {
      border-top: none;
    }
  }

}

.view-print-life {
  .tbl-life>td {
    border: solid 1px;
    padding-left: 10px;
  }

  .text-bold {
    text-align: center;
    // font-weight: bold;
  }

  .text-vertical {
    width: 30px;
    word-break: break-word;
    padding: 10px;
    vertical-align: "center";
  }

  @media only screen and (min-width: 1376px) {
    .flex-inline {
      display: inline-flex;
    }
  }

}

.pagebreak {
  display: block;
  clear: both;
  page-break-before: always;

  &-non-inside {
    page-break-inside: avoid;
  }

  &-non-inside-top-padding {
    page-break-inside: avoid;
    padding-top: 25;
  }
}

#kobetsu-detai-page #view-doc-list-page .view-seikatu-check-container {
  max-width: 1080px;
  pointer-events: none;
  padding: 10px 0px;
  margin: 0 auto;

  .pljp-table-tbody>tr>td {
    padding: 10px 10px;
  }

  .view-seikatu-table1 {

    .pljp-table-tbody>tr:first-child>td:nth-child(1),
    .pljp-table-tbody>tr:first-child>td:nth-child(3),
    .pljp-table-tbody>tr:first-child>td:nth-child(5),
    .pljp-table-tbody>tr:first-child>td:nth-child(7) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2),
    .pljp-table-tbody>tr:first-child>td:nth-child(4) {
      border-right: 0px;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(6) {
      border-right: 1px !important;
    }

    .pljp-table-tbody>tr>td {
      border-bottom: 1px solid;
    }
  }

  .view-seikatu-table1-2 {
    .pljp-table-tbody>tr:first-child>td:nth-child(1),
    .pljp-table-tbody>tr:first-child>td:nth-child(3),
    .pljp-table-tbody>tr:first-child>td:nth-child(5) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2) {
      border-right: 0px;
    }

    .pljp-table-tbody>tr>td {
      border-bottom: 1px solid;
    }
  }

  .view-seikatu-table1-3 {
    margin-top: 10px;
    .pljp-table-tbody>tr:first-child>td:nth-child(1),
    .pljp-table-tbody>tr:first-child>td:nth-child(3),
    .pljp-table-tbody>tr:first-child>td:nth-child(5) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }
    

    .pljp-table-tbody>tr:first-child>td:nth-child(2) {
      border-right: 0px;
    }

    .pljp-table-tbody>tr>td {
      border-bottom: 1px solid;
    }
  }

  .view-seikatu-table2 {
    padding-top: 10px;

    .seikatu-title2 {
      color: white;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      border-left: 1px solid white;
    }

    .pljp-table-tbody>tr:first-child>td:first-child,
    .pljp-table-tbody>tr:first-child>td:nth-child(2),
    .pljp-table-tbody>tr:first-child>td:nth-child(3) {
      border-top: none;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr>td {
      font-size: 15px;
      font-weight: bold;
      text-align: center;
    }
  }

  .view-seikatu-table2_2 {
    .pljp-table-tbody>tr:first-child>td:first-child {
      font-size: 15px;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2) {
      border-left: none;
      vertical-align: top;
    }
  }

  .view-table {
    &--1 {
      .pljp-table-tbody>tr>td {
        border-bottom: 1px solid;
      }
    }
  }

  .view-table2 {
    &--0 {
      .pljp-table-tbody>tr>td {
        border-bottom: 1px solid;
      }
    }
  }
}

#view-doc-list-page .view-jokuso-plan-container {
  pointer-events: none;
  margin: 0px auto;
  max-width: 1080px;
  padding: 20px 0;

  .pljp-table-tbody>tr>td {
    padding: 10px 10px;
  }

  .view-plan-table1 {
    .pljp-table-tbody>tr:first-child>td:first-child {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(3) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(5) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(4) {
      border-right: 0px;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2) {
      border-right: 0px;
    }

    .pljp-table-tbody>tr>td {
      border-bottom: 1px solid;
    }
  }

  .view-plan-table2 {
    .pljp-table-tbody>tr:first-child>td:first-child {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid white;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(3) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid white;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(5) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(7) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(6) {
      border-right: 0px;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(4) {
      border-right: 0px;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2) {
      border-right: 0px;
    }

    .pljp-table-tbody>tr>td {
      border-bottom: 1px solid;
    }
  }

  .view-plan-table3 {
    .pljp-table-tbody>tr:first-child>td:first-child {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(3) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(5) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(4) {
      border-right: 0px;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2) {
      border-right: 0px;
    }

    .pljp-table-tbody>tr>td {
      border-top: 0px;
      border-bottom: 1px solid;
    }
  }

  .view-plan-table4 {
    .pljp-table-tbody>tr:first-child>td:first-child {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      background-color: #00838F;
      border-right: 1px solid #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr>td {
      border-bottom: 1px solid;
      vertical-align: top;
    }
  }

  .view-table {
    &--0 {
      margin-top: 20px;

      .pljp-table-tbody>tr:first-child>td {
        padding: 5px 0;
      }
    }

    &--1,
    &--2,
    &--3,
    &--4,
    &--5,
    &--6,
    &--7,
    &--8,
    &--9,
    &--10,
    &--11,
    &--12,
    &--13 {
      .pljp-table-tbody>tr:first-child>td:first-child {
        border-top: 1px solid white;
      }

      .pljp-table-tbody>tr:first-child>td:nth-child(2) {
        border-top: 0px;
      }
    }
  }
}

.haisetsu-add-form {
  margin: 16px auto;
  max-width: 1281px;

  .title-input {
    color: rgba(0, 0, 0, 0.85);
  }

  .haisetu {
    &__input-doctor {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.65);
    }

    &__input-table {
      border: 1px solid;
      background-color: rgb(220, 240, 147);
    }

    &__input-table-title {
      border-left: 1px solid;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__input-table-title2 {
      border-left: 1px solid;
      height: 45px;
      text-align: center;
    }

    &__input-table-title3 {
      height: 24px;
      text-align: center;
    }

    &__input-table-title4 {
      border-left: 1px solid;
      height: 24px;
      text-align: center;
    }

    &__input-column {
      border: 1px solid;
      margin-top: -1px;
    }

    &__input-column-title {
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &__input-column-value {
      border-left: 1px solid;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &__radio-value {
      padding-left: 100px;
    }

    &__textareas-hide {
      display: none;
    }
  }
}

#view-doc-list-page .view-haisetsu-container {
  pointer-events: none;
  margin: 0px auto;
  max-width: 1080px;
  padding: 20px 0;

  .pljp-table-tbody>tr>td {
    padding: 10px 10px;
  }

  .view-haisetsu-table1 {
    .pljp-table-tbody>tr>td {
      border-bottom: 0;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }
  }

  .view-haisetsu-table2 {
    .pljp-table-tbody>tr>td {
      border-bottom: 1px solid;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2n+1) {
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }
  }

  .view-haisetsu-table4 {
    .pljp-table-tbody>tr>td {
      border-bottom: 1px solid;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }
  }

  .view-haisetsu-table5 {
    .pljp-table-tbody>tr>td {
      border-bottom: 0;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }
  }

  .view-table {
    &--1 {
      .pljp-table-tbody>tr>td {
        border-bottom: 1px solid;
      }

      .pljp-table-tbody>tr:first-child>td:nth-child(2n+1) {
        border-top: 1px solid #ffffff;
      }
    }
  }

  .haisetu-detail-table {
    .haisetu-detail-title {
      font-size: 15px;
      font-weight: bold;
    }

    .haisetu-detail-title2 {
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .haisetu-detail-row {
      border: 1px solid;
      background-color: #00838F;
    }

    .haisetu-detail-table-title {
      border-left: 1px solid white;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .haisetu-detail-table-title2 {
      border-left: 1px solid white;
      height: 45px;
      text-align: center;
    }

    .haisetu-detail-table-title3 {
      height: 24px;
      text-align: center;
    }

    .haisetu-detail-table-title4 {
      border-left: 1px solid white;
      height: 24px;
      text-align: center;
    }

    .haisetu-detail-column {
      border: 1px solid;
      margin-top: -1px;
    }

    .haisetu-detail-column-title {
      font-weight: bold;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .haisetu-detail-column-value {
      border-left: 1px solid;
      height: 55px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

//ErrorCSV
.error-csv {
  margin: 16px auto;
  max-width: 1081px;

  .error-csv-koumoku {
    //エラーの内容全体の要素
    margin-top: 30px;
    margin-left: 30px;
    border-bottom: 1px solid;
  }

  .error-csv-koumoku-name {
    //CSVファイル名
    font-size: 16px;
  }

  .error-csv-koumoku-naiyou {
    //CSVエラー箇所名
    font-size: 14px;
    margin-left: 12px;
  }
}

.jiritu-add-form {
  margin: 16px auto;
  max-width: 1081px; //1081

  .title-input {
    color: rgba(0, 0, 0, 0.85);
  }

  .jiritu {
    &__input-doctor {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.65);
    }

    &__input-panel-title-value {
      margin-top: 6px;
    }

    &__textareas-hide {
      display: none;
    }

    &__input-checkbox {
      .pljp-checkbox-wrapper {
        display: block;
        margin: 0;
        margin-bottom: 5px;
      }
    }
  }
}

#view-doc-list-page .view-jiritu-container {
  pointer-events: none;
  margin: 0px auto;
  max-width: 1080px;
  padding: 20px 0;

  .pljp-table-tbody>tr>td {
    padding: 10px 10px;
  }

  .view-jiritu-table1 {
    .pljp-table-tbody>tr>td {
      border-bottom: 0;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }
  }

  .view-jiritu-table2 {
    .pljp-table-tbody>tr>td {
      border-bottom: 1px solid;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2n+1) {
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }
  }

  .view-jiritu-table3 {
    .pljp-table-tbody>tr>td:nth-child(2n+1) {
      border-top: 1px solid #ffffff;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
    }

    .pljp-table-tbody>tr:last-child>td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.65);
    }

    .pljp-table-tbody>tr:last-child>td:nth-child(2n+1) {
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #00838F;
    }
  }

  .view-jiritu-table4 {
    .pljp-table-tbody>tr>td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.65);
    }
  }

  .view-jiritu-table5 {
    .pljp-table-tbody>tr>td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.65);
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }
  }

  .view-jiritu-table6 {
    .pljp-table-tbody>tr>td {
      border-bottom: 0;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(1) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2) {
      border-right: 0px;
    }
  }

  .view-jiritu-table7 {
    .columnDisable {
      background: #F2F2F2;
    }

    .pljp-table-tbody>tr>td:nth-child(1) {
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr>td:nth-child(2) {
      border-right: 0px;
    }

    .pljp-table-tbody>tr:last-child>td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.65);
    }
  }

  .view-jiritu-table8 {

    .jiritu-title,
    .jiritu-title2 {
      color: white;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
    }

    .jiritu-title2 {
      border-left: 1px solid white;
    }

    .pljp-table-tbody>tr:last-child>td {
      border-top: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.65);
      color: rgba(0, 0, 0, 0.65);
      font-weight: normal;
    }
  }

  .view-jiritu-table9 {
    .pljp-table-tbody>tr>td:nth-child(3n+1) {
      border-top: 1px solid white;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr>td:nth-child(3),
    .pljp-table-tbody>tr>td:nth-child(6) {
      border-left: 0px;
    }

    .pljp-table-tbody>tr>td:nth-child(2),
    .pljp-table-tbody>tr>td:nth-child(3),
    .pljp-table-tbody>tr>td:nth-child(5),
    .pljp-table-tbody>tr>td:nth-child(6) {
      vertical-align: top;
    }

    .pljp-table-tbody>tr:first-child>td {
      border-top: 1px solid rgba(0, 0, 0, 0.65);
    }

    .pljp-table-tbody>tr:last-child>td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.65);
    }
  }

  .view-jiritu-table10 {
    .pljp-table-tbody>tr>td:nth-child(2n+1) {
      border-top: 1px solid #ffffff;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
    }

    .pljp-table-tbody>tr:nth-child(3)>td:nth-child(1),
    .pljp-table-tbody>tr:nth-child(4)>td:nth-child(1),
    .pljp-table-tbody>tr:nth-child(5)>td:nth-child(1),
    .pljp-table-tbody>tr:nth-child(6)>td:nth-child(1) {
      border-top: none;
    }

    .pljp-table-tbody>tr:nth-child(3)>td:nth-child(2),
    .pljp-table-tbody>tr:nth-child(4)>td:nth-child(2),
    .pljp-table-tbody>tr:nth-child(5)>td:nth-child(2),
    .pljp-table-tbody>tr:nth-child(6)>td:nth-child(2) {
      border-top: none;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: right;
    }

    .pljp-table-tbody>tr>td:nth-child(3) {
      text-align: right;
    }

    .pljp-table-tbody>tr:last-child>td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.65);
    }
  }

  .view-jiritu-table11 {

    .pljp-table-tbody>tr:nth-child(odd)>td:nth-child(1),
    .pljp-table-tbody>tr:nth-child(even)>td:nth-child(odd) {
      border-top: 1px solid #ffffff;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:nth-child(odd)>td:nth-child(2) {
      border-right: none;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(1),
    .pljp-table-tbody>tr:nth-child(even)>td:nth-child(odd) {
      border-top: 1px solid #00838F;
    }

    .pljp-table-tbody>tr:nth-child(7)>td:nth-child(2),
    .pljp-table-tbody>tr:last-child>td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.65);
    }
  }

  .view-jiritu-table12 {
    .pljp-table-tbody>tr:nth-child(-n+7)>td:nth-child(1) {
      border-top: 1px solid #ffffff;
      border-right: 1px solid #ffffff;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:nth-child(-n+7)>td:nth-child(2) {
      border-top: 1px solid #ffffff;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: right;
    }

    .pljp-table-tbody>tr:last-child>td:nth-child(1) {
      border-top: 1px solid #ffffff;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr:last-child>td:nth-child(2) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.65);
    }
  }

  .view-jiritu-table13 {
    .pljp-table-tbody>tr:first-child>td:nth-child(1) {
      border-top: 1px solid #00838F;
    }

    .pljp-table-tbody>tr>td:nth-child(1) {
      border-top: 1px solid #ffffff;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }

    .pljp-table-tbody>tr>td:nth-child(2) {
      border-right: 0px;
    }

    .pljp-table-tbody>tr:last-child>td:nth-child(2),
    .pljp-table-tbody>tr:last-child>td:nth-child(3) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.65);
    }
  }
}

// 薬剤
#view-doc-list-page .view-yakuzai-container {
  pointer-events: none;
  margin: 0px auto;
  max-width: 1080px;
  padding: 20px 0;

  .pljp-table-tbody>tr>td {
    padding: 10px 10px;
  }

  .view-yakuzai-table {
    border-bottom: 1px solid rgba(0, 0, 0, 0.65);

    .pljp-table-tbody>tr>td {
      border-bottom: 0;
    }

    .pljp-table-tbody>tr:first-child>td:nth-child(2n+1) {
      border-top: 1px solid #00838F;
      border-bottom: 1px solid #00838F;
      border-right: 1px solid #00838F;
      background-color: #00838F;
      color: white;
      font-weight: bold;
      text-align: center;
    }
  }

  .yakuzai-form {
    color: white;
  }

  .yakuzai-form-title {
    border-top: 1px solid #ffffff;
    background-color: #00838F;
    color: white;
    text-align: center;
  }

  .yakuzai-form-title2 {
    background-color: #00838F;
    color: white;
    text-align: center;
  }

  //薬剤detail
  .view-yakuzai-detail-0 {
    background-color: #00838F;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }

  .view-yakuzai-detail-1 {
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .view-yakuzai-detail-2,
  .view-yakuzai-detail-3 {
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .view-yakuzai-detail-4 {
    color: white;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.65);
  }

  //薬剤kioureki
  .view-yakuzai-kioureki-0 {
    background-color: #00838F;
    color: white;
    font-size: 15px;
    font-weight: bold;
  }

  .view-yakuzai-kioureki-1 {
    color: white;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.65);
  }
}

@media print {

  // 不要項目削除
  .life-header {
    display: none;
  }

  .menu-header .sm-c-center {
    display: none;
  }

  .menu-header .sm-c-right {
    display: none;
  }

  // タイトル
  .menu-header .sm-c-left p {
    color: #000;
  }

  .menu-header .sm-c-left p:nth-child(2),
  .menu-header .sm-c-left p:nth-child(3) {
    font-size: 15px;
    color: #000;
  }

  // 見出しフォントサイズ
  .Inquiry-Request__input-text__title {
    font-size: 10px;
  }

  #kobetsu-detai-page #view-doc-list-page {
    .view-jokuso-plan-container {

      // 罫線白→灰
      .view-plan-table1 table>tbody>tr>td,
      .view-plan-table2 table>tbody>tr>td,
      .view-plan-table1 table>tbody>tr>td:nth-child(odd),
      .view-plan-table2 table>tbody>tr>td:nth-child(odd) {
        border-bottom: solid 1px gray;
      }

      // 黒文字+フォントサイズ
      .view-plan-table1 table>tbody>tr>td:nth-child(odd),
      .view-plan-table3 table>tbody>tr>td:nth-child(n+1),
      .view-plan-table2 table>tbody>tr>td:nth-child(odd),
      .pljp-table-tbody>tr>td {
        color: #000;
        font-size: 10px;
      }
    }

    .view-seikatu-check-container {

      .pljp-table-tbody>tr>td {
        padding: 5px 5px !important;
      }

      .view-seikatu-table1 {
        .pljp-table-tbody>tr:first-child>td:nth-child(n+1) {
          border: solid 1px gray;
          color: #000;
          font-size: 10px;
        }
      }

      .view-seikatu-table2 {
        .pljp-table-thead>tr:first-child>th:nth-child(n+1) {
          border: 1px solid gray;
          color: #000;
          font-size: 10px;
        }

        .pljp-table-thead>tr:first-child>th:nth-child(2),
        .pljp-table-thead>tr:first-child>th:nth-child(3) {
          border-left: none;
        }

        .pljp-table-tbody>tr:first-child,
        .pljp-table-tbody>tr {

          td:nth-child(n+1),
          label {
            font-size: 10px;
          }
        }
      }

      .view-seikatu-table2_2 {
        .pljp-table-tbody>tr:first-child>td:nth-child(n+1) {
          font-size: 10px;
        }
      }
    }

  }

  #view-doc-list-page .view-jokuso-plan-container {

    .view-plan-table1 .pljp-table-tbody>tr:first-child>td:nth-child(n+1),
    .view-plan-table2 .pljp-table-tbody>tr:first-child>td:nth-child(n+1),
    .view-plan-table3 .pljp-table-tbody>tr:first-child>td:nth-child(n+1),
    .view-plan-table4 .pljp-table-tbody>tr:first-child>td:nth-child(n+1) {
      border: 1px solid #000;
      border-top: none;
      color: #000;
      font-size: 10px;
    }

    .view-plan-table1 .pljp-table-tbody>tr:last-child>td:nth-child(n+1),
    .view-plan-table2 .pljp-table-tbody>tr:last-child>td:nth-child(n+1) {
      border-top: 1px solid #000;
    }

    .view-table--0 {
      border-top: 1px solid #000;
    }
  }
}

#input-koku-page {
  .ml-14 .pljp-form-item-control-wrapper {
    margin-left: 14px;
  }

  .ml-42 .pljp-form-item-control-wrapper {
    margin-left: 42px;
  }

  .ml-40 .pljp-form-item-control-wrapper {
    margin-left: 40px;
  }

  .ml-28 .pljp-form-item-control-wrapper {
    margin-left: 28px;
  }

  .ml-25 .pljp-form-item-control-wrapper {
    margin-left: 25px;
  }

  .ml-72 .pljp-form-item-control-wrapper {
    margin-left: 72px;
  }
}