@color-select-row: #bacfcd;
#input-riyou-syokujisen{
  border-bottom: 1px solid;
}
.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.title_kanrihyo {
  color: white;
}

.group_btn_action {
  gap: 10px;
}

.kanrihyo_page .item-link {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  transition: 0.3s all;
  padding: 2px 8px;
  height: 41px;

  &:hover {
    background-color: #e0e0e0;
    border-radius: 4px;
  }
}

.title_icon {
  margin-top: 3px !important;
}
.toolbar__container {
  border-bottom: 1px solid;
}

.option-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  width: 93%;
}

.option__container {
  justify-content: space-between;
  padding: 10px 20px;
  width: 95%;
}

.plan_date {
  display: flex;
  align-items: center;
}

.ant-notification-notice-error {
  width: 400px !important;
}

.kanrihyo_page {
  background-color: #FFF9C4;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll; 
  table >tbody>tr>td {
    border-inline-start: 1px solid black !important;
    border-bottom: 1px solid black !important;
  }
  table >thead>tr>th {
    border-inline-start: 1px solid black !important;
    border-top: 1px solid black !important;
  }
}

.date-picker-kanrihyo::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 23%;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #00838f;
}

.service-type {
  text-align: start !important;
}

.gray-background {
  background-color: #F2F2F2;
}

.jigyousyo-name {
  display: flex !important;
  justify-content: flex-start;
}

.jigyousyo-no, .jigyousyo-kubun, .plan-tani {
  text-align: center;
  border: none;
}

.service-syurui-cd {
  border: none;
}

.comment .text-dots {
  text-align: start;
}

.date-picker-kanrihyo {
  .pljp-picker-input>input {
    right: -23px;
  }

  .pljp-picker-suffix {
    order: -1;
    color: white;
    z-index: 1;
    position: absolute;
    left: -8px !important;
  }

  .pljp-picker-footer-extra {
    width: 100% !important;
  }

  .pljp-picker-suffix:hover {
    color: white !important;
  }
}

.datepicker_group {
  display: flex;
  gap: 10px;
}
.option_insurance {
  width: 44%;
  display: flex;
  justify-content: space-between
}

.option_date,
.item-level {
  display: flex;
  gap: 10px;
  align-items: center;
}

.date-limit-application {
  justify-content: start;
}

.option_date {
  padding-top: 16px;
}

.birth-day-sex {
  display: flex;
}

.item-birth-day {
  display: flex;
}

.level-of-care-required {
  display: flex;
}
.period-begins{
  justify-content: flex-start !important;
}
.period-begins, .period-ends{
  gap: 10px;
  align-items: center;
}
.body_container{
  padding: 20px;
  background-color: #FFF9C4;
  width: 85%;
}
.header-table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 62%;
}

.group-btn-setting{
  display: flex;
  gap: 10px;
  align-items: center;
  input {
    width: 100px !important;
  }
}
.contain_table{
  margin-top: 20px;
}
.custom-row td {
  height: 30px !important;
  padding: 4px !important;
}
.messageIcon {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 50%;
  .icon-pen {
    position: absolute;
    top: 45%;
    left: 8%;
    transform: translate(-44%, -55%);
    height: 62%;
  }
}
.group_btn_info{
  width: 300px;
  gap: 20px;
  justify-content: flex-start;
}
.mb-24{
  margin-bottom: 24px;
}
.ml-20{
  margin-left: 20px;
}
.mr-25{
  margin-right: 25px;
}
.w-890{
  width: 890px;
}
.mr-10{
  margin-right:10px;
}
.ga-10{
  gap: 10px
}
.selected-row {
  background-color: #bacfcd !important;
}
.table-tanto-master {
  .pljp-table-row {
    .pljp-table-cell{
      padding: 10px !important;
    }
  }
}
.title_user{
  font-size: 14px;
  font-weight: 200;
  color: white;
}

.row-expanded {
  background-color: @color-select-row;
  height: 50px;
  vertical-align: text-top;
}
.text-dots-comment {
  white-space: nowrap;
  word-break: break-word;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.add-row-button{
  float: right;
}
.disabled{
  cursor: not-allowed;
}

.body_container .pljp-table-container table>thead>tr >*:last-child { 
  border-right: 1px solid black !important;
}

.body_container .pljp-table.pljp-table-bordered >.pljp-table-container >.pljp-table-content >table >tbody>tr>td:last-child {
  border-right: 1px solid black !important;
}
 
.body_container .pljp-table.pljp-table-bordered >.pljp-table-container >.pljp-table-content >table >thead>tr>th {
  border-bottom: 1px solid black;
  background: #F2F2F2;
}

@media screen and (max-width: 1080px) {
  .datepicker_group {
    gap: 2px !important;
  }
  .planning-year, .billing-date, .creation-category {
    width: 52px;
    margin-right: 2px !important;
  }
  .date-picker-kanrihyo {
    width: 100px;
  }
  .toolbar__container .pljp-select-show-arrow {
    width: 100px !important;
  }
  .care-manager-number {
    width: 117px;
  }
  .input-care-manager-number {
    width: 85px !important;
  }
  .usage-slip-citation {
    width: 122px !important;
  }
  .kanrihyo_page .tanto-name {
    width: 92px !important;
  }
  .group_btn_info {
    gap: 5px;
    margin-left:10px;
  }
  .icon-pen {
    transform: translate(25%, -55%) !important;
  }
  .date_select_jp {
    width: 60px !important;
  }
  .year_select_jp{
    width: 70px !important;
  }
  .space-between {
    gap: 2px !important;
  }
  .item-birth-day {
    width: 400px !important;
    align-items: center !important;
    gap: 8px;
  }
  .date-of-birth {
    margin-right: 0px !important;
  }
  .pljp-form-item-has-success {
    margin-bottom: 0px !important;
  }
  .birth-day-sex .data-sex {
    width: 170px !important;
    margin-left: 0px !important;
  }
  .period-begins, .period-ends {
    gap: 2px !important;
    padding-bottom: 10px;
  }
  .period-begins .pljp-form-item-row {
  }
  .limit-application-period {
    margin-bottom: 0px !important;
  }
  
  .period-ends {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .limit-application-period-ends {
    gap: 0px !important;
  }
  .info-sex {
    position: absolute;
    width: 86px;
    margin-top: 8px;
  }
  .data-period-begins {
    padding-top: 16px !important;
  }
  .option_date {
    padding-top: 0px !important;
  }
  .option-toolbar {
    width: 100%;
  }
  .option_insurance {
    width: 80%;
  }
  .option__container {
    width: 100%;
  }
  .date-limit-application {
    justify-content: end;
  }
  .item-birth-day-sex {
    margin-top: -24px;
  }
  .birth-day-sex {
    padding-bottom: 6px;
  }
  .text-item-level-care {
    margin-right: 8px !important;
  }
  .item-level{
    margin-top: 5px;
  }
  .option__container{
    padding: 10px 5px 10px 20px !important;
  }
 
}