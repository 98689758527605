.document-table {
  margin: 0px auto;
  max-width: 1260px;
  padding: 0 10px;

  table,
  tr,
  td {
    border: 0.5px solid black;
    cursor: pointer;
  }

  .nameRiyousya {
    font-size: 1.5em;
  }
}

.menu-header {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #212121d9;
  color: @white;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;

  .sm-c-left {
    display: flex;
    color: @white;
    align-items: center;

    p {
      margin-right: 5px;
    }

    p:nth-child(2),
    p:nth-child(3) {
      font-size: 25px;
    }
  }

  .sm-c-right {
    display: flex;

    .item-link {
      margin-right: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: @white;
      transition: 0.3s all;
      padding: 2px 8px;

      &:hover {
        background-color: #e0e0e0;
        border-radius: 4px;
      }
    }
  }
  @media (max-width: 1024px) {
    .sm-c-right {
      .item-link {
        &:hover {
          background-color: unset;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) {
  .kyotaku-header {
    padding: 12px;

    .kyotaku-header__logo {
      height: 40px;
      width: auto;
    }
  }

  .document-table {
    .btn-row {
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;

      .btn-crud {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }

    .toolbar-row {
      display: flex;
      justify-content: space-between;

      .toolbar-checkbox-div {
        padding-top: 8px;
      }
    }

    .pljp-select-enabled {
      width: 200px !important;
    }

    .pljp-select-selection--single {
      width: 200px;
    }

    .row-button {
      text-align: right;

      .btn-right {
        .pljp-btn {
          margin-right: 5px;
          float: right;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 991.99px) {
  .document-table {
    #record-active-tab1 {
      .pljp-row .btn-row {
        display: block !important;
      }
    }
  }
}

@media only screen and (min-device-width: 992px) {
  .document-table {
    #record-active-tab1 {
      .btn-row {
        display: flex;
      }
    }
  }
}

@media only screen and (max-device-width: 767.99px) {
  .sub-menu-top {
    padding-bottom: 0px !important;
  }
  
  .kyotaku-header {
    height: 64px;

    .kyotaku-header__left {
      width: 50%;
      display: inline-block !important;
      padding-left: 10px;

      .kyotaku-header__logo {
        height: 20px;
        width: 72px !important;
      }

      .kyotaku-header__logo-title {
        font-size: 1.3em;
      }
    }

    .kyotaku-header__right {
      .kyotaku-header__nickname {
        font-size: 1em !important;
      }
    }
  }

  .text-tool-bar {
    margin-top: 10px !important;

    span {
      font-size: 15px !important;
    }
  }

  .document-table {
    .btn-row {
      justify-content: flex-start;
      margin-top: 10px;
    }

    .toolbar-row {
      .pljp-checkbox-wrapper {
        margin-bottom: 10px;
        padding-left: 0px !important;
      }

      .pljp-select {
        margin-bottom: 10px;
      }

      .date-picker {
        margin-bottom: 10px;
      }

      .search-riyousya {
        margin-bottom: 10px;
      }

      .sort-handle {
        margin-left: 0px !important;
      }

      .pljp-select-enabled {
        width: 120px !important;
      }

      .pljp-select-selection--single {
        width: 120px;
      }
    }

    .btn-right {
      .btn-crud {
        margin-right: 10px;
        margin-bottom: 10px;
      }

      .pljp-btn {
        margin-right: 5px;
        float: left;
      }
    }

    .pljp-row {
      .pljp-col {
        width: 100% !important;
      }

      .titleRecord {
        float: left !important;
        margin-bottom: 10px;
      }
    }
  }
}
